import { OrgId, TEAM_ROLES, Team__StaffTypes } from "@ollie-sports/models";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import * as express from "express";
import _ from "lodash";
import { isParentOrgInvoice } from "../../utils";
import { OrgMemberTableData } from "../../constants";

const SortKeys = {
  name: true,
  roles: false,
  email: true,
  teams: false,
  phone_number: false
};

export async function org__server__getOrgMemberTableData(p: {
  orgId: OrgId;
  selfAccountId: string;
  offset?: number;
  limit?: number;
  searchTerm?: string;
  roles?: (TEAM_ROLES | Team__StaffTypes)[];
  sort?: {
    key: keyof typeof SortKeys;
    dir: "desc" | "asc";
  }[];
}) {
  console.log("-------");
  console.log(p.sort);
  const { getAppPgPool } = getServerHelpers();

  const baseParam = {
    orgId: { valid: true, val: p.orgId },
    offset: { valid: true, val: p.offset || 0 },
    searchTerm: { valid: !!p.searchTerm, val: `%${p.searchTerm?.replace(/ /g, "").toLowerCase()}%` },
    limit: { valid: true, val: p.limit || 100 }
  };

  const queryParams = _(baseParam)
    .entries()
    .filter(a => !!a[1].valid)
    .map((a, i) => {
      return [a[0], { sqlParam: `$${i + 1}`, value: a[1].val }];
    })
    .fromPairs()
    .value() as { [k in keyof typeof baseParam]?: { sqlParam: string; value: any } };

  let sort = p.sort
    ?.filter(a => {
      return SortKeys[a.key] && ["asc", "desc"].includes(a.dir);
    })
    .slice(0, 3);

  if (!sort?.length) {
    sort = [{ key: "name", dir: "asc" }];
  }
  const orderBy = sort.map(a => `${a.key === "name" ? "trim(concat(first_name, last_name))" : a.key} ${a.dir}`).join(", ");

  const query = `select account_or_player_bundle_id,
  first_name,
  last_name,
  type,
  team_ids_json_string,
  team_name_json_string,
  is_player, is_guardian,
  is_staff,
  is_head_coach,
  is_assistant_coach,
  is_staff_member,
  is_team_admin,
  email,
  phone_number,
  count(*) over () as count
  from f_org_member_table_data(${queryParams.orgId!.sqlParam})
  where org_id = ${queryParams.orgId!.sqlParam}
  ${
    queryParams.searchTerm
      ? `and replace(trim(concat(first_name, ' ', last_name, ' ', email, replace(
        replace(
            replace(team_name_json_string, '[', ''),
            ']', ''),
        ',', ''))), ' ', '') ilike ${queryParams.searchTerm.sqlParam}`
      : ""
  }
  ${p.roles?.length ? getRolsQuerySubstring(p.roles) : ""}
  order by ${orderBy}
  OFFSET ${queryParams.offset!.sqlParam} LIMIT ${queryParams.limit!.sqlParam};`;

  const r1 = await getAppPgPool().query(
    query,
    Object.values(queryParams).map(a => a.value)
  );

  if (!r1.rowCount) {
    return {
      data: [] as OrgMemberTableData[],
      count: 0
    };
  } else {
    const returnData = r1.rows.map(row => {
      return {
        accountOrPlayerBundleId: row["account_or_player_bundle_id"],
        firstName: row["first_name"],
        lastName: row["last_name"],
        type: row["type"],
        teamIds: row["team_ids_json_string"] as string[],
        teamNames: row["team_name_json_string"] as string[],
        isPlayer: row["is_player"],
        isGuardian: row["is_guardian"],
        isStaff: row["is_staff"],
        isHeadCoach: row["is_head_coach"],
        isAssistantCoach: row["is_assistant_coach"],
        isStaffMember: row["is_staff_member"],
        isTeamAdmin: row["is_team_admin"],
        email: row["email"],
        phoneNumber: row["phone_number"]
      } as OrgMemberTableData;
    });
    return {
      data: returnData,
      count: (r1.rows[0].count ?? 0) as number
    };
  }
}

org__server__getOrgMemberTableData.auth = async (r: express.Request) => {
  // Make sure user has correct permission
  await validateTokenAndEnsureSelfAccountIdMatches(r);
};

function getRolsQuerySubstring(roles: (Team__StaffTypes | TEAM_ROLES)[]) {
  return `and (${_.compact(
    roles.map(role => {
      if (role === TEAM_ROLES.athlete) {
        return "is_player = true";
      } else if (role === TEAM_ROLES.guardian) {
        return "is_guardian = true";
      } else if (role === TEAM_ROLES.staff) {
        return "is_staff = true";
      } else if (role === Team__StaffTypes.assistantCoach) {
        return "is_assistant_coach = true";
      } else if (role === Team__StaffTypes.headCoach) {
        return "is_head_coach = true";
      } else if (role === Team__StaffTypes.staffMember) {
        return "is_staff_member = true";
      } else if (role === Team__StaffTypes.teamAdmin) {
        return "is_team_admin = true";
      } else {
        return null;
      }
    })
  ).join(" or ")})`;
}

// i18n certified - complete
