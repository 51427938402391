import { Typography, SvgIcon, InputLabel } from "@material-ui/core";
import { TouchableOpacity, View } from "react-native-web";
import _ from "lodash";
import {
  DownPaymentType,
  Org,
  OrgCouponUseType,
  OrgPaymentPlan,
  OrgPaymentPlanEven,
  OrgPaymentPlanInitialPaymentType,
  OrgPaymentPlanPercent,
  OrgPaymentPlanType
} from "@ollie-sports/models";
import { getCurrentLocale, translate } from "@ollie-sports/i18n";
import { getBifrost } from "../../services/bifrost.service";
import { COLORS, compute } from "@ollie-sports/core";
import { useRef, useState } from "react";
import { useImmutableState } from "../../utils/useImmutableState";
import {
  Form,
  PrettyCoolDateInput,
  PrettyCoolSelectInput,
  PrettyCoolTextInput,
  PrettyCoolTextInputWithLabel
} from "../../components/Form";
import moment from "moment";
import { DistributiveOmit } from "react-redux";
import { CoolCheckboxInput } from "../../components/Inputs/CoolCheckboxInput";
import { DeepPartial } from "redux";
import { PlusCircle } from "react-feather";
import { ArrowDownIcon, ArrowUpIcon, TrashIcon } from "@heroicons/react/24/outline";
import { openModal } from "../../components/modals/imperativeModal";
import { FullScreenModal } from "../../components/modals/getFullscreenModal";
import { dequal } from "dequal";
import { CoolSelectInput } from "../../components/Inputs/CoolSelectInput";
import { useOrgSettings } from "../../hooks/useOrgSettings";
import { TextButton } from "../../components/TextButton";
import { Expando } from "../../components/Expando";
import { StyledText } from "../../components/StyledText";
import { OrgInvoicePreviewForCouponOrPaymentPlan } from "./OrgInvoicePayPreview";

function getDefaultDate() {
  return moment().month(5).startOf("month").format("MM-DD");
}

type Props = {
  type: "add" | "edit";
  org: Org;
  initialOrgPaymentPlan?: OrgPaymentPlan;
};
export function openOrgPaymentPlanAddEditModal(p: Props) {
  return new Promise<void>(res => {
    const modal = openModal({
      body: (
        <OrgPaymentPlanAddEditModal
          {...p}
          onRequestDismiss={() => {
            modal.close();
            res();
          }}
        />
      )
    });
  });
}

function OrgPaymentPlanAddEditModal(p: Props & { onRequestDismiss: () => void }) {
  const initialOrgPaymentPlan =
    p.initialOrgPaymentPlan ??
    ({
      orgId: p.org.id
    } as DeepPartial<OrgPaymentPlan>);
  const { orgSettings } = useOrgSettings({ orgId: p.org.id });
  const [isPreviewExpanded, setIsPreviewExpanded] = useState(false);
  const [orgPaymentPlan, setOrgPaymentPlan] = useImmutableState<DeepPartial<OrgPaymentPlan>>(initialOrgPaymentPlan);
  const [errorMsg, setErrorMsg] = useState("");
  const [selectedDownPaymentType, setSelectedDownPaymentType] = useState<"amount" | "percent">("amount");
  const [lateFeeAmountShadow, setLateFeeAmountShadow] = useState(
    orgPaymentPlan.lateFeeAmountCents ? String(orgPaymentPlan.lateFeeAmountCents / 100) : ""
  );
  const [downPaymentAmountShadow, setDownPaymentAmountShadow] = useState(
    orgPaymentPlan.initialPaymentSettings?.type === OrgPaymentPlanInitialPaymentType.fixedAmount
      ? String(orgPaymentPlan.initialPaymentSettings.ammountCents / 100)
      : ""
  );
  const [paymentPlanSetupFeeAmountShadow, setPaymentPlanSetupFeeAmountShadow] = useState(
    orgPaymentPlan.setupFeeAmountCents ? String(orgPaymentPlan.setupFeeAmountCents / 100) : ""
  );
  const [paymentPlanInstallmentFeeAmountShadow, setPaymentPlanInstallmentFeeAmountShadow] = useState(
    orgPaymentPlan.installmentFeeAmountCents ? String(orgPaymentPlan.installmentFeeAmountCents / 100) : ""
  );
  const [percentShadow, setPercentShadow] = useState(
    orgPaymentPlan.initialPaymentSettings?.type === OrgPaymentPlanInitialPaymentType.percent
      ? String(orgPaymentPlan.initialPaymentSettings.percent * 100)
      : ""
  );

  let previewOrgPaymentPlan: OrgPaymentPlan | undefined = undefined;

  const initialPaymentOptions = [
    { label: translate.common.None, value: "none" },
    {
      label: translate({ defaultMessage: "Fixed Amount" }),
      value: OrgPaymentPlanInitialPaymentType.fixedAmount
    },
    {
      label: translate({ defaultMessage: "Percent" }),
      value: OrgPaymentPlanInitialPaymentType.percent
    }
  ];

  if (orgPaymentPlan.type === OrgPaymentPlanType.monthly) {
    initialPaymentOptions.push({
      label: translate({ defaultMessage: "First Installment" }),
      value: OrgPaymentPlanInitialPaymentType.firstInstallment
    });
  }

  if (orgPaymentPlan.type === OrgPaymentPlanType.monthly) {
    if (orgPaymentPlan.name && orgPaymentPlan.numberOfDaysUntilLate && orgPaymentPlan.numberPeriods) {
      previewOrgPaymentPlan = {
        type: OrgPaymentPlanType.monthly,
        createdAtMS: Date.now(),
        id: "test-payment-plan",
        orgId: p.org.id,
        name: orgPaymentPlan.name,
        numberOfDaysUntilLate: orgPaymentPlan.numberOfDaysUntilLate,
        numberPeriods: orgPaymentPlan.numberPeriods,
        ...orgPaymentPlan
      };
    }
  } else if (orgPaymentPlan.type === OrgPaymentPlanType.evenlySplit) {
    if (orgPaymentPlan.chargeDatesWithoutYear) {
      const chargeDatesWithoutYearTemp = orgPaymentPlan.chargeDatesWithoutYear
        .filter((item): item is DeepPartial<{ date: string }> => item !== undefined && item.date !== undefined)
        .map(item => ({ date: item.date as string }));
      if (
        orgPaymentPlan.name &&
        orgPaymentPlan.numberOfDaysUntilLate !== undefined &&
        orgPaymentPlan.numberOfDaysUntilLate >= 0 &&
        chargeDatesWithoutYearTemp.length
      ) {
        previewOrgPaymentPlan = {
          type: OrgPaymentPlanType.evenlySplit,
          createdAtMS: Date.now(),
          id: "test-payment-plan",
          orgId: p.org.id,
          name: orgPaymentPlan.name,
          numberOfDaysUntilLate: orgPaymentPlan.numberOfDaysUntilLate,
          ...orgPaymentPlan,
          chargeDatesWithoutYear: chargeDatesWithoutYearTemp
        };
      }
    }
  } else if (orgPaymentPlan.type === OrgPaymentPlanType.customPercent) {
  }
  const totalPercent =
    orgPaymentPlan.type === OrgPaymentPlanType.customPercent
      ? Object.values(orgPaymentPlan.chargeDatesWithoutYear ?? {}).reduce((acc, val) => {
          acc = (acc ?? 0) + (val?.percent ?? 0);
          return acc;
        }, 0 as number)
      : undefined;

  return (
    <Form
      children={isFormValid => {
        return (
          <FullScreenModal
            title={
              p.type === "add"
                ? translate({ defaultMessage: "Add Payment Plan" })
                : translate({ defaultMessage: "Edit Payment Plan" })
            }
            onRequestDismiss={() => {
              if (
                !dequal(initialOrgPaymentPlan, orgPaymentPlan) &&
                !window.confirm(translate({ defaultMessage: "You have unsaved changes. Are you sure you wish to leave?" }))
              ) {
                return;
              }

              p.onRequestDismiss();
            }}
            bottomButton={{
              title: p.type === "add" ? translate.common.Create : translate.common.Save,
              enabled: isFormValid,
              onPress: async () => {
                try {
                  if (
                    orgPaymentPlan.type === OrgPaymentPlanType.evenlySplit ||
                    orgPaymentPlan.type === OrgPaymentPlanType.customPercent
                  ) {
                    const dates = compute.orgPaymentPlan.transformChargeDatesToCurrentYearMoments({
                      type: "manual",
                      dates: orgPaymentPlan.chargeDatesWithoutYear ?? ([] as any),
                      dueDateMS: moment().valueOf()
                    });

                    const numYears = dates[dates.length - 1].diff(dates[0], "years");

                    if (
                      numYears > 1 &&
                      !window.confirm(
                        translate({
                          defaultMessage:
                            "This plan has payments spanning more than 1 year of time. Are you sure you wish to proceed?"
                        })
                      )
                    ) {
                      return;
                    }
                  }

                  if (!p.initialOrgPaymentPlan) {
                    const newOrgPaymentPlan: DistributiveOmit<OrgPaymentPlan, "id" | "createdAtMS"> = {
                      ...orgPaymentPlan
                    } as DistributiveOmit<OrgPaymentPlan, "id" | "createdAtMS">;
                    await getBifrost().orgPaymentPlan__client__addOrgPaymentPlan.fetchClient({
                      orgPaymentPlan: newOrgPaymentPlan,
                      locale: getCurrentLocale()
                    });
                  } else {
                    await getBifrost().orgPaymentPlan__client__updateOrgPaymentPlan.fetchClient({
                      orgPaymentPlan: orgPaymentPlan as OrgPaymentPlan
                    });
                  }
                  p.onRequestDismiss();
                } catch (e) {
                  console.log(e);
                  setErrorMsg(
                    translate({
                      defaultMessage:
                        "There was a problem creating the payment plan. Please try again or contact support@olliesports.com"
                    })
                  );
                }
              }
            }}
          >
            <div>
              <PrettyCoolSelectInput
                label={translate({ defaultMessage: "Plan Type" })}
                placeholder={translate.common.SelectDotDotDot}
                value={orgPaymentPlan.type ?? ""}
                isRequired
                options={[
                  {
                    label: `${translate.common.FixedDates}`,
                    value: OrgPaymentPlanType.evenlySplit
                  },
                  {
                    label: translate.common.Monthly,
                    value: OrgPaymentPlanType.monthly
                  }
                ]}
                onChange={newVal => {
                  setOrgPaymentPlan(a => {
                    if (newVal === OrgPaymentPlanType.evenlySplit && orgPaymentPlan.type !== OrgPaymentPlanType.evenlySplit) {
                      a.type = OrgPaymentPlanType.evenlySplit;
                      (a as Partial<OrgPaymentPlanEven>).chargeDatesWithoutYear = [{ date: getDefaultDate() }];
                      if (a.initialPaymentSettings?.type === OrgPaymentPlanInitialPaymentType.firstInstallment) {
                        a.initialPaymentSettings = undefined;
                      }
                    } else if (
                      newVal === OrgPaymentPlanType.customPercent &&
                      orgPaymentPlan.type !== OrgPaymentPlanType.customPercent
                    ) {
                      a.type = OrgPaymentPlanType.customPercent;
                      (a as Partial<OrgPaymentPlanPercent>).chargeDatesWithoutYear = [{ date: getDefaultDate(), percent: 0 }];
                      if (a.initialPaymentSettings?.type === OrgPaymentPlanInitialPaymentType.firstInstallment) {
                        a.initialPaymentSettings = undefined;
                      }
                    } else if (newVal === OrgPaymentPlanType.monthly && orgPaymentPlan.type !== OrgPaymentPlanType.monthly) {
                      a.type = OrgPaymentPlanType.monthly;
                    }
                  });
                }}
              />
              {orgPaymentPlan.type ? (
                <div className="flex p-1 text-xs text-gray-500 items-center">
                  {orgPaymentPlan.type === OrgPaymentPlanType.evenlySplit
                    ? translate({
                        defaultMessage:
                          "Charges users on specified dates of the year. These payment plans can only be used with registration invoices, not manual invoices."
                      })
                    : orgPaymentPlan.type === OrgPaymentPlanType.monthly
                    ? translate({
                        defaultMessage: "Charge users once a month for a specified number of months"
                      })
                    : orgPaymentPlan.type === OrgPaymentPlanType.customPercent
                    ? translate({
                        defaultMessage:
                          "Splits the total fee amount by a percent of total on different dates. E.g. 30% of total at first payment, 70% at second payment, etc"
                      })
                    : ""}
                </div>
              ) : null}
              <PrettyCoolTextInputWithLabel
                label={translate({ defaultMessage: "Plan Name" })}
                isRequired
                onChange={newCode => {
                  setOrgPaymentPlan({ name: newCode ?? "" });
                }}
                style={{ marginTop: 16 }}
                inputProps={{ type: "text" }}
                value={orgPaymentPlan.name}
              />
              <div className="flex px-1 text-xs text-gray-500 items-center mt-1">
                {translate({
                  defaultMessage: "Note: Parents and players will see this name."
                })}
              </div>

              {orgPaymentPlan.type === OrgPaymentPlanType.monthly ? (
                <div className="flex gap-2 mt-4">
                  <div className="flex-1">
                    {orgPaymentPlan.type === OrgPaymentPlanType.monthly ? (
                      <>
                        <PrettyCoolTextInputWithLabel
                          label={translate({
                            defaultMessage: "# of Months"
                          })}
                          infoTooltip={translate({
                            defaultMessage: "The maximum number of months a user can split their payments over."
                          })}
                          onChange={newVal => {
                            setOrgPaymentPlan(a => {
                              if (a.type === OrgPaymentPlanType.monthly) {
                                const value = newVal?.replaceAll(".", "");
                                if (!value) {
                                  a.numberPeriods = undefined;
                                  return;
                                }
                                const numValue = parseInt(value);
                                a.numberPeriods = numValue;
                              }
                            });
                          }}
                          isRequired
                          inputProps={{ type: "number" }}
                          value={
                            orgPaymentPlan.type === OrgPaymentPlanType.monthly ? `${orgPaymentPlan.numberPeriods ?? ""}` : ""
                          }
                        />
                      </>
                    ) : null}
                  </div>
                  <div className="flex-1">
                    <PrettyCoolTextInputWithLabel
                      infoTooltip={translate({
                        defaultMessage:
                          "The day of the month that you want the payments to be scheduled. Must be between 1 and 31. If left blank, the payments schedule will be based on the day that the payment plan is created or the season start date."
                      })}
                      label={`${translate({
                        defaultMessage: "Day of the Month"
                      })} (${translate.common.Optional})`}
                      onChange={newVal => {
                        setOrgPaymentPlan(a => {
                          if (a.type === OrgPaymentPlanType.monthly) {
                            const value = newVal?.replaceAll(".", "");
                            if (!value) {
                              a.dayOfTheMonth = undefined;
                              return;
                            }
                            const numValue = parseInt(value);
                            if (numValue < 1 || numValue > 31) {
                              return;
                            }
                            a.dayOfTheMonth = numValue;
                          }
                        });
                      }}
                      inputProps={{ type: "number" }}
                      value={orgPaymentPlan.type === OrgPaymentPlanType.monthly ? `${orgPaymentPlan.dayOfTheMonth ?? ""}` : ""}
                    />
                  </div>
                </div>
              ) : null}
              {orgPaymentPlan.type === OrgPaymentPlanType.monthly && orgPaymentPlan.dayOfTheMonth ? (
                <div className="flex px-1 text-xs text-gray-500 items-center mt-2">
                  {translate(
                    {
                      defaultMessage:
                        "NOTE: Users will only ever be charged ONCE per calendar month. E.g. If they set up their plan on July 5, they will next be charged on August {date}"
                    },
                    { date: orgPaymentPlan.dayOfTheMonth }
                  )}
                </div>
              ) : null}

              {!!orgPaymentPlan.type && orgPaymentPlan.type !== OrgPaymentPlanType.monthly ? (
                <div className="border-t-2 border-b-2 mt-4 pb-4 border-[#F0F1F2]">
                  <div className="mt-4 mb-4 font-bold">
                    {translate({ defaultMessage: "Fixed Installment Dates" })}
                    {orgPaymentPlan.type === OrgPaymentPlanType.evenlySplit ||
                    orgPaymentPlan.type === OrgPaymentPlanType.customPercent ? (
                      <div className="text-gray-500" style={{ fontWeight: "normal" }}>
                        {getPaymentScheduleSummary(orgPaymentPlan?.chargeDatesWithoutYear as any)}
                      </div>
                    ) : null}
                  </div>
                  {orgPaymentPlan.type === OrgPaymentPlanType.evenlySplit ||
                  orgPaymentPlan.type === OrgPaymentPlanType.customPercent ? (
                    <>
                      {orgPaymentPlan?.chargeDatesWithoutYear?.map((val, i, arr) => {
                        if (
                          orgPaymentPlan.type === OrgPaymentPlanType.evenlySplit ||
                          orgPaymentPlan.type === OrgPaymentPlanType.customPercent
                        ) {
                          return (
                            <PaymentRow
                              key={i}
                              index={i}
                              percent={(val as any)?.percent}
                              type={orgPaymentPlan.type!}
                              daysToNextPayment={
                                arr[i + 1]?.date ? getNumDaysDifference(val?.date!, arr[i + 1]!.date!) : undefined
                              }
                              date={val!.date!}
                              onDelete={
                                arr.length === 1
                                  ? undefined
                                  : () => {
                                      setOrgPaymentPlan(a => {
                                        if (
                                          a.type === OrgPaymentPlanType.evenlySplit ||
                                          a.type === OrgPaymentPlanType.customPercent
                                        ) {
                                          a.chargeDatesWithoutYear?.splice(i, 1);
                                        }
                                      });
                                    }
                              }
                              onUpdateDate={newDate => {
                                setOrgPaymentPlan(a => {
                                  if (a.type === OrgPaymentPlanType.evenlySplit || a.type === OrgPaymentPlanType.customPercent) {
                                    a.chargeDatesWithoutYear![i]!.date = newDate;
                                  }
                                });
                              }}
                              onUpdatePercent={newPercent => {
                                setOrgPaymentPlan(a => {
                                  if (a.type === OrgPaymentPlanType.evenlySplit || a.type === OrgPaymentPlanType.customPercent) {
                                    (a.chargeDatesWithoutYear![i] as any).percent = newPercent;
                                  }
                                });
                              }}
                            />
                          );
                        }
                      })}
                    </>
                  ) : null}

                  {orgPaymentPlan.type === OrgPaymentPlanType.customPercent ? (
                    <div className="flex justify-end mr-9 mt-4">
                      <Typography style={{ color: totalPercent !== 1 ? COLORS.red : COLORS.green }}>
                        {translate(
                          {
                            defaultMessage: "{percent}/100% assigned"
                          },
                          { percent: Math.round((totalPercent ?? 0) * 100).toString() }
                        )}
                      </Typography>
                    </div>
                  ) : null}
                  {orgPaymentPlan.type === OrgPaymentPlanType.evenlySplit ||
                  orgPaymentPlan.type === OrgPaymentPlanType.customPercent ? (
                    <TouchableOpacity
                      style={{ flexDirection: "row", marginTop: 16 }}
                      onPress={() => {
                        const latest = orgPaymentPlan.chargeDatesWithoutYear?.slice(-1).pop();
                        setOrgPaymentPlan(a => {
                          if (a.type === OrgPaymentPlanType.evenlySplit || a.type === OrgPaymentPlanType.customPercent) {
                            const dateToAdd = latest ? moment(latest.date).add(2, "month").format("MM-DD") : getDefaultDate();
                            a.chargeDatesWithoutYear = a.chargeDatesWithoutYear || [];
                            a.chargeDatesWithoutYear.push({ date: dateToAdd, percent: (latest as any).percent });
                          }
                        });
                      }}
                    >
                      <SvgIcon style={{ paddingRight: 6, color: COLORS.blue }}>
                        <PlusCircle />
                      </SvgIcon>
                      <Typography style={{ color: COLORS.blue }}>{translate.common.AddDate}</Typography>
                    </TouchableOpacity>
                  ) : null}
                </div>
              ) : null}

              <div className="flex gap-2">
                <div className="flex-1">
                  <PrettyCoolTextInputWithLabel
                    style={{ marginTop: 16 }}
                    label={translate({ defaultMessage: "Grace Period Days" })}
                    infoTooltip={translate({
                      defaultMessage: '# of days after a scheduled installment payment before the payment is marked "Late"'
                    })}
                    onChange={newVal => {
                      const value = newVal?.replaceAll(".", "");
                      if (!value) {
                        setOrgPaymentPlan({ numberOfDaysUntilLate: undefined });
                        return;
                      }
                      const numValue = parseInt(value);
                      setOrgPaymentPlan({ numberOfDaysUntilLate: numValue });
                    }}
                    validate={v => {
                      if (!v) {
                        return translate.common.IsRequired;
                      }
                      try {
                        const numVal = parseInt(v);
                        if (numVal < 0) {
                          return translate({ defaultMessage: "Must be a positive number" });
                        }
                      } catch (e) {
                        return translate({ defaultMessage: "Must be a positive number" });
                      }
                      return "";
                    }}
                    isRequired
                    inputProps={{ type: "number" }}
                    value={`${orgPaymentPlan.numberOfDaysUntilLate ?? ""}`}
                  />
                </div>
                <div className="flex-1">
                  <PrettyCoolTextInputWithLabel
                    style={{ marginTop: 16 }}
                    label={`${translate.common.LateFee} (${translate.common.Optional})`}
                    isMoney
                    infoTooltip={translate({
                      defaultMessage:
                        "Extra fee the user will be required to pay AFTER the installment date has elapsed AND the grace period has expired."
                    })}
                    onChange={newVal => {
                      if (!newVal) {
                        setLateFeeAmountShadow("");
                        setOrgPaymentPlan({ lateFeeAmountCents: undefined });
                        return;
                      }
                      const newAmt = newVal?.match(/\d+\.?\d?\d?/)?.[0];
                      setLateFeeAmountShadow(newAmt || "");
                      setOrgPaymentPlan({ lateFeeAmountCents: newAmt ? Number(newAmt) * 100 : 0 });
                    }}
                    inputProps={{ type: "number" }}
                    value={lateFeeAmountShadow}
                  />
                </div>
              </div>
              <div className="flex px-1 text-xs text-gray-500 items-center mt-1">
                {translate({
                  defaultMessage:
                    "If a payment fails, the user will be notified and we will automatically retry the payment once daily until a total of 5 attempts."
                })}
              </div>

              <CoolSelectInput
                label={translate({ defaultMessage: "Initial Payment Type" })}
                containerStyle={{ flex: 1, marginTop: 16 }}
                value={
                  orgPaymentPlan.initialPaymentSettings?.type === OrgPaymentPlanInitialPaymentType.firstInstallment
                    ? OrgPaymentPlanInitialPaymentType.firstInstallment
                    : orgPaymentPlan.initialPaymentSettings?.type === OrgPaymentPlanInitialPaymentType.fixedAmount
                    ? OrgPaymentPlanInitialPaymentType.fixedAmount
                    : orgPaymentPlan.initialPaymentSettings?.type === OrgPaymentPlanInitialPaymentType.percent
                    ? OrgPaymentPlanInitialPaymentType.percent
                    : "none"
                }
                options={initialPaymentOptions}
                onChange={newVal => {
                  switch (newVal) {
                    case "none":
                      setOrgPaymentPlan({ initialPaymentSettings: undefined });
                      break;
                    case OrgPaymentPlanInitialPaymentType.fixedAmount:
                      if (orgPaymentPlan.initialPaymentSettings?.type !== OrgPaymentPlanInitialPaymentType.fixedAmount) {
                        setOrgPaymentPlan({
                          initialPaymentSettings: {
                            type: OrgPaymentPlanInitialPaymentType.fixedAmount,
                            ammountCents: 0
                          }
                        });
                      }
                      break;
                    case OrgPaymentPlanInitialPaymentType.percent:
                      if (orgPaymentPlan.initialPaymentSettings?.type !== OrgPaymentPlanInitialPaymentType.percent) {
                        setOrgPaymentPlan({
                          initialPaymentSettings: {
                            type: OrgPaymentPlanInitialPaymentType.percent,
                            percent: 0
                          }
                        });
                      }
                      break;
                    case OrgPaymentPlanInitialPaymentType.firstInstallment:
                      if (orgPaymentPlan.initialPaymentSettings?.type !== OrgPaymentPlanInitialPaymentType.firstInstallment) {
                        setOrgPaymentPlan({
                          initialPaymentSettings: {
                            type: OrgPaymentPlanInitialPaymentType.firstInstallment
                          }
                        });
                      }
                      break;
                  }
                }}
              />

              {orgPaymentPlan.initialPaymentSettings?.type === OrgPaymentPlanInitialPaymentType.fixedAmount ||
              orgPaymentPlan.initialPaymentSettings?.type === OrgPaymentPlanInitialPaymentType.percent ? (
                <PrettyCoolTextInputWithLabel
                  style={{ flex: 1, marginTop: 16 }}
                  label={`${
                    orgPaymentPlan.initialPaymentSettings.type === OrgPaymentPlanInitialPaymentType.fixedAmount
                      ? translate({ defaultMessage: "Down Payment Amount" })
                      : translate({ defaultMessage: "Down Payment Percent" })
                  }`}
                  isRequired
                  isMoney={orgPaymentPlan.initialPaymentSettings.type === OrgPaymentPlanInitialPaymentType.fixedAmount}
                  isPercent={orgPaymentPlan.initialPaymentSettings.type === OrgPaymentPlanInitialPaymentType.percent}
                  onChange={newVal => {
                    const newAmt = newVal?.match(/\d+\.?\d?\d?/)?.[0];
                    if (orgPaymentPlan.initialPaymentSettings?.type === OrgPaymentPlanInitialPaymentType.fixedAmount) {
                      if (!newVal) {
                        setDownPaymentAmountShadow("");
                        setOrgPaymentPlan({
                          initialPaymentSettings: {
                            type: OrgPaymentPlanInitialPaymentType.fixedAmount,
                            ammountCents: 0
                          }
                        });
                        return;
                      }

                      if (parseFloat(newAmt || "0") < 0) {
                        return;
                      }
                      setDownPaymentAmountShadow(newAmt || "");
                      setOrgPaymentPlan({
                        initialPaymentSettings: {
                          type: OrgPaymentPlanInitialPaymentType.fixedAmount,
                          ammountCents: newAmt ? Number(newAmt) * 100 : 0
                        }
                      });
                    } else if (orgPaymentPlan.initialPaymentSettings?.type === OrgPaymentPlanInitialPaymentType.percent) {
                      if (!newAmt) {
                        setOrgPaymentPlan({
                          initialPaymentSettings: {
                            type: OrgPaymentPlanInitialPaymentType.percent,
                            percent: 0
                          }
                        });
                        setPercentShadow("");
                        return;
                      }
                      let percent = parseFloat(newAmt);
                      const percentVal = percent ? percent / 100 : undefined;
                      if (percentVal && (percentVal <= 0 || percentVal > 1)) {
                        return;
                      }
                      if (!percentVal) {
                        setOrgPaymentPlan({
                          initialPaymentSettings: {
                            type: OrgPaymentPlanInitialPaymentType.percent,
                            percent: 0
                          }
                        });
                        setPercentShadow("");
                        return;
                      }
                      setPercentShadow(newAmt || "");
                      setOrgPaymentPlan({
                        initialPaymentSettings: {
                          type: OrgPaymentPlanInitialPaymentType.percent,
                          percent: percentVal
                        }
                      });
                    }
                  }}
                  inputProps={{ type: "number" }}
                  value={
                    orgPaymentPlan.initialPaymentSettings.type === OrgPaymentPlanInitialPaymentType.fixedAmount
                      ? downPaymentAmountShadow
                      : percentShadow
                  }
                />
              ) : null}
              {orgPaymentPlan.initialPaymentSettings ? (
                <div className="flex px-1 text-xs text-gray-500 items-center mt-1">
                  {orgPaymentPlan.initialPaymentSettings?.type === OrgPaymentPlanInitialPaymentType.fixedAmount ||
                  orgPaymentPlan.initialPaymentSettings?.type === OrgPaymentPlanInitialPaymentType.percent
                    ? translate({
                        defaultMessage:
                          "Users will be required to pay this when setting up the payment plan. The future payment plan installments will be calculated after deducting the down payment from the total invoice amount."
                      })
                    : orgPaymentPlan.initialPaymentSettings?.type === OrgPaymentPlanInitialPaymentType.firstInstallment
                    ? translate({
                        defaultMessage: "Users will be required to pay the first installment at checkout."
                      })
                    : ""}
                </div>
              ) : null}

              <label className="flex text-sm font-extrabold items-center mt-4">{`${translate({
                defaultMessage: "Additional Convenience Fees"
              })} (${translate.common.Optional})`}</label>

              <div className="flex px-1 text-xs text-gray-500 items-center mt-1">
                {translate({
                  defaultMessage: "You can charge additional fees for paying with a payment plan instead of paying in full."
                })}
              </div>
              <div className="flex flex-row w-full mt-4">
                <PrettyCoolTextInputWithLabel
                  style={{ flex: 1, marginRight: 4 }}
                  infoTooltip={translate({
                    defaultMessage: "A one time fee to set up the payment plan. This fee will be included at initial checkout."
                  })}
                  label={`${translate({ defaultMessage: "Setup Fee" })} (${translate.common.Optional})`}
                  isMoney={true}
                  onChange={newVal => {
                    const newAmt = newVal?.match(/\d+\.?\d?\d?/)?.[0];
                    if (!newVal) {
                      setPaymentPlanSetupFeeAmountShadow("");
                      setOrgPaymentPlan({ setupFeeAmountCents: undefined });
                      return;
                    }

                    if (parseFloat(newAmt || "0") < 0) {
                      return;
                    }
                    setPaymentPlanSetupFeeAmountShadow(newAmt || "");
                    setOrgPaymentPlan({
                      setupFeeAmountCents: newAmt ? Number(newAmt) * 100 : 0
                    });
                  }}
                  inputProps={{ type: "number" }}
                  value={paymentPlanSetupFeeAmountShadow}
                />
                <div className="flex-1">
                  <PrettyCoolTextInputWithLabel
                    style={{ flex: 1, marginLeft: 4 }}
                    infoTooltip={translate({
                      defaultMessage: "A fee that will be added to each installment."
                    })}
                    label={`${translate({ defaultMessage: "Installment Fee" })} (${translate.common.Optional})`}
                    isMoney={true}
                    onChange={newVal => {
                      const newAmt = newVal?.match(/\d+\.?\d?\d?/)?.[0];
                      if (!newVal) {
                        setPaymentPlanInstallmentFeeAmountShadow("");
                        setOrgPaymentPlan({ installmentFeeAmountCents: undefined });
                        return;
                      }

                      if (parseFloat(newAmt || "0") < 0) {
                        return;
                      }
                      setPaymentPlanInstallmentFeeAmountShadow(newAmt || "");
                      setOrgPaymentPlan({
                        installmentFeeAmountCents: newAmt ? Number(newAmt) * 100 : 0
                      });
                    }}
                    inputProps={{ type: "number" }}
                    value={paymentPlanInstallmentFeeAmountShadow}
                  />
                </div>
              </div>
              <CoolCheckboxInput
                label={translate({ defaultMessage: "Enable Installment Date Flexibility?" })}
                style={{ marginTop: 16 }}
                labelType="inside"
                infoTooltip={translate({
                  defaultMessage:
                    "Let users to set a preferred charge date within the grace period. E.g. If there is a 20 day grace period and a payment typically scheduled for May 1, users can override the charge date to be any date up to May 20."
                })}
                onChange={newVal => {
                  setOrgPaymentPlan({
                    allowUsersToOverrideChargeDateUpToLateFeeDeadline: newVal === true ? true : undefined
                  });
                }}
                value={orgPaymentPlan.allowUsersToOverrideChargeDateUpToLateFeeDeadline ?? false}
              />

              {orgSettings ? (
                <View style={{ paddingVertical: 16 }}>
                  <TextButton
                    iconElm={
                      isPreviewExpanded ? (
                        <ArrowUpIcon width={16} height={16} className="text-green-400" />
                      ) : (
                        <ArrowDownIcon width={16} height={16} className="text-green-400" />
                      )
                    }
                    text={isPreviewExpanded ? translate.common.HidePreview : translate.common.ViewPreview}
                    onPress={() => {
                      setIsPreviewExpanded(!isPreviewExpanded);
                    }}
                    disabled={!previewOrgPaymentPlan}
                    textClassName="text-green-400"
                  />
                  <Expando durationMS={500} isExpanded={isPreviewExpanded}>
                    <View style={{ paddingHorizontal: 16 }}>
                      {previewOrgPaymentPlan ? (
                        <OrgInvoicePreviewForCouponOrPaymentPlan
                          type={"paymentPlan"}
                          org={p.org}
                          orgSettings={orgSettings}
                          orgPaymentPlan={previewOrgPaymentPlan}
                        />
                      ) : (
                        <StyledText style={{ marginTop: 16 }}>
                          {translate({
                            defaultMessage:
                              "Please make sure that all of the required fields are filled out in order to view the preview."
                          })}
                        </StyledText>
                      )}
                    </View>
                  </Expando>
                </View>
              ) : null}
              {errorMsg ? <Typography style={{ color: COLORS.red, marginTop: 30 }}>{errorMsg}</Typography> : null}
            </div>
          </FullScreenModal>
        );
      }}
    />
  );
}

function PaymentRow(p: {
  index: number;
  date: string;
  onDelete?: () => void;
  type: OrgPaymentPlanType;
  daysToNextPayment?: string;
  percent?: number;
  onUpdateDate: (date: string) => void;
  onUpdatePercent?: (percent: number | undefined) => void;
}) {
  const observerRef = useRef<null | MutationObserver>(null);
  return (
    <div className="flex flex-col items-center">
      <div className="flex w-full items-center">
        <div className="flex items-center justify-center text-lg text-white w-8 h-8 rounded-full bg-slate-300 mr-4">
          {p.index + 1}
        </div>
        <div className="flex-1 flex flex-col">
          <PrettyCoolDateInput
            isRequired
            dateFormat="MMMM do"
            //Do some annoying stuff to make the datepicker not have a year...
            onCalendarOpen={() => {
              const watchedClass = ".react-datepicker__current-month, .react-datepicker__aria-live";
              const tweakText = () => {
                document.querySelectorAll(watchedClass).forEach(node => {
                  const newText = node.textContent?.replace(/[ \d]/g, "") || "";
                  if (newText !== node.textContent) {
                    node.textContent = newText;
                  }
                });
              };

              tweakText();

              const observer = new MutationObserver(mutationsList => {
                for (let mutation of mutationsList) {
                  if (mutation.type === "childList") {
                    mutation.addedNodes.forEach(node => {
                      if (node.nodeType === Node.ELEMENT_NODE && (node as any).matches(watchedClass)) {
                        tweakText();
                      }
                    });
                    mutation.removedNodes.forEach(node => {
                      if (node.nodeType === Node.ELEMENT_NODE && (node as any).matches(watchedClass)) {
                        tweakText();
                      }
                    });
                  } else if (mutation.type === "characterData") {
                    if (
                      mutation.target?.parentElement?.nodeType === Node.ELEMENT_NODE &&
                      (mutation.target.parentElement as any).matches(watchedClass)
                    ) {
                      tweakText();
                    }
                  }
                }
              });

              // Start observing the document with the configured parameters
              observer.observe(document, { childList: true, subtree: true, characterData: true });

              observerRef.current = observer;
            }}
            onCalendarClose={() => {
              observerRef.current?.disconnect();
              observerRef.current = null;
            }}
            onChange={newVal => {
              if (!newVal) {
                return;
              }

              p.onUpdateDate(moment(newVal).format("MM-DD"));
            }}
            label=""
            value={moment(p.date, "MM-DD").toDate()}
          />
        </div>
        {p.type === OrgPaymentPlanType.customPercent ? (
          <div style={{ marginLeft: 16, maxWidth: 100 }} className="flex flex-col relative">
            <PrettyCoolTextInput
              isRequired
              value={p.percent ? String(Math.round(p.percent * 100)) : ""}
              isPercent
              errorStyles={{ position: "absolute", top: 40 }}
              onChange={newVal => {
                if (!newVal) {
                  p.onUpdatePercent?.(undefined);
                  return;
                }
                let percent = parseInt(newVal);
                const percentVal = percent ? percent / 100 : undefined;
                if (percentVal && (percentVal <= 0 || percentVal > 1)) {
                  return;
                }
                p.onUpdatePercent?.(percentVal);
              }}
            />
          </div>
        ) : null}
        <TouchableOpacity
          style={{ marginLeft: 12, opacity: !p.onDelete ? 0 : undefined }}
          onPress={p.onDelete}
          disabled={!p.onDelete}
        >
          <SvgIcon style={{ color: COLORS.red }}>
            <TrashIcon />
          </SvgIcon>
        </TouchableOpacity>
      </div>

      {p.daysToNextPayment ? (
        <div style={{ marginTop: 8, marginBottom: 3 }} className="flex flex-col items-center">
          {Array.from(new Array(3)).map((__, i) => (
            <div key={i} className="bg-gray-500 rounded-full" style={{ height: 2, width: 2, margin: "2px 0" }}></div>
          ))}
          <div className="text-xs text-gray-500">{p.daysToNextPayment}</div>
          {Array.from(new Array(3)).map((__, i) => (
            <div key={i} className="bg-gray-500 rounded-full" style={{ height: 2, width: 2, margin: "2px 0" }}></div>
          ))}
        </div>
      ) : null}
    </div>
  );
}

function getNumDaysDifference(dateWOYear1: string, dateWOYear2: string) {
  const m1 = moment(dateWOYear1, "MM-DD");
  const m2 = moment(dateWOYear2, "MM-DD");

  if (m1.isSameOrAfter(m2)) {
    m2.add(1, "year");
  }

  const oldLocale = moment.locale();

  // Change the global locale settings
  moment.updateLocale("en", {
    relativeTime: {
      future: "%s",
      past: "%s",
      s: "a few seconds",
      ss: "%d seconds",
      m: "a minute",
      mm: "%d minutes",
      h: "an hour",
      hh: "%d hours",
      d: "1 day",
      dd: "%d days",
      M: "1 month",
      MM: "%d months",
      y: "1 year",
      yy: "%d years"
    }
  });

  const str = m1.from(m2);

  moment.locale(oldLocale);

  return str;
}

function getPaymentScheduleSummary(
  a?: OrgPaymentPlanPercent["chargeDatesWithoutYear"] | OrgPaymentPlanEven["chargeDatesWithoutYear"]
) {
  if (!a || a.length === 1) {
    return "";
  }

  const dates = compute.orgPaymentPlan.transformChargeDatesToCurrentYearMoments({
    type: "manual",
    dates: a,
    dueDateMS: moment().valueOf()
  });

  const days = dates[dates.length - 1].diff(dates[0], "days");
  //Approximate "round" number of months that the plan lasts
  const months = (Math.round((days / 30.41) * 6) / 6).toFixed(2);

  return ` (${translate(
    {
      defaultMessage: "{months} {months, plural, one {month} other {months}} start to finish",
      description: "The number of total months included in the payment plan"
    },
    { months }
  )})`;
}
