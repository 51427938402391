import { Typography, useTheme, useMediaQuery, Box, Button, SvgIcon } from "@material-ui/core";
import { View, StyleSheet, Image } from "react-native-web";
import { Tooltip } from "@material-ui/core";
import { Account, ORG_PERMISSIONS, Org, OrgId, OrgSecret, OrgSettings, Org__Account } from "@ollie-sports/models";
import { CenteredLoader } from "../../../components/CenteredLoader";
import { dateFormatters, getCurrentLocale, translate } from "@ollie-sports/i18n";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { useOrg } from "../../../hooks/useOrg";
import { useOrgSettings } from "../../../hooks/useOrgSettings";
import { StyledText } from "../../../components/StyledText";
import { COLORS, ObjectKeys, compute, isValidHex, ensureColorHasHashtag } from "@ollie-sports/core";
import { PencilSquareIcon, PlusCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import { ShadowView } from "../../../components/ShadowView";
import { openOrgDetailsEditModal } from "../OrgDetailsEditModal";
import { getAuthToken, getBifrost } from "../../../services/bifrost.service";
import { useAccounts } from "../../../hooks/useAccounts";
import CoolerTable from "../../../components/CoolerTable";
import getConfirm from "../../../components/modals/getConfirm";
import { openErrorToast, openSuccessToast } from "../../../utils/openErrorToast";
import { Form, PrettyCoolTextInputWithLabel } from "../../../components/Form";
import { FullScreenModal } from "../../../components/modals/getFullscreenModal";
import React, { useState } from "react";
import { openModal } from "../../../components/modals/imperativeModal";
import { EditButton } from "../../../components/EditButton";
import { config } from "../../../config";
import { usePromise } from "../../../utils/hooks/usePromise";
import { getCurrentUserAccountId } from "../../../hooks/commonDataUtils";
import { useImmutableState } from "../../../utils/useImmutableState";
import { CoolCheckboxInput } from "../../../components/Inputs/CoolCheckboxInput";
import { OrgPermissionRow } from "../../../components/OrgPermissionRow";
import { CoolerTabbedContent } from "../../../components/CoolerTabbedContent";
import { Clipboard, DollarSign, List, Settings, User, Watch } from "react-feather";
import { InfoTooltip, InfoTooltipIcon } from "../../../components/InfoTooltip";
import { OrgTryoutSettings } from "./OrgTryoutSettings";
import { useSearchParamsState } from "../../../hooks/useSearchParamsState";
import { OrgRegistrationSettings } from "./OrgRegistrationSettings";
import moment from "moment";
import { StyledButton } from "../../../components/StyledButton";
import { OrgSettingsDetails } from "../components/OrgSettingsDetails";
import { TextButton } from "../../../components/TextButton";

export default function OrgSettingsPage() {
  const params: any = useParams();
  const orgId = params.orgId;

  const { org, isLoading: isOrgLoading } = useOrg({ orgId });
  const { orgSettings, isLoading: isOrgSettingsLoading } = useOrgSettings({ orgId });
  const { data: orgSecret, isLoading: isOrgSecretLoading } =
    getBifrost().orgSecret__client__getOrgSecretSubscription.useClientSubscription(
      { orgId: orgId },
      { notifyOnMetaDataChanges: true }
    );

  return (
    <Box px={3} py={2} display="flex" style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        {isOrgLoading || isOrgSettingsLoading || isOrgSecretLoading ? (
          <CenteredLoader />
        ) : org && orgSettings ? (
          <OrgSettingsPageInner org={org} orgSettings={orgSettings} orgSecret={orgSecret ?? undefined} />
        ) : (
          <Typography>{translate({ defaultMessage: "Failed to load org settings" })}</Typography>
        )}
      </View>
    </Box>
  );
}

function OrgSettingsPageInner(p: { org: Org; orgSettings: OrgSettings; orgSecret?: OrgSecret }) {
  const [searchParams, setSearchParams] = useSearchParamsState<{ tab?: string }>();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const { accounts: adminAccounts } = useAccounts({ accountIds: ObjectKeys(p.org.accounts) });
  const adminAccountsWithOrgAccount = adminAccounts
    .map(a => {
      return { account: a, orgAccount: p.org.accounts[a.id] };
    })
    .filter(a => !!a.orgAccount) as { account: Account; orgAccount: Org__Account }[];

  const canManageOrgAdmins = compute.hasOrgPermission({
    accountId: getCurrentUserAccountId(),
    org: p.org,
    permission: ORG_PERMISSIONS.manageOrgAccounts
  });

  function onEditOrgAdminPress(account: Account, orgAccount: Org__Account) {
    const modal = openModal({
      body: (
        <EditOrgAdminModal
          onRequestDismiss={() => {
            modal.close();
          }}
          orgId={p.org.id}
          account={account}
          initialOrgAccount={orgAccount}
        />
      )
    });
  }

  return (
    <View style={{ flex: 1, marginBottom: 16 }}>
      <Typography variant={mobileDevice ? "h4" : "h1"} color="textPrimary">
        {translate({ defaultMessage: "Club Settings" })}
      </Typography>
      <CoolerTabbedContent
        className="mt-4 pb-8"
        useQueryParams
        initialTabKey={searchParams.tab}
        tabs={[
          {
            key: "main",
            label: translate.common.Details,
            labelIcon: <Settings />,
            getContents: () => <OrgSettingsDetails initialOrg={p.org} initialOrgSettings={p.orgSettings} />
          },
          {
            label: translate.common.Admins,
            key: "admins",
            labelIcon: <User />,
            getContents: () => (
              <View style={styles.expandoContent}>
                {canManageOrgAdmins ? (
                  <Button
                    color="secondary"
                    style={{ marginBottom: 8, alignSelf: "flex-end" }}
                    variant="contained"
                    onClick={() => {
                      const modal = openModal({
                        body: (
                          <AddOrgAdminModal
                            onRequestDismiss={() => {
                              modal.close();
                            }}
                            orgId={p.org.id}
                          />
                        )
                      });
                    }}
                  >
                    <SvgIcon style={{ paddingRight: 6 }}>
                      <PlusCircleIcon />
                    </SvgIcon>
                    {translate({ defaultMessage: "Add Admin" })}
                  </Button>
                ) : null}
                <CoolerTable
                  defaultSortSettings={{ dir: "asc", label: translate.common.Name }}
                  items={adminAccountsWithOrgAccount}
                  getItemKey={item => item.account.id}
                  columnDefs={[
                    {
                      label: translate.common.Name,
                      getValue(item) {
                        return `${item.account.firstName} ${item.account.lastName}`;
                      },
                      sortItems(items, dir) {
                        return _.orderBy(
                          items,
                          a => {
                            return `${a.account.firstName} ${a.account.lastName}`;
                          },
                          dir
                        );
                      }
                    },
                    {
                      label: translate.common.Permissions,
                      getValue(item) {
                        return <OrgPermissionRow orgAccount={item.orgAccount} />;
                      }
                    }
                  ]}
                  onRowClick={
                    canManageOrgAdmins
                      ? item => {
                          onEditOrgAdminPress(item.account, item.orgAccount);
                        }
                      : undefined
                  }
                  rowButtons={
                    canManageOrgAdmins
                      ? [
                          {
                            getLabel: () => translate.common.Edit,
                            type: "inline",
                            getIcon: () => <PencilSquareIcon color={COLORS.green} />,
                            async onClick(item) {
                              onEditOrgAdminPress(item.account, item.orgAccount);
                            }
                          }
                        ]
                      : []
                  }
                />
              </View>
            )
          },
          {
            key: "tryouts",
            label: translate.common.Tryouts,
            labelIcon: <Watch />,
            getContents: () => <OrgTryoutSettings orgId={p.org.id} initialOrgSettings={p.orgSettings} />
          },
          {
            key: "registrations",
            label: translate.common.Registration,
            labelIcon: <Clipboard />,
            getContents: () => <OrgRegistrationSettings orgId={p.org.id} initialOrgSettings={p.orgSettings} />
          }
        ]}
      />
    </View>
  );
}

// function OrgBankingAndPayments(p: { orgSecret?: OrgSecret; org: Org }) {
//   const { data: currentAuthToken } = usePromise(() => getAuthToken(), []);
//   const { data: merchant, isLoading } = getBifrost().orgSecret__orgServer__getOrgMerchantDetails.useServer(
//     {
//       orgId: p.org.id,
//       selfAccountId: getCurrentUserAccountId()
//     },
//     { notifyOnMetaDataChanges: true }
//   );

//   const { accounts: requestedByAccountData } = useAccounts({
//     accountIds: p.orgSecret?.squareOauth?.requestedByAccountId ? [p.orgSecret.squareOauth.requestedByAccountId] : []
//   });

//   const requestedByAccount = requestedByAccountData.length ? requestedByAccountData[0] : undefined;

//   if (isLoading) {
//     return <CenteredLoader />;
//   }

//   return (
//     <>
//       <Typography style={{ marginBottom: 30 }} variant={"h3"} color="textPrimary">
//         {translate({ defaultMessage: "Merchant Settings" })}
//       </Typography>
//       {merchant ? (
//         <View>
//           {merchant.businessName ? (
//             <View style={{ flexDirection: "row" }}>
//               <StyledText style={{ fontWeight: "bold", marginRight: 8 }}>{translate.common.Name + ":"}</StyledText>
//               <StyledText>{merchant.businessName}</StyledText>
//             </View>
//           ) : null}
//           {merchant.status ? (
//             <View style={{ flexDirection: "row" }}>
//               <StyledText style={{ fontWeight: "bold", marginRight: 8 }}>{translate.common.Status + ":"}</StyledText>
//               <StyledText>{_.upperFirst(merchant.status.toLowerCase())}</StyledText>
//             </View>
//           ) : null}
//           {merchant.createdAt ? (
//             <View style={{ flexDirection: "row" }}>
//               <StyledText style={{ fontWeight: "bold", marginRight: 8 }}>
//                 {translate({ defaultMessage: "Setup Date" }) + ":"}
//               </StyledText>
//               <StyledText>{dateFormatters.mm_dd_yyyy(moment(merchant.createdAt).toDate(), getCurrentLocale())}</StyledText>
//             </View>
//           ) : null}
//           {requestedByAccount ? (
//             <View style={{ flexDirection: "row" }}>
//               <StyledText style={{ fontWeight: "bold", marginRight: 8 }}>{translate({ defaultMessage: "By" }) + ":"}</StyledText>
//               <StyledText>{requestedByAccount.firstName + " " + requestedByAccount.lastName}</StyledText>
//             </View>
//           ) : null}
//         </View>
//       ) : (
//         <Typography>
//           {translate({
//             defaultMessage: "Click the button below to set up the merchant account in Square and start accepting payments."
//           })}
//         </Typography>
//       )}
//       {merchant ? (
//         <View style={{ marginTop: 30 }}>
//           <Typography>
//             {translate({
//               defaultMessage: "Click the button below to refresh your merchant account."
//             })}
//           </Typography>
//         </View>
//       ) : null}
//       <div className="flex bg-primary text-white py-3 px-4 w-48 justify-center items-center align-center mt-4 rounded-md text-center">
//         <a
//           target="_blank"
//           rel="noreferrer"
//           className="flex w-full h-full items-center justify-center"
//           // Redirected in squareOauth.ts
//           href={`${config.appApiRoot}/square-oauth?authorize_org_id=${p.org.id}&auth=${currentAuthToken}`}
//         >
//           {merchant
//             ? translate({ defaultMessage: "Refresh", description: "Verb, as in to refresh something" })
//             : translate({ defaultMessage: "Set Up", description: "Verb, as in to set up something" })}
//         </a>
//       </div>
//     </>
//   );
// }

// function SettingRow(p: {
//   label: string;
//   value: string;
//   infoMessage?: string;
//   style?: any;
//   newline?: boolean;
//   extraComponentRight?: JSX.Element;
// }) {
//   return (
//     <View style={[{ flexDirection: p.newline ? "column" : "row" }, p.style]}>
//       <div>
//         <InfoTooltip title={p.infoMessage || ""}>
//           <span className={p.infoMessage ? "cursor-help" : ""} style={{ fontWeight: "bold", marginRight: 6 }}>
//             {p.label + ":"}
//           </span>
//         </InfoTooltip>
//       </div>
//       <View style={{ marginTop: p.newline ? 8 : 0 }}>
//         {p.value.split("\n").map((line, index) => {
//           return line ? (
//             <StyledText key={index} style={{ marginBottom: 2 }}>
//               {line}
//             </StyledText>
//           ) : (
//             <br key={index} />
//           );
//         })}
//       </View>
//       {p.extraComponentRight}
//     </View>
//   );
// }

const styles = StyleSheet.create({
  expandoContent: {
    backgroundColor: COLORS.white,
    padding: 16
  }
});

function AddOrgAdminModal(p: { onAdd?: () => Promise<void>; onRequestDismiss: () => void; orgId: OrgId }) {
  const [emailInput, setEmailInput] = useState("");
  const [permissions, setPermissions] = useImmutableState<Org__Account["permissions"]>({
    manageOrgAccounts: true,
    manageOrgChat: true,
    manageTeams: true
  });
  return (
    <Form
      children={isFormValid => {
        return (
          <FullScreenModal
            children={
              <View>
                <PrettyCoolTextInputWithLabel
                  label={translate.common.Email}
                  onChange={newVal => {
                    setEmailInput(newVal ?? "");
                  }}
                  style={{ marginBottom: 16 }}
                  isRequired
                  value={emailInput}
                />
                <OrgAdminPermissionSection
                  permissions={permissions}
                  onUpdatePermissions={newPermissions => {
                    setPermissions(a => {
                      a = newPermissions;
                      return a;
                    });
                  }}
                />
              </View>
            }
            onRequestDismiss={async () => {
              p.onRequestDismiss();
            }}
            title={translate({ defaultMessage: "Add Admin" })}
            bottomButton={{
              title: translate.common.Add,
              onPress: async () => {
                try {
                  await getBifrost().org__server__addRemoveAdmin.fetchServer({
                    action: "add",
                    type: "byEmail",
                    email: emailInput.toLowerCase(),
                    orgId: p.orgId,
                    permissions
                  });
                  await p.onAdd?.();
                  p.onRequestDismiss();
                  openSuccessToast(translate.common.Success);
                } catch (e) {
                  openErrorToast(
                    translate({
                      defaultMessage:
                        "There was a problem adding the admin. Please make sure the email is correct and matches the email they used to create their Ollie account. Please try again or contact support@olliesports.com"
                    })
                  );
                }
              },
              enabled: isFormValid
            }}
          />
        );
      }}
    />
  );
}

function EditOrgAdminModal(p: {
  onEdit?: () => Promise<void>;
  onRequestDismiss: () => void;
  orgId: OrgId;
  initialOrgAccount: Org__Account;
  account: Account;
}) {
  const [permissions, setPermissions] = useImmutableState<Org__Account["permissions"]>(p.initialOrgAccount.permissions);
  return (
    <FullScreenModal
      children={
        <View>
          <OrgAdminPermissionSection
            permissions={permissions}
            onUpdatePermissions={newPermissions => {
              setPermissions(a => {
                a = newPermissions;
                return a;
              });
            }}
          />
          <TextButton
            className="text-red-500 mt-4"
            iconElm={
              <div className="h-4 w-4 mr-2">
                <TrashIcon />
              </div>
            }
            onPress={async () => {
              const confirm = await getConfirm({
                confirmButtonColor: "red",
                subtitle: translate({ defaultMessage: "Are you sure you want to remove this admin?" }),
                title: translate({ defaultMessage: "Remove Admin?" })
              });
              if (confirm) {
                try {
                  await getBifrost().org__server__addRemoveAdmin.fetchServer({
                    accountId: p.account.id,
                    action: "remove",
                    orgId: p.orgId,
                    type: "byAccountId"
                  });
                  openSuccessToast(
                    translate(
                      {
                        defaultMessage: "Removed {name}"
                      },
                      { name: `${p.account.firstName} ${p.account.lastName}` }
                    )
                  );
                  p.onRequestDismiss();
                } catch (e) {
                  openErrorToast(
                    translate({
                      defaultMessage:
                        "There was a problem removing the admin. Please try again or contact support@olliesports.com"
                    })
                  );
                }
              }
            }}
            text={translate({ defaultMessage: "Remove Admin" })}
          />
        </View>
      }
      onRequestDismiss={async () => {
        p.onRequestDismiss();
      }}
      title={`${p.account.firstName} ${p.account.lastName}`}
      bottomButton={{
        title: translate.common.Save,
        onPress: async () => {
          try {
            await getBifrost().org__server__setOrgAccount.fetchServer({
              accountId: p.account.id,
              orgAccount: { ...p.initialOrgAccount, permissions },
              orgId: p.orgId
            });
            await p.onEdit?.();
            p.onRequestDismiss();
            openSuccessToast(translate.common.Success);
          } catch (e) {
            openErrorToast(
              translate({
                defaultMessage: "There was a problem editing the admin. Please try again or contact support@olliesports.com"
              })
            );
          }
        }
      }}
    />
  );
}

function OrgAdminPermissionSection(p: {
  permissions: Org__Account["permissions"];
  onUpdatePermissions: (updatedPermissions: Org__Account["permissions"]) => void;
}) {
  return (
    <View>
      <StyledText style={{ fontWeight: "bold" }}>{translate.common.Permissions}</StyledText>
      <CoolCheckboxInput
        label={translate({ defaultMessage: "Manage Admins" })}
        subtitle={translate({ defaultMessage: "Add or remove admins, and manage permissions" })}
        labelType="inside"
        onChange={newVal => {
          const newPermissions = { ...p.permissions };
          if (newVal) {
            newPermissions.manageOrgAccounts = true;
          } else {
            delete newPermissions.manageOrgAccounts;
          }
          p.onUpdatePermissions(newPermissions);
        }}
        value={!!p.permissions.manageOrgAccounts}
      />
      <CoolCheckboxInput
        label={translate({ defaultMessage: "Manage Finances and Registration" })}
        subtitle={translate({
          defaultMessage: "View completed and scheduled payment information, registrations, manage payments, process refunds"
        })}
        labelType="inside"
        onChange={newVal => {
          const newPermissions = { ...p.permissions };
          if (newVal) {
            newPermissions.manageFinances = true;
            newPermissions.viewFinances = true;
          } else {
            delete newPermissions.manageFinances;
          }
          p.onUpdatePermissions(newPermissions);
        }}
        value={!!p.permissions.manageFinances}
      />
      <CoolCheckboxInput
        label={translate({ defaultMessage: "View Finances and Registration" })}
        subtitle={translate({
          defaultMessage: "View completed and scheduled payment information and registrations"
        })}
        labelType="inside"
        onChange={newVal => {
          if (p.permissions.manageFinances) {
            openSuccessToast(
              translate({
                defaultMessage: "Permission automatically granted with the Manage Finances and Registration permission."
              })
            );
            return;
          }

          const newPermissions = { ...p.permissions };
          if (newVal) {
            newPermissions.viewFinances = true;
          } else {
            delete newPermissions.viewFinances;
          }
          p.onUpdatePermissions(newPermissions);
        }}
        value={p.permissions.manageFinances || !!p.permissions.viewFinances}
      />
      <CoolCheckboxInput
        label={translate({ defaultMessage: "Manage Chats" })}
        labelType="inside"
        subtitle={translate({
          defaultMessage:
            "Create and edit organization chat channels, and block/unblock specific memebers from being able to message"
        })}
        onChange={newVal => {
          console.log(newVal);
          const newPermissions = { ...p.permissions };
          if (newVal) {
            newPermissions.manageOrgChat = true;
          } else {
            delete newPermissions.manageOrgChat;
          }
          p.onUpdatePermissions(newPermissions);
        }}
        value={!!p.permissions.manageOrgChat}
      />
    </View>
  );
}
