import { Box, Typography, Button, SvgIcon } from "@material-ui/core";
import {
  AccountId,
  ORG_PERMISSIONS,
  Org,
  OrgInvoice,
  OrgInvoiceChild,
  OrgInvoiceParent,
  OrgInvoiceTypes,
  OrgPaymentInvoice,
  OrgPaymentInvoiceCredit,
  OrgPaymentInvoiceDefault,
  OrgPaymentRefund,
  OrgPaymentType,
  OrgRegistration,
  OrgRegistrationAnswer,
  OrgRegistrationAnswerAdditionalStep,
  OrgRegistrationQuestionType,
  OverallInvoiceStatus,
  PaymentMethodType,
  PlayerBundle,
  PlayerBundle__AccountType,
  PrettyPlayer
} from "@ollie-sports/models";
import { Link, useParams, useHistory } from "react-router-dom";
import _, { update } from "lodash";
import { useOrg } from "../../hooks/useOrg";
import { getBifrost } from "../../services/bifrost.service";
import { dateFormatters, getCurrentLocale, translate } from "@ollie-sports/i18n";
import { TouchableOpacity, View } from "react-native-web";
import { CenteredLoader } from "../../components/CenteredLoader";
import CoolerTable from "../../components/CoolerTable";

import {
  formatMoneyCentsToDollarCentPrettyString,
  getOverallOrgInvoiceAmountDetails,
  getIndividualOrgInvoiceAmountDetails,
  COLORS,
  isIndividualOrgInvoiceEligibleForCreditDeletion,
  PRETTY_OVERALL_ORG_INVOICE_STATUS,
  getRefundAvailabilityDetails,
  compute,
  getPaymentMethodString
} from "@ollie-sports/core";
import moment from "moment";
import { StyledText } from "../../components/StyledText";
import {
  ArrowLeftCircleIcon,
  ArrowPathIcon,
  ArrowUpCircleIcon,
  EyeIcon,
  PlusCircleIcon,
  ReceiptRefundIcon,
  TrashIcon
} from "@heroicons/react/24/outline";
import {
  openOrgPaymentInvoiceCreditAddModal,
  openOrgPaymentInvoiceCreditAddToScheduledPaymentModal
} from "./OrgPaymentInvoiceCreditAdd";
import getFullScreenModal from "../../components/modals/getFullscreenModal";
import { openErrorToast, openSuccessToast } from "../../utils/openErrorToast";
import getAlert from "../../components/modals/getAlert";
import getConfirm from "../../components/modals/getConfirm";
import { wrapPromiseWithLoader } from "../../utils/wrapPromiseWithLoader";
import { PrettyCoolDateInput, PrettyCoolTextInput } from "../../components/Form";
import { ShadowView } from "../../components/ShadowView";
import { ArrowDown, Paperclip } from "react-feather";
import { openPaymentForgivenessModal } from "../../utils/openPaymentForgivenessModal";
import { openPaymentRefundModal } from "../../utils/openPaymentRefundModal";
import { OrgRegistrationAnswersTable } from "./components/OrgRegistrationAnswersTable";
import { BackButton } from "../../components/BackButton";
import { getCurrentUserAccountId } from "../../hooks/commonDataUtils";
import { useEffect } from "react";
import { useAccounts } from "../../hooks/useAccounts";
import { InfoTooltipIcon } from "../../components/InfoTooltip";
import { KeyboardArrowDown, More } from "@material-ui/icons";
import { ActionButtonDropdown } from "../../components/ActionButtonDropdown";
import { openOrgInvoiceAddEditModal } from "./OrgInvoiceAddEdit";
import { TableSectionWrapper } from "./components/TableSectionWrapper";
import { StyledButton } from "../../components/StyledButton";

export default function OrgInvoiceDetails() {
  const params: any = useParams();
  const orgId = params.orgId;
  const orgInvoiceId = params.orgInvoiceId;
  const playerId = params.playerId;

  const { org, isLoading: isLoadingOrg } = useOrg({ orgId });

  const {
    data: orgInvoiceData,
    isLoading: isLoadingOrgInvoiceData,
    refetch
  } = getBifrost().orgInvoice__client__getParentOrgInvoiceAndAllRelatedInvoicesAndPayments.useClient(
    { id: orgInvoiceId, orgId },
    { notifyOnMetaDataChanges: true }
  );

  const { data: prettyPlayer } = getBifrost().player__server__getPrettyPlayer.useServer(
    {
      playerId
    },
    { enabled: !!playerId }
  );

  const { data: orgRegistration } = getBifrost().orgRegistration__client__getOrgRegistrationForOrgInvoice.useClient(
    {
      orgId,
      orgRegistrationInvoiceId:
        orgInvoiceData?.parentOrgInvoice.type === OrgInvoiceTypes.registration ? orgInvoiceData.parentOrgInvoice.id : ""
    },
    { enabled: orgInvoiceData?.parentOrgInvoice.type === OrgInvoiceTypes.registration }
  );

  return (
    <Box px={3} py={2} display="flex" style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        {isLoadingOrg || isLoadingOrgInvoiceData ? (
          <CenteredLoader />
        ) : org && orgInvoiceData ? (
          <OrgInvoiceDetailsInner
            org={org}
            parentOrgInvoice={orgInvoiceData.parentOrgInvoice}
            childrenOrgInvoices={orgInvoiceData.childrenOrgInvoices}
            orgPayments={orgInvoiceData.orgPayments}
            orgRefunds={orgInvoiceData.orgRefunds}
            playerBundle={orgInvoiceData.playerBundle}
            onRefetch={async () => {
              await refetch();
            }}
            prettyPlayer={prettyPlayer}
            orgRegistration={orgRegistration}
          />
        ) : (
          <Typography>{translate({ defaultMessage: "Failed to load details" })}</Typography>
        )}
      </View>
    </Box>
  );
}

function OrgInvoiceDetailsInner(p: {
  org: Org;
  parentOrgInvoice: OrgInvoiceParent;
  childrenOrgInvoices: OrgInvoiceChild[];
  orgRefunds: OrgPaymentRefund[];
  orgPayments: OrgPaymentInvoice[];
  playerBundle: PlayerBundle;
  onRefetch: () => Promise<void>;
  prettyPlayer?: PrettyPlayer;
  orgRegistration?: OrgRegistration;
}) {
  const { data: registrationAnswers, refetch: refetchAnswers } =
    getBifrost().orgRegistrationAnswer__client__getAnswersForOrgInvoiceRegistration.useClient(
      {
        orgInvoiceId: p.parentOrgInvoice.id,
        orgId: p.parentOrgInvoice.orgId
      },
      { enabled: p.parentOrgInvoice.type === OrgInvoiceTypes.registration, notifyOnMetaDataChanges: true }
    );

  const { data: additionalSteps } = getBifrost().orgRegistrationQuestion__server__getAdditionalStepsForOrgRegistration.useClient(
    {
      orgRegistrationId: p.orgRegistration?.id ?? "",
      orgRegistrationPackageId:
        p.parentOrgInvoice.type === OrgInvoiceTypes.registration ? p.parentOrgInvoice.orgRegistrationPackageId : ""
    },
    { enabled: p.parentOrgInvoice.type === OrgInvoiceTypes.registration && !!p.orgRegistration }
  );

  const registrationAnswersExcludingAdditionalSteps = registrationAnswers?.filter(
    a => a.type !== OrgRegistrationQuestionType.additionalStep
  );

  const history = useHistory();
  const rowItemsBase: {
    orgInvoice: OrgInvoice;
    orgRefunds: OrgPaymentRefund[];
    orgPayments: OrgPaymentInvoice[];
  }[] = [p.parentOrgInvoice, ...p.childrenOrgInvoices]
    .map(invoice => {
      return {
        orgInvoice: invoice,
        // Payment TODO: Might replace this back to completedRefunds
        orgRefunds: p.orgRefunds.filter(r => r.orgInvoiceId === invoice.id),
        orgPayments: p.orgPayments.filter(op => op.invoiceId === invoice.id)
      };
    })
    .filter(a => (a.orgInvoice.id === p.parentOrgInvoice.id ? a.orgPayments.length > 0 : true));

  const { accounts: refundAccounts } = useAccounts({
    accountIds: _.uniq(p.orgRefunds.map(a => a.refundedByAccountId))
  });

  const paymentsTableItems = rowItemsBase.map(rib => {
    return {
      ...rib,
      ...getIndividualOrgInvoiceAmountDetails({
        orgInvoice: rib.orgInvoice,
        orgPayments: rib.orgPayments,
        paymentMethodType: PaymentMethodType.card // Doesn't matter here cause we don't show the fees
      })
    };
  });

  const overallDetails = getOverallOrgInvoiceAmountDetails({
    childrenOrgInvoices: p.childrenOrgInvoices,
    orgPayments: p.orgPayments,
    parentOrgInvoice: p.parentOrgInvoice
  });

  const creditGroupings: { credit: OrgPaymentInvoiceCredit; invoice: OrgInvoice; orgPayments: OrgPaymentInvoice[] }[][] = _(
    (p.orgPayments.filter(op => op.type === OrgPaymentType.invoiceCredit) as OrgPaymentInvoiceCredit[]).map(a => {
      const orgInvoice = [p.parentOrgInvoice, ...p.childrenOrgInvoices].find(oi => oi.id === a.invoiceId);
      if (orgInvoice) {
        return {
          invoice: orgInvoice,
          credit: a,
          orgPayments: p.orgPayments.filter(op => op.type === OrgPaymentType.invoiceDefault && op.invoiceId === orgInvoice.id)
        };
      }
    })
  )
    .compact()
    .groupBy(a => a.credit.groupingId)
    .orderBy(a => a[0].credit.createdAtMS, "desc")
    .value();

  const selfAthleteAccountId = Object.keys(p.playerBundle.managingAccounts || {}).find(
    accId => p.playerBundle.managingAccounts?.[accId]?.type === PlayerBundle__AccountType.selfAthlete
  );

  const selfAthleteAccountIdOrPlayerBundleId = selfAthleteAccountId ?? p.playerBundle.id;

  const actions = _.compact([
    !p.parentOrgInvoice.thisInvoicePaidInFullDateMS
      ? {
          key: "edit",
          label: translate.common.Edit,
          onClick: async () => {
            openOrgInvoiceAddEditModal({
              org: p.org,
              type: "edit",
              initialOrgInvoice: p.parentOrgInvoice as any
            });
          }
        }
      : null,
    !!p.parentOrgInvoice.thisInvoicePaidInFullDateMS &&
    overallDetails.remainingAmount &&
    compute.org.hasOrgPermission({
      accountId: getCurrentUserAccountId(),
      org: p.org,
      permission: ORG_PERMISSIONS.manageFinances
    })
      ? {
          key: "credits",
          label: translate({ defaultMessage: "Add Credits" }),
          onClick: async () => {
            await openOrgPaymentInvoiceCreditAddModal({
              orgInvoiceParent: p.parentOrgInvoice,
              maxAmountCents: overallDetails.remainingAmount,
              scheduledChildrenOrgInvoices: p.childrenOrgInvoices.filter(oi => !oi.thisInvoicePaidInFullDateMS),
              orgPayments: p.orgPayments,
              onComplete: p.onRefetch
            });
          }
        }
      : null,
    !p.parentOrgInvoice.thisInvoicePaidInFullDateMS
      ? {
          key: "delete",
          label: translate({ defaultMessage: "Delete Invoice" }),
          onClick: async () => {
            const confirm = await getConfirm({
              confirmButtonColor: "red",
              subtitle: translate({ defaultMessage: "Are you sure you want to delete this invoice?" }),
              title: translate({ defaultMessage: "Delete Invoice?" })
            });
            if (confirm) {
              await getBifrost().orgInvoice__client__deleteOrgInvoice.fetchClient({
                id: p.parentOrgInvoice.id,
                orgId: p.org.id
              });
              wrapPromiseWithLoader(
                new Promise(res => {
                  setTimeout(() => {
                    history.goBack();
                    res(null);
                  }, 2000);
                })
              );
            }
          }
        }
      : null
  ]);

  return (
    <View style={{ flex: 1 }}>
      <BackButton />
      <View style={{ flexDirection: "row" }}>
        <h1 className="flex-1 text-2xl sm:text-4xl mt-4">
          {p.parentOrgInvoice.type === OrgInvoiceTypes.registration
            ? translate({ defaultMessage: "Registration Details" })
            : translate({ defaultMessage: "Invoice Details" })}
        </h1>
        <ActionButtonDropdown actions={actions}>
          {translate({ defaultMessage: "Actions" })}
          <SvgIcon style={{ paddingLeft: 6, fontSize: 30 }}>
            <KeyboardArrowDown />
          </SvgIcon>
        </ActionButtonDropdown>
      </View>

      <ShadowView
        style={{
          backgroundColor: COLORS.white,
          padding: 16,
          borderRadius: 16,
          maxWidth: 400,
          marginTop: 16
        }}
      >
        <div className="flex">
          <div className="flex-1">
            <OrgInvoiceDetailRow value={"#" + p.parentOrgInvoice.id} />
          </div>

          <StyledText
            style={{
              fontWeight: "bold",
              color: [
                OverallInvoiceStatus.invoiceFailedPayment,
                OverallInvoiceStatus.late,
                OverallInvoiceStatus.latePaymentInstallment
              ].includes(overallDetails.status)
                ? COLORS.red
                : overallDetails.status === OverallInvoiceStatus.paid
                ? COLORS.green
                : COLORS.yellow
            }}
          >
            {PRETTY_OVERALL_ORG_INVOICE_STATUS(getCurrentLocale())[overallDetails.status]}
          </StyledText>
        </div>
        <Link to={`/app/org/${p.org.id}/registrationDashboard/members/${selfAthleteAccountIdOrPlayerBundleId}`} className="flex">
          <StyledText
            style={{ fontWeight: "bold", color: COLORS.blue, textDecoration: "underline" }}
          >{`${p.playerBundle.virtualAthleteAccount.firstName} ${p.playerBundle.virtualAthleteAccount.lastName}`}</StyledText>
        </Link>

        <Link to="" style={{ marginBottom: 8 }}></Link>

        <OrgInvoiceDetailRow
          label={translate({ defaultMessage: "Issued On" })}
          value={dateFormatters.mmm_d_yyyy(p.parentOrgInvoice.createdAtMS)}
        />
        <OrgInvoiceDetailRow
          label={translate({ defaultMessage: "Invoiced Amount" })}
          value={formatMoneyCentsToDollarCentPrettyString(
            overallDetails.totalAmount + (p.parentOrgInvoice.appliedCouponCodeDetails?.discountAmountCents ?? 0)
          )}
        />
        <OrgInvoiceDetailRow
          label={translate({ defaultMessage: "Total Late Fees" })}
          value={formatMoneyCentsToDollarCentPrettyString(
            overallDetails.currentlyDueLateFeesAmount + overallDetails.pastPaidLateFeesAmount
          )}
        />
        {p.parentOrgInvoice.appliedCouponCodeDetails ? (
          <OrgInvoiceDetailRow
            label={translate({ defaultMessage: "Coupon Discount" })}
            value={formatMoneyCentsToDollarCentPrettyString(p.parentOrgInvoice.appliedCouponCodeDetails.discountAmountCents)}
            type={"paymentOrCredit"}
          />
        ) : null}
        {overallDetails.creditAmount ? (
          <OrgInvoiceDetailRow
            label={translate({ defaultMessage: "Credit Amount" })}
            value={formatMoneyCentsToDollarCentPrettyString(overallDetails.creditAmount)}
            type={"paymentOrCredit"}
          />
        ) : null}
        <OrgInvoiceDetailRow
          label={translate({ defaultMessage: "Amount Paid" })}
          value={formatMoneyCentsToDollarCentPrettyString(
            overallDetails.paidAmount + (overallDetails.pastPaidLateFeesAmount || 0)
          )}
          type={"paymentOrCredit"}
        />
        <OrgInvoiceDetailRow
          label={translate({ defaultMessage: "Balance Remaining" })}
          value={formatMoneyCentsToDollarCentPrettyString(overallDetails.remainingAmount)}
        />
        {/* Payment TODO Might break this back out based on refund status */}
        {p.orgRefunds.length ? (
          <OrgInvoiceDetailRow
            label={translate({ defaultMessage: "Amount Refunded" })}
            value={formatMoneyCentsToDollarCentPrettyString(_.sum(p.orgRefunds.map(a => a.totalAmountRefundedCents)))}
            type={"paymentOrCredit"}
          />
        ) : null}
        <OrgInvoiceDetailRow
          label={
            p.childrenOrgInvoices.length
              ? translate({ defaultMessage: "Full Balance Due By" })
              : translate({ defaultMessage: "Due Date" })
          }
          value={dateFormatters.mmm_d_yyyy(p.childrenOrgInvoices.slice().pop()?.dueDateMS ?? p.parentOrgInvoice.dueDateMS)}
          isBottomRow
        />
        {p.parentOrgInvoice.type === OrgInvoiceTypes.manual &&
        !paymentsTableItems.some(a => a.orgInvoice.thisInvoicePaidInFullDateMS) ? (
          <div className="bg-gray-100 rounded-md overflow-hidden flex items-center w-full mt-5">
            <div className="py-3 px-4">
              <Paperclip className="text-gray-400 h-4 w-4" />
            </div>
            <div id="invoice-link-url" className="text-gray-400 text-sm whitespace-nowrap text-ellipsis flex-1 overflow-hidden">
              <span>{`${window.location.origin}/org/${p.org.id}/payInvoice/${p.parentOrgInvoice.id}`}</span>
            </div>
            <button
              className="bg-blue-500 py-3 px-4 ml-4 rounded-md text-white h-full"
              onClick={e => {
                navigator.clipboard.writeText(document.getElementById("invoice-link-url")!.textContent!);
                (e.nativeEvent.target as Element).textContent = translate({ defaultMessage: "Copied!" });
              }}
            >
              {translate({ defaultMessage: "Copy" })}
            </button>
          </div>
        ) : null}
      </ShadowView>

      <TableSectionWrapper title={translate({ defaultMessage: "Payments & Scheduled Payments" })}>
        <CoolerTable
          items={paymentsTableItems}
          noItemsMessage={translate({ defaultMessage: "No payments made or scheduled..." })}
          columnDefs={[
            {
              label: translate.common.Scheduled,
              getValue(item) {
                return item.orgInvoice.thisInvoicePaidInFullDateMS
                  ? ""
                  : item.orgInvoice.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
                    item.orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment
                  ? dateFormatters.mm_dd_yyyy(moment(item.orgInvoice.autoChargeDateMS).toDate(), getCurrentLocale())
                  : "";
              },
              editable: {
                isEditable(item) {
                  return (
                    (item.orgInvoice.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
                      item.orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment) &&
                    !!getIndividualOrgInvoiceAmountDetails({
                      orgInvoice: item.orgInvoice,
                      orgPayments: item.orgPayments,
                      paymentMethodType: PaymentMethodType.card // Doesn't matter
                    }).remainingAmount
                  );
                },
                async onComplete(updatedItem) {
                  await wrapPromiseWithLoader(
                    new Promise<null>(async (resolve, reject) => {
                      if (
                        updatedItem.orgInvoice.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
                        updatedItem.orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment
                      ) {
                        await getBifrost().orgInvoice__client__updateIndividualOrgInvoice.fetchClient({
                          orgInvoiceId: updatedItem.orgInvoice.id,
                          updatedInvoice: {
                            autoChargeDateMS: updatedItem.orgInvoice.autoChargeDateMS,
                            dueDateMS: Math.max(
                              updatedItem.orgInvoice.dueDateMS,
                              moment(updatedItem.orgInvoice.autoChargeDateMS).add(1, "day").valueOf()
                            )
                          }
                        });
                        await p.onRefetch();
                      }
                      resolve(null);
                    })
                  );
                },
                onError(e) {
                  openErrorToast(
                    translate({
                      defaultMessage:
                        "There was a problem updating the payment. Please try again or contact support@olliesports.com"
                    })
                  );
                },
                render: a => {
                  return (
                    <PrettyCoolDateInput
                      onChange={newVal => {
                        if (newVal) {
                          if (
                            a.item.orgInvoice.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
                            a.item.orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment
                          ) {
                            a.setItem({
                              orgInvoice: {
                                ...a.item.orgInvoice,
                                autoChargeDateMS: moment(newVal).startOf("day").add(10, "hours").valueOf()
                              }
                            });
                            a.setTempState(newVal || undefined);
                          }
                        }
                      }}
                      minDate={moment().add(1, "day").startOf("day").toDate()}
                      value={a.tempState}
                    />
                  );
                },
                getInitialTempStateOnEditStart(currItem) {
                  if (
                    currItem.orgInvoice.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
                    currItem.orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment
                  ) {
                    return moment(currItem.orgInvoice.autoChargeDateMS).toDate();
                  }
                  return undefined;
                }
              }
            },
            {
              label: translate.common.Due,
              getValue(item) {
                return dateFormatters.mm_dd_yyyy(moment(item.orgInvoice.dueDateMS).toDate(), getCurrentLocale());
              },
              editable: {
                isEditable(item) {
                  return !!getIndividualOrgInvoiceAmountDetails({
                    orgInvoice: item.orgInvoice,
                    orgPayments: item.orgPayments,
                    paymentMethodType: PaymentMethodType.card // Doesn't matter
                  }).remainingAmount;
                },
                async onComplete(updatedItem) {
                  await wrapPromiseWithLoader(
                    new Promise<null>(async (resolve, reject) => {
                      await getBifrost().orgInvoice__client__updateIndividualOrgInvoice.fetchClient({
                        orgInvoiceId: updatedItem.orgInvoice.id,
                        updatedInvoice: {
                          dueDateMS: moment(updatedItem.orgInvoice.dueDateMS).endOf("day").valueOf()
                        }
                      });
                      await p.onRefetch();
                      resolve(null);
                    })
                  );
                },
                onError(e) {
                  openErrorToast(
                    translate({
                      defaultMessage:
                        "There was a problem updating the payment. Please try again or contact support@olliesports.com"
                    })
                  );
                },
                render: a => {
                  return (
                    <PrettyCoolDateInput
                      onChange={newVal => {
                        if (newVal) {
                          a.setItem({
                            orgInvoice: {
                              ...a.item.orgInvoice,
                              dueDateMS: moment(newVal).startOf("day").valueOf()
                            }
                          });
                          a.setTempState(newVal || undefined);
                        }
                      }}
                      minDate={
                        a.item.orgInvoice.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
                        a.item.orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment
                          ? moment(a.item.orgInvoice.autoChargeDateMS).add(1, "day").toDate()
                          : moment().add(1, "day").startOf("day").toDate()
                      }
                      value={a.tempState}
                    />
                  );
                },
                getInitialTempStateOnEditStart(currItem) {
                  if (
                    currItem.orgInvoice.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
                    currItem.orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment
                  ) {
                    return moment(currItem.orgInvoice.dueDateMS).toDate();
                  }
                  return undefined;
                }
              }
            },
            {
              label: translate.common.Paid,
              getValue(item) {
                return item.orgInvoice.thisInvoicePaidInFullDateMS
                  ? dateFormatters.mm_dd_yyyy(moment(item.orgInvoice.thisInvoicePaidInFullDateMS).toDate(), getCurrentLocale())
                  : "";
              }
            },
            {
              label: translate.common.Status,
              getCellCustomClassName: item => (item.status === "failed" || item.status === "pastDue" ? "text-red-500" : ""),
              getValue(item) {
                switch (item.status) {
                  case "paid":
                    return translate.common.Paid;
                  case "partial":
                    return translate.common.Partial;
                  case "failed":
                    return translate.common.Failed;
                  case "pastDue":
                    return translate.common.PastDue;
                  case "scheduled":
                    return translate.common.Scheduled;
                  case "unpaid":
                    return translate.common.Unpaid;
                }
              }
            },
            {
              label: translate({ defaultMessage: "Amt. Invoiced" }),
              getValue(item) {
                return formatMoneyCentsToDollarCentPrettyString(item.totalAmount);
              },
              getValueForTotalRow(items) {
                return formatMoneyCentsToDollarCentPrettyString(_.sum(items.map(item => item.totalAmount)));
              },
              editable: {
                isEditable(item) {
                  return (
                    (item.orgInvoice.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
                      item.orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment) &&
                    !!getIndividualOrgInvoiceAmountDetails({
                      orgInvoice: item.orgInvoice,
                      orgPayments: item.orgPayments,
                      paymentMethodType: PaymentMethodType.card // Doesn't matter
                    }).remainingAmount
                  );
                },
                async onComplete(updatedItem) {
                  await wrapPromiseWithLoader(
                    new Promise<null>(async (resolve, reject) => {
                      if (
                        updatedItem.orgInvoice.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
                        updatedItem.orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment
                      ) {
                        if (updatedItem.creditAmount && updatedItem.creditAmount > updatedItem.totalAmount) {
                          openErrorToast(
                            translate({
                              defaultMessage: "The total amount can't be less than the amount of credit applied."
                            })
                          );
                          resolve(null);
                          return;
                        }
                        console.log(updatedItem);
                        await getBifrost().orgInvoice__client__updateInstallmentAmounInvoiced.fetchClient({
                          orgInvoiceId: updatedItem.orgInvoice.id,
                          newAmountCents: updatedItem.totalAmount
                        });
                        await p.onRefetch();
                      }
                      resolve(null);
                    })
                  );
                },
                onError(e) {
                  openErrorToast(
                    translate({
                      defaultMessage:
                        "There was a problem updating the payment. Please try again or contact support@olliesports.com"
                    })
                  );
                },
                render: a => {
                  return (
                    <PrettyCoolTextInput
                      isRequired
                      isMoney
                      onChange={newAmtRaw => {
                        const newAmt = newAmtRaw?.match(/\d+\.?\d?\d?/)?.[0];
                        a.setTempState(newAmt || "");
                        a.setItem({ totalAmount: newAmt ? Number(newAmt) * 100 : 0 });
                      }}
                      inputProps={{ type: "number" }}
                      value={a.tempState}
                    />
                    // <PrettyCoolDateInput
                    //   onChange={newVal => {
                    //     if (newVal) {
                    //       if (
                    //         a.item.orgInvoice.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
                    //         a.item.orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment
                    //       ) {
                    //         a.setItem({
                    //           orgInvoice: {
                    //             ...a.item.orgInvoice,
                    //             autoChargeDateMS: moment(newVal).startOf("day").add(10, "hours").valueOf()
                    //           }
                    //         });
                    //         a.setTempState(newVal || undefined);
                    //       }
                    //     }
                    //   }}
                    //   minDate={moment().add(1, "day").startOf("day").toDate()}
                    //   value={a.tempState}
                    // />
                  );
                },
                getInitialTempStateOnEditStart(currItem) {
                  if (
                    currItem.orgInvoice.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
                    currItem.orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment
                  ) {
                    return moment(currItem.orgInvoice.autoChargeDateMS).toDate();
                  }
                  return undefined;
                }
              }
            },
            {
              label: translate({ defaultMessage: "Credit Amount" }),
              getValue(item) {
                const credits = p.orgPayments.filter(
                  op => op.type === OrgPaymentType.invoiceCredit && op.invoiceId === item.orgInvoice.id
                ) as OrgPaymentInvoiceCredit[];
                return (
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <StyledText>{formatMoneyCentsToDollarCentPrettyString(item.creditAmount)}</StyledText>
                    {credits.length && item.creditAmount ? (
                      <TouchableOpacity
                        onPress={async () => {
                          await getFullScreenModal({
                            title: translate.common.Credits,
                            containerStyle: { maxWidth: 500 },
                            children: <CreditsTable credits={credits} />
                          });
                        }}
                        style={{ width: 20, height: 20, marginLeft: 4 }}
                      >
                        <EyeIcon color={COLORS.blue_66} />
                      </TouchableOpacity>
                    ) : null}
                  </View>
                );
              },
              getValueForTotalRow(items) {
                return formatMoneyCentsToDollarCentPrettyString(_.sum(items.map(item => item.creditAmount)));
              }
            },
            {
              label: translate({ defaultMessage: "Amount Paid" }),
              getValue(item) {
                return formatMoneyCentsToDollarCentPrettyString(item.paidAmount);
              },
              getValueForTotalRow(items) {
                return formatMoneyCentsToDollarCentPrettyString(_.sum(items.map(item => item.paidAmount)));
              }
            },
            {
              label: translate({ defaultMessage: "Amount Paid + Fees" }),

              getValue(item) {
                return formatMoneyCentsToDollarCentPrettyString(
                  item.lateFeesPaidAmount + item.paidAmount + item.otherFeesPaidAmount
                );
              },
              getValueForTotalRow(items) {
                return formatMoneyCentsToDollarCentPrettyString(
                  _.sum(items.map(item => item.lateFeesPaidAmount + item.paidAmount + item.otherFeesPaidAmount))
                );
              }
            },
            // Payment TODO: Might need to break this out based on refund status */
            p.orgRefunds.length
              ? {
                  label: translate({ defaultMessage: "Amount Refunded" }),
                  getValue(item) {
                    return formatMoneyCentsToDollarCentPrettyString(_.sum(item.orgRefunds.map(a => a.totalAmountRefundedCents)));
                  },
                  getValueForTotalRow(items) {
                    return formatMoneyCentsToDollarCentPrettyString(
                      _.sum(items.map(item => _.sum(item.orgRefunds.map(a => a.totalAmountRefundedCents))))
                    );
                  }
                }
              : null,
            {
              label: translate({ defaultMessage: "Balance" }),
              getValue(item) {
                return formatMoneyCentsToDollarCentPrettyString(item.remainingAmount);
              },
              getValueForTotalRow(items) {
                return formatMoneyCentsToDollarCentPrettyString(_.sum(items.map(item => item.remainingAmount)));
              }
            },
            {
              label: translate({ defaultMessage: "Payment Method" }),
              getValue(item) {
                const paymentMethodSnapshot = (
                  item.orgPayments.find(op => op.type === OrgPaymentType.invoiceDefault && op.paymentMethodSnapshot) as
                    | OrgPaymentInvoiceDefault
                    | undefined
                )?.paymentMethodSnapshot;
                return paymentMethodSnapshot
                  ? getPaymentMethodString({
                      paymentMethod: paymentMethodSnapshot,
                      includeName: false,
                      locale: getCurrentLocale()
                    })
                  : "";
              }
            }
          ]}
          rowButtons={
            compute.org.hasOrgPermission({
              accountId: getCurrentUserAccountId(),
              org: p.org,
              permission: ORG_PERMISSIONS.manageFinances
            })
              ? [
                  {
                    isVisible: item => {
                      const defaultPmt = item.orgPayments.find(
                        pmt => pmt.type === OrgPaymentType.invoiceDefault
                      ) as OrgPaymentInvoiceDefault;
                      return (
                        !!defaultPmt &&
                        getRefundAvailabilityDetails({ payment: defaultPmt, refunds: item.orgRefunds }).canIssueRefund
                      );
                    },
                    getLabel: () => translate({ defaultMessage: "Issue Refund" }),
                    type: "dropdown",
                    getIcon: () => <ReceiptRefundIcon color={COLORS.blue} />,
                    onClick: async item => {
                      const defaultPmt = item.orgPayments.find(
                        pmt => pmt.type === OrgPaymentType.invoiceDefault
                      ) as OrgPaymentInvoiceDefault;

                      openPaymentRefundModal({
                        payment: defaultPmt,
                        previousRefunds: item.orgRefunds,
                        onComplete: async () => {}
                      });
                    }
                  },
                  {
                    isVisible: item => {
                      const details = getIndividualOrgInvoiceAmountDetails({
                        orgInvoice: item.orgInvoice,
                        orgPayments: item.orgPayments,
                        paymentMethodType: PaymentMethodType.card // Doesn't matter
                      });

                      return details.remainingAmount > 0;
                    },
                    getLabel: () => translate({ defaultMessage: "Forgive" }),
                    type: "dropdown",
                    getIcon: () => {
                      return (
                        <svg style={{ fill: COLORS.blue }} viewBox="0 0 24 24">
                          <path d="M16 13c3.09-2.81 6-5.44 6-7.7C22 3.45 20.55 2 18.7 2c-1.04 0-2.05.49-2.7 1.25C15.34 2.49 14.34 2 13.3 2 11.45 2 10 3.45 10 5.3c0 2.26 2.91 4.89 6 7.7zm-2.7-9c.44 0 .89.21 1.18.55L16 6.34l1.52-1.79c.29-.34.74-.55 1.18-.55.74 0 1.3.56 1.3 1.3 0 1.12-2.04 3.17-4 4.99-1.96-1.82-4-3.88-4-4.99 0-.74.56-1.3 1.3-1.3zM19 16h-2c0-1.2-.75-2.28-1.87-2.7L8.97 11H1v11h6v-1.44l7 1.94 8-2.5v-1c0-1.66-1.34-3-3-3zM3 20v-7h2v7H3zm10.97.41L7 18.48V13h1.61l5.82 2.17c.34.13.57.46.57.83 0 0-1.99-.05-2.3-.15l-2.38-.79-.63 1.9 2.38.79c.51.17 1.04.26 1.58.26H19c.39 0 .74.23.9.56l-5.93 1.84z"></path>
                        </svg>
                      );
                    },
                    async onClick(item) {
                      if (
                        item.orgInvoice.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
                        item.orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment
                      ) {
                        const details = getIndividualOrgInvoiceAmountDetails({
                          orgInvoice: item.orgInvoice,
                          orgPayments: item.orgPayments,
                          paymentMethodType: PaymentMethodType.card // Doesn't matter
                        });
                        await openOrgPaymentInvoiceCreditAddToScheduledPaymentModal({
                          maxAmountCents: details.remainingAmount,
                          async onComplete() {
                            await p.onRefetch();
                          },
                          orgInvoice: item.orgInvoice,
                          orgInvoiceParent: p.parentOrgInvoice
                        });
                        await openPaymentForgivenessModal({
                          orgInvoice: item.orgInvoice,
                          remainingAmount: details.remainingAmount,
                          async onComplete() {
                            await p.onRefetch();
                          }
                        });
                      }
                    },
                    getTheme(item) {
                      return "red";
                    }
                  }
                ]
              : []
          }
          getItemKey={item => {
            return item.orgInvoice.id;
          }}
        />
      </TableSectionWrapper>
      {creditGroupings.length ? (
        <div>
          <h1 className="text-lg sm:text-2xl mt-4">{`${translate.common.Credits}`}</h1>
          <CreditGroupingTable groupings={creditGroupings} onDelete={p.onRefetch} org={p.org} />
        </div>
      ) : null}
      {p.orgRefunds.length ? (
        <div>
          <h1 className="text-lg sm:text-2xl mt-4">{`${translate.common.Refunds}`}</h1>
          <CoolerTable
            items={_.orderBy(p.orgRefunds, a => a.createdAtMS, "desc")}
            getItemKey={a => a.id}
            columnDefs={[
              {
                label: translate.common.Date,
                getValue(item) {
                  return dateFormatters.mm_dd_yyyy(moment(item.createdAtMS).toDate(), getCurrentLocale());
                }
              },
              {
                label: translate.common.Amount,
                getValue(item) {
                  return formatMoneyCentsToDollarCentPrettyString(item.totalAmountRefundedCents);
                }
              },
              {
                label: translate.common.Note,
                getValue(item) {
                  return item.note;
                }
              },
              {
                label: translate({ defaultMessage: "Refunded By", description: "As in the account who triggered the refund." }),
                getValue(item) {
                  const account = refundAccounts?.find(acc => acc.id === item.refundedByAccountId);
                  if (account) {
                    return `${account.firstName} ${account.lastName}`;
                  }
                  return "";
                }
              }
            ]}
          />
        </div>
      ) : null}
      {registrationAnswersExcludingAdditionalSteps?.length ? (
        <RegistrationAnswersTable registrationAnswers={registrationAnswersExcludingAdditionalSteps} />
      ) : null}
      {p.orgRegistration && additionalSteps?.length ? (
        <TableSectionWrapper title={translate({ defaultMessage: "Additional Steps" })}>
          <CoolerTable
            items={_.orderBy(additionalSteps, a => a.shortTitle, "asc")}
            columnDefs={[
              {
                getValue(item) {
                  return item.shortTitle;
                },
                label: translate.common.Title
              },
              {
                getValue(item) {
                  return item.descriptionMD;
                },
                label: translate.common.Description
              },
              {
                label: translate({ defaultMessage: "Completed" }),
                getValue(item) {
                  const answer = registrationAnswers?.find(a => a.questionId === item.id);
                  if (answer && answer.type === OrgRegistrationQuestionType.additionalStep) {
                    return dateFormatters.mm_dd_yyyy(moment(answer.completedAtMS).toDate(), getCurrentLocale());
                  }
                  return (
                    <StyledButton
                      className="w-32"
                      onClick={async () => {
                        const confirm = await getConfirm({
                          title: translate({ defaultMessage: "Complete", description: "Verb, as in to complete a task" }),
                          subtitle: translate({ defaultMessage: "Do you want to mark this additional step as completed?" }),
                          confirmButtonColor: "blue"
                        });
                        if (confirm) {
                          await getBifrost().orgRegistrationAnswer__client__completeAdditionalStepAnswer.fetchClient({
                            answer: {
                              type: OrgRegistrationQuestionType.additionalStep,
                              completedAtMS: Date.now(),
                              orgId: p.org.id,
                              questionId: item.id,
                              questionSnapshot: item
                            },
                            orgRegistrationId: p.orgRegistration!.id
                          });
                          await refetchAnswers();
                        }
                      }}
                      text={translate({ defaultMessage: "Complete", description: "Verb, as in to complete a task" })}
                    />
                  );
                }
              }
            ]}
            getItemKey={a => a.id}
          />
        </TableSectionWrapper>
      ) : null}
    </View>
  );
}

function RegistrationAnswersTable(p: { registrationAnswers: OrgRegistrationAnswer[] }) {
  return <OrgRegistrationAnswersTable orgRegistrationAnswers={p.registrationAnswers} />;
}

function CreditGroupingTable(p: {
  groupings: { credit: OrgPaymentInvoiceCredit; invoice: OrgInvoice; orgPayments: OrgPaymentInvoice[] }[][];
  onDelete: () => Promise<void>;
  org: Org;
}) {
  return (
    <CoolerTable
      items={p.groupings.filter(g => g.length)}
      getItemKey={item => {
        return item.map(a => a.credit.id).join("");
      }}
      noItemsMessage={translate({ defaultMessage: "No credits applied to invoice..." })}
      columnDefs={[
        {
          label: translate.common.Date,
          getValue(item) {
            return dateFormatters.mm_dd_yyyy(moment(item[0].credit.createdAtMS).toDate(), getCurrentLocale());
          }
        },
        {
          label: translate.common.Amount,
          getValue(item) {
            return formatMoneyCentsToDollarCentPrettyString(_.sum(item.map(a => a.credit.amountCents)));
          }
        },
        {
          label: translate({ defaultMessage: "Applied By", description: `As in a credit was "Applied By" John` }),
          getValue(item) {
            return <AccountName accountId={item[0].credit.appliedByAccountId} />;
          }
        },
        {
          label: translate.common.Note,
          getValue(item) {
            return item[0].credit.note;
          }
        },
        {
          label: translate({ defaultMessage: "# Payments Applied To" }),
          getValue(item) {
            return item.length;
          }
        }
      ]}
      rowButtons={
        compute.org.hasOrgPermission({
          accountId: getCurrentUserAccountId(),
          org: p.org,
          permission: ORG_PERMISSIONS.manageFinances
        })
          ? [
              {
                getLabel: () => translate.common.Delete,
                type: "inline",
                getIcon: () => {
                  return <TrashIcon color={COLORS.red_66} />;
                },
                async onClick(item) {
                  const invoicesWhereCreditIsEligibleForDeletion = item.filter(a =>
                    isIndividualOrgInvoiceEligibleForCreditDeletion({
                      orgInvoice: a.invoice,
                      orgPayments: a.orgPayments
                    })
                  );
                  const invoicesWhereCreditIsNotEligibleForDeletion = item.filter(
                    a =>
                      !isIndividualOrgInvoiceEligibleForCreditDeletion({
                        orgInvoice: a.invoice,
                        orgPayments: a.orgPayments
                      })
                  );

                  if (!invoicesWhereCreditIsEligibleForDeletion.length) {
                    return await getAlert({
                      title: translate({ defaultMessage: "Cannot Delete Credit" }),
                      subtitle: `${translate({
                        defaultMessage:
                          "You cannot delete credits if doing so is likely to confuse or frustrate players/parents. For example, a credit cannot be deleted if the user has completed the payment that the credit was applied to or if the credit covered the whole payment and it's past the due date."
                      })}\n\n${translate({
                        defaultMessage:
                          "Please create a new invoice if you need to collect additional money from this player or parent."
                      })}`
                    });
                  }
                  const confirm = await getConfirm({
                    confirmButtonColor: "red",
                    confirmText: translate.common.Delete,
                    title: translate({ defaultMessage: "Delete Credit?" }),
                    subtitle: invoicesWhereCreditIsNotEligibleForDeletion.length
                      ? `${translate(
                          {
                            defaultMessage:
                              "NOTE: Only {amount} of this credit is eligible to deleted. A portion of the credit is not eligible to be deleted because the payment it was applied to has been paid or the credit covered the entire payment and it's past due."
                          },
                          {
                            amount: formatMoneyCentsToDollarCentPrettyString(
                              _.sum(invoicesWhereCreditIsEligibleForDeletion.map(a => a.credit.amountCents))
                            )
                          }
                        )}\n\n${translate({ defaultMessage: "Are you sure you want to delete this credit?" })}`
                      : translate({ defaultMessage: "Are you sure you want to delete this credit?" })
                  });

                  if (confirm) {
                    try {
                      const promise = new Promise(async (resolve, reject) => {
                        await getBifrost().orgPayment__client__deleteOrgPaymentInvoiceCredits.fetchClient({
                          orgPaymentInvoiceCreditIds: invoicesWhereCreditIsEligibleForDeletion.map(a => a.credit.id)
                        });
                        await p.onDelete();
                        resolve(undefined);
                      });
                      await wrapPromiseWithLoader(promise);
                      openSuccessToast();
                    } catch (e) {
                      openErrorToast(
                        translate({
                          defaultMessage:
                            "There was a problem deleting the credit. Please try again or contact support@olliesports.com"
                        })
                      );
                    }
                  }
                },
                getTheme(item) {
                  return "red";
                }
              }
            ]
          : []
      }
    />
  );
}

function CreditsTable(p: { credits: OrgPaymentInvoiceCredit[] }) {
  return (
    <CoolerTable
      items={p.credits}
      columnDefs={[
        {
          label: translate.common.Date,
          getValue(item) {
            return dateFormatters.mm_dd_yyyy(moment(item.createdAtMS).toDate(), getCurrentLocale());
          }
        },
        {
          label: translate.common.Amount,
          getValue(item) {
            return formatMoneyCentsToDollarCentPrettyString(item.amountCents);
          },
          getValueForTotalRow(items) {
            return formatMoneyCentsToDollarCentPrettyString(_.sum(p.credits.map(c => c.amountCents)));
          }
        },
        {
          label: translate({ defaultMessage: "Applied By", description: `As in a credit was "Applied By" John` }),
          getValue(item) {
            return <AccountName accountId={item.appliedByAccountId} />;
          }
        },
        {
          label: translate.common.Note,
          getValue(item) {
            return item.note;
          }
        }
      ]}
      getItemKey={item => item.id}
    />
  );
}

function AccountName(p: { accountId: AccountId }) {
  const { data: account } = getBifrost().account__client__getAccount.useClient({
    accountId: p.accountId
  });
  return <StyledText>{account ? `${account.firstName} ${account.lastName}` : ""}</StyledText>;
}

function OrgInvoiceDetailRow(p: {
  label?: string;
  value: string;
  isBottomRow?: boolean;
  type?: "paymentOrCredit";
  infoToolTipText?: string;
}) {
  return (
    <div
      style={{
        display: "flex",
        // justifyContent: "space-between",
        marginBottom: p.isBottomRow ? 0 : 8
      }}
    >
      {p.label ? <StyledText style={{ fontWeight: "bold", color: COLORS.black, flex: 1 }}>{`${p.label}:`}</StyledText> : null}
      <StyledText style={{ color: COLORS.black, fontWeight: p.label ? "normal" : "bold" }}>{`${
        p.type === "paymentOrCredit" && Number(p.value.replace(/[^\d]/g, "")) ? "-" : ""
      }${p.value}`}</StyledText>
      {p.infoToolTipText ? <InfoTooltipIcon title={p.infoToolTipText} /> : null}
    </div>
  );
}
