import {
  AccountId,
  OrgCoupon,
  OrgId,
  OrgInvoice,
  OrgPayment,
  OrgRegistration,
  OrgRegistrationAnswer,
  OrgRegistrationAnswerId,
  OrgRegistrationPackageId,
  OrgRegistrationStatus,
  OrgSeasonId,
  PlayerBundleId,
  PrettyPlayerBundle,
  TEAM_ROLES,
  Team__StaffTypes
} from "@ollie-sports/models";
import { validateToken, validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import * as express from "express";
import _ from "lodash";
import { OrgMemberDetailsData, PlayerBundleRegistrationData } from "../../constants/Misc";
import { getServerHelpers } from "../../helpers";
import { playerBundle__server__getPrettyPlayerBundle, playerBundle__server__getPrettyPlayerBundles } from "../playerBundle";
import { ObjectKeys, filterOrgPaymentInvoices } from "../../utils";

async function filterActivePrettyPlayerBundlesInOrg(p: { orgId: OrgId; prettyPlayerBundles: PrettyPlayerBundle[] }) {
  const { appOllieFirestoreV2: h } = getServerHelpers();
  const allActiveTeamIds = _(
    p.prettyPlayerBundles.map(ppb => {
      return Object.values(ppb.playerBundle.derived.linkedPlayers)
        .filter(a => a.status === "active")
        .map(a => a.teamId);
    })
  )
    .flatten()
    .compact()
    .value();
  const allTeams = _.compact(await h.Team.getDocs(allActiveTeamIds));
  const managedPrettyPlayerBundlesInOrg = p.prettyPlayerBundles.filter(ppb => {
    const activePlayerTeamIds = Object.values(ppb.playerBundle.derived.linkedPlayers)
      .filter(a => a.status === "active")
      .map(a => a.teamId);
    if (activePlayerTeamIds.find(teamId => allTeams.find(t => t.id === teamId && t.orgId === p.orgId))) {
      return true;
    }
    return false;
  });
  return _.uniqBy(managedPrettyPlayerBundlesInOrg, a => a.playerBundle.id);
}

async function getAllOrgInvoicesAndOrgPaymentsForPlayerBundlesInOrg(p: { playerBundleIds: PlayerBundleId[]; orgId: OrgId }) {
  const { appOllieFirestoreV2: h } = getServerHelpers();
  const [orgInvoices, orgPayments] = await Promise.all([
    await h.OrgInvoice.multiQuery({
      queries: p.playerBundleIds.map(playerBundleId => {
        return {
          where: [
            {
              playerBundleId: ["==", playerBundleId]
            },
            {
              orgId: ["==", p.orgId]
            }
          ]
        };
      })
    }),
    await h.OrgPayment.multiQuery({
      queries: p.playerBundleIds.map(playerBundleId => {
        return {
          where: [
            {
              playerBundleId: ["==", playerBundleId]
            },
            {
              orgId: ["==", p.orgId]
            }
          ]
        };
      })
    })
  ]);
  return {
    orgInvoicesByPlayerBundleId: orgInvoices.docs.reduce((acc, orgInvoice) => {
      const existingArray = acc[orgInvoice.playerBundleId];
      if (existingArray && existingArray.length) {
        acc[orgInvoice.playerBundleId] = [...existingArray, orgInvoice];
      } else {
        acc[orgInvoice.playerBundleId] = [orgInvoice];
      }
      return acc;
    }, {} as Record<PlayerBundleId, OrgInvoice[]>),
    orgPaymentsByPlayerBundleId: filterOrgPaymentInvoices(orgPayments.docs).reduce((acc, orgPayment) => {
      const existingArray = acc[orgPayment.playerBundleId];
      if (existingArray && existingArray.length) {
        acc[orgPayment.playerBundleId] = [...existingArray, orgPayment];
      } else {
        acc[orgPayment.playerBundleId] = [orgPayment];
      }
      return acc;
    }, {} as Record<PlayerBundleId, OrgPayment[]>)
  };
}

async function getOrgCouponsForPlayerBundlesInOrgByPlayerBundleId(p: { playerBundleIds: PlayerBundleId[]; orgId: OrgId }) {
  const { appOllieFirestoreV2: h } = getServerHelpers();
  const orgCoupons = (
    await h.OrgCoupon.multiQuery({
      queries: p.playerBundleIds.map(playerBundleId => {
        return {
          where: [
            {
              playerBundleIds: { [playerBundleId]: ["==", true] }
            },
            {
              orgId: ["==", p.orgId]
            }
          ]
        };
      })
    })
  ).docs;

  return orgCoupons.reduce((acc, orgCoupon) => {
    if (orgCoupon.playerBundleIds) {
      const playerBundleIdsAssignedToThisCoupon = p.playerBundleIds.filter(pbId => !!orgCoupon.playerBundleIds?.[pbId]);
      playerBundleIdsAssignedToThisCoupon.forEach(pbId => {
        const existingArray = acc[pbId];
        if (existingArray && existingArray.length) {
          acc[pbId] = [...existingArray, orgCoupon];
        } else {
          acc[pbId] = [orgCoupon];
        }
      });
    }
    return acc;
  }, {} as Record<PlayerBundleId, OrgCoupon[]>);
}

async function getregistrationDataForPlayerBundleIds(p: {
  playerBundleIds: PlayerBundleId[];
  orgId: OrgId;
}): Promise<PlayerBundleRegistrationData[]> {
  const { getAppPgPool } = getServerHelpers();

  const query1 = `select v.*
  from f_player_bundle_registration_status($2, $1::text[]) v,
       mirror_orgseason os
  where os.id = v.org_season_id;`;

  const query2 = `select org_season_id, org_registration_package_id, rank
  from f_player_bundle_with_ranked_packages_for_each_season($2, $1::text[])
  where org_registration_package_id is not null
  and is_org_season_live = true;`;

  const [r1, r2] = await Promise.all([
    getAppPgPool().query(query1, [p.playerBundleIds, p.orgId]),
    getAppPgPool().query(query2, [p.playerBundleIds, p.orgId])
  ]);

  return r1.rows.map(row => {
    const matchingPackagesForThisSeason = r2.rows.filter(a => a["org_season_id"] === row["org_season_id"]);
    return {
      playerBundleId: row["player_bundle_id"] as PlayerBundleId,
      orgId: row["org_id"] as OrgId,
      prioritizedRegistrationPackageId: (row["prioritized_registration_package_id"] ?? undefined) as
        | OrgRegistrationPackageId
        | undefined,
      status: row["status"] as OrgRegistrationStatus,
      orgRegistration: (row["org_registration"] ?? undefined) as OrgRegistration | undefined,
      orgSeasonId: row["org_season_id"],
      allMatchingPackagesWithRankings: matchingPackagesForThisSeason.reduce((acc, val) => {
        acc[val["org_registration_package_id"]] = val["rank"];
        return acc;
      }, {} as Record<OrgRegistrationPackageId, true>)
    };
  });
}

export async function org__server__getOrgMemberDetailsData(
  p: { orgId: OrgId; currentOrgSeasonIds?: OrgSeasonId[] } & (
    | { type: "account"; accountId: AccountId }
    | { type: "playerBundle"; playerBundleId: PlayerBundleId; ignoreFamilyMembers?: boolean }
  )
): Promise<OrgMemberDetailsData | null> {
  const { appOllieFirestoreV2: h } = getServerHelpers();
  if (p.type === "account") {
    const [account, managedPlayerBundlesData] = await Promise.all([
      h.Account.getDoc(p.accountId),
      h.PlayerBundle.query({
        where: [{ managingAccounts: { [p.accountId]: { exists: ["==", true] } } }, { deletedAtMS: ["==", 0] }]
      })
    ]);
    if (!account) {
      return null;
    }
    const managedPrettyPlayerBundles = await playerBundle__server__getPrettyPlayerBundles({
      ids: managedPlayerBundlesData.docs.map(a => a.id)
    });

    const [managedPrettyPlayerBundlesInOrg, playerBundleRegistrationData, orgInvoiceAndPaymentData, orgCouponsByPlayerBundleId] =
      await Promise.all([
        filterActivePrettyPlayerBundlesInOrg({
          orgId: p.orgId,
          prettyPlayerBundles: managedPrettyPlayerBundles
        }),
        getregistrationDataForPlayerBundleIds({
          playerBundleIds: managedPrettyPlayerBundles.map(ppb => ppb.playerBundle.id),
          orgId: p.orgId
        }),
        getAllOrgInvoicesAndOrgPaymentsForPlayerBundlesInOrg({
          playerBundleIds: managedPrettyPlayerBundles.map(ppb => ppb.playerBundle.id),
          orgId: p.orgId
        }),
        getOrgCouponsForPlayerBundlesInOrgByPlayerBundleId({
          playerBundleIds: managedPrettyPlayerBundles.map(ppb => ppb.playerBundle.id),
          orgId: p.orgId
        })
      ]);

    return {
      type: "account",
      account,
      managedPrettyPlayerBundlesWithMetadata: managedPrettyPlayerBundlesInOrg.map(ppb => {
        return {
          prettyPlayerBundle: ppb,
          registrationData: playerBundleRegistrationData.filter(a => a.playerBundleId === ppb.playerBundle.id),
          orgInvoices: orgInvoiceAndPaymentData.orgInvoicesByPlayerBundleId[ppb.playerBundle.id] ?? [],
          orgPayments: orgInvoiceAndPaymentData.orgPaymentsByPlayerBundleId[ppb.playerBundle.id] ?? [],
          orgCoupons: orgCouponsByPlayerBundleId[ppb.playerBundle.id] ?? []
        };
      })
    };
  } else {
    const prettyPlayerBundle = await playerBundle__server__getPrettyPlayerBundle({ id: p.playerBundleId });
    if (!prettyPlayerBundle) {
      return null;
    }

    const managingAccountIds = ObjectKeys(prettyPlayerBundle.playerBundle.managingAccounts ?? {});

    const otherPlayerBundlesManagedByManagingAccountIds = p.ignoreFamilyMembers
      ? []
      : (
          await h.PlayerBundle.multiQuery({
            queries: managingAccountIds.map(accountId => {
              return { where: [{ managingAccounts: { [accountId]: { exists: ["==", true] } } }, { deletedAtMS: ["==", 0] }] };
            })
          })
        ).docs;
    const otherPrettyPlayerBundlesManagedByManagingAccountIds = await playerBundle__server__getPrettyPlayerBundles({
      ids: otherPlayerBundlesManagedByManagingAccountIds.map(a => a.id)
    });

    const [
      filteredOtherPrettyPlayerBundlesManagedByManagingAccountIds,
      playerBundleRegistrationData,
      orgInvoiceAndPaymentData,
      orgCouponsByPlayerId
    ] = await Promise.all([
      filterActivePrettyPlayerBundlesInOrg({
        orgId: p.orgId,
        prettyPlayerBundles: otherPrettyPlayerBundlesManagedByManagingAccountIds
      }),
      getregistrationDataForPlayerBundleIds({
        playerBundleIds: [prettyPlayerBundle.playerBundle.id],
        orgId: p.orgId
      }),
      getAllOrgInvoicesAndOrgPaymentsForPlayerBundlesInOrg({
        playerBundleIds: [prettyPlayerBundle.playerBundle.id, ...otherPlayerBundlesManagedByManagingAccountIds.map(pb => pb.id)],
        orgId: p.orgId
      }),
      getOrgCouponsForPlayerBundlesInOrgByPlayerBundleId({
        playerBundleIds: [prettyPlayerBundle.playerBundle.id],
        orgId: p.orgId
      })
    ]);

    const registrationData = playerBundleRegistrationData.filter(a => a.playerBundleId === prettyPlayerBundle.playerBundle.id);
    const orgRegistrationQuestionAnswerIds = _.uniq(
      registrationData.reduce((acc, val) => {
        acc = [...acc, ...Object.keys(val.orgRegistration?.answerIds ?? {})];
        return acc;
      }, [] as OrgRegistrationAnswerId[])
    );

    let orgRegistrationAnswers: OrgRegistrationAnswer[] | undefined = undefined;
    if (orgRegistrationQuestionAnswerIds.length) {
      orgRegistrationAnswers = _.compact(await h.OrgRegistrationAnswer.getDocs(orgRegistrationQuestionAnswerIds));
    }

    return {
      type: "playerBundle",
      prettyPlayerBundleWithMetadata: {
        prettyPlayerBundle,
        registrationData,
        orgInvoices: orgInvoiceAndPaymentData.orgInvoicesByPlayerBundleId[prettyPlayerBundle.playerBundle.id] ?? [],
        orgPayments: orgInvoiceAndPaymentData.orgPaymentsByPlayerBundleId[prettyPlayerBundle.playerBundle.id] ?? [],
        orgCoupons: orgCouponsByPlayerId[prettyPlayerBundle.playerBundle.id] ?? [],
        orgRegistrationAnswers
      },
      familyMemberPrettyPlayerBundlesWithMetadata: filteredOtherPrettyPlayerBundlesManagedByManagingAccountIds
        .filter(ppb => ppb.playerBundle.id !== prettyPlayerBundle.playerBundle.id)
        .map(ppb => {
          return {
            prettyPlayerBundle: ppb,
            registrationData: playerBundleRegistrationData.filter(a => a.playerBundleId === ppb.playerBundle.id)
          };
        })
    };
  }
}

org__server__getOrgMemberDetailsData.auth = async (r: express.Request) => {
  // Make sure user has correct permission
  await validateToken(r);
};

// i18n certified - complete
