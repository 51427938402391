import { Typography, useTheme, useMediaQuery, Box, Button, SvgIcon, IconButton } from "@material-ui/core";
import { View } from "react-native-web";
import { ORG_PERMISSIONS, Org, OrgCoupon, OrgCouponType, OrgCouponUseType, PlayerBundleId } from "@ollie-sports/models";
import { CenteredLoader } from "../../components/CenteredLoader";
import { getCurrentLocale, translate } from "@ollie-sports/i18n";
import { useParams } from "react-router-dom";
import { getBifrost } from "../../services/bifrost.service";
import {
  COLORS,
  compute,
  formatMoneyCentsToDollarCentPrettyString,
  isOrgCouponExpired,
  isOrgCouponOutOfUses
} from "@ollie-sports/core";
import moment from "moment";
import CoolerTable from "../../components/CoolerTable";
import _ from "lodash";
import { CoolTextInput } from "../../components/Inputs/CoolTextInput";
import { useState } from "react";
import { CoolSelectInput } from "../../components/Inputs/CoolSelectInput";
import { CoolCheckboxInput } from "../../components/Inputs/CoolCheckboxInput";
import { TrashIcon, ArrowPathIcon, PencilIcon, DocumentDuplicateIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import getConfirm from "../../components/modals/getConfirm";
import { openOrgCouponsAddEditModal } from "./OrgCouponsAddEdit";
import { useOrg } from "../../hooks/useOrg";
import { Tag } from "../orgRegistrationPackages/OrgRegistrationPackagesList";
import { PrettyCoolDateInput, PrettyCoolSelectInput, PrettyCoolTextInput } from "../../components/Form";
import { openErrorToast } from "../../utils/openErrorToast";
import { getCurrentUserAccountId } from "../../hooks/commonDataUtils";

export default function OrgCoupons() {
  const params: any = useParams();
  const orgId = params.orgId;

  const { org, isLoading } = useOrg({ orgId });

  const { data: orgCoupons, isLoading: isLoadingCoupons } =
    getBifrost().orgCoupon__client__getOrgCouponsForOrgSubscription.useClientSubscription(
      {
        orgId
      },
      { notifyOnMetaDataChanges: true }
    );

  return (
    <Box px={3} py={2} display="flex" style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        {isLoading || isLoadingCoupons ? (
          <CenteredLoader />
        ) : org ? (
          <OrgCouponsInner org={org} orgCoupons={orgCoupons ?? []} />
        ) : (
          <Typography>{translate({ defaultMessage: "Failed to load org" })}</Typography>
        )}
      </View>
    </Box>
  );
}

function OrgCouponsInner(p: { org: Org; orgCoupons: OrgCoupon[] }) {
  const theme = useTheme();
  const isMediumDeviceOrSmaller = useMediaQuery(theme.breakpoints.down("md"));
  const [searchInput, setSearchInput] = useState("");
  const [selectedOrgCouponType, setSelectedOrgCouponType] = useState<OrgCouponType | undefined>();
  const [selectedOrgCouponUseType, setSelectedOrgCouponUseType] = useState<OrgCouponUseType | undefined>();
  const [showActiveOnly, setShowActiveOnly] = useState(true);
  const { data: playerBundles } = getBifrost().playerBundle__server__getPrettyPlayerBundles.useServer({
    ids: _.uniq(
      p.orgCoupons.reduce((acc, val) => {
        Object.keys(val.playerBundleIds ?? {}).forEach(pbId => {
          acc.push(pbId);
        });
        return acc;
      }, [] as PlayerBundleId[])
    )
  });

  const hasOrgManageFinancesPermission = compute.org.hasOrgPermission({
    org: p.org,
    accountId: getCurrentUserAccountId(),
    permission: ORG_PERMISSIONS.manageFinances
  });

  return (
    <View style={{ flex: 1 }}>
      <View style={{ flexDirection: "row" }}>
        <h1 className="text-2xl sm:text-4xl flex-1 mt-4">{translate.common.Coupons}</h1>
        {compute.org.hasOrgPermission({
          accountId: getCurrentUserAccountId(),
          org: p.org,
          permission: ORG_PERMISSIONS.manageFinances
        }) ? (
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              openOrgCouponsAddEditModal({
                org: p.org,
                type: "create"
              });
            }}
          >
            <SvgIcon style={{ paddingRight: 6 }}>
              <PlusCircleIcon />
            </SvgIcon>
            {translate({ defaultMessage: "New Coupon" })}
          </Button>
        ) : null}
      </View>
      <div className="pb-8">
        <CoolerTable
          condensed={isMediumDeviceOrSmaller}
          style={{ marginTop: 30 }}
          items={p.orgCoupons}
          paginationOptions={{
            defaultPageSize: 20,
            pageSizeOptions: [20, 50, 100]
          }}
          noItemsMessage={translate({ defaultMessage: "No coupons created yet..." })}
          noFilteredItemsMessage={translate({ defaultMessage: "No coupons matching selected filters..." })}
          getRowCustomClassName={item => {
            if (isOrgCouponExpired(item) || isOrgCouponOutOfUses(item)) {
              return "bg-red-50";
            }
            return "";
          }}
          rowButtons={
            compute.org.hasOrgPermission({
              accountId: getCurrentUserAccountId(),
              org: p.org,
              permission: ORG_PERMISSIONS.manageFinances
            })
              ? [
                  //Edit
                  {
                    getLabel: () => translate.common.Edit,
                    type: "dropdown",
                    onClick: async item => {
                      openOrgCouponsAddEditModal({
                        org: p.org,
                        type: "edit",
                        initialOrgCoupon: item
                      });
                    }
                  },
                  //Clone
                  {
                    getLabel: () => translate({ defaultMessage: "Duplicate" }),
                    type: "dropdown",
                    onClick: async item => {
                      openOrgCouponsAddEditModal({
                        org: p.org,
                        type: "create",
                        initialOrgCoupon: { ...item, code: item.code + "COPY", playerBundleIds: undefined }
                      });
                    }
                  },
                  //Delete
                  {
                    getLabel: () => translate.common.Delete,
                    type: "dropdown",
                    async onClick(item) {
                      const confirm = await getConfirm({
                        confirmButtonColor: "red",
                        subtitle: translate({ defaultMessage: "Are you sure you want to delete this coupon?" }),
                        title: translate({ defaultMessage: "Delete Coupon?" })
                      });
                      if (confirm) {
                        try {
                          await getBifrost().orgCoupon__client__deleteOrgCoupon.fetchClient({ orgCouponId: item.id });
                        } catch (e) {}
                      }
                    },
                    getTheme(item) {
                      return "red";
                    }
                  }
                ]
              : []
          }
          columnDefs={[
            {
              label: translate.common.Code,
              editable: hasOrgManageFinancesPermission
                ? {
                    onComplete: newItem =>
                      getBifrost()
                        .orgCoupon__client__updateOrgCoupon.fetchClient({ orgCoupon: newItem, locale: getCurrentLocale() })
                        .then(resp => {
                          if (resp.data.type !== "success") {
                            openErrorToast(
                              [translate({ defaultMessage: "Unable to save changes to coupon code." }), resp.data.message].join(
                                " "
                              )
                            );
                          }
                        }),
                    onError: e => {
                      openErrorToast(translate({ defaultMessage: "Unable to save changes to coupon code." }));
                      console.error(e);
                    },
                    render: a => (
                      <PrettyCoolTextInput
                        onChange={newCode => {
                          a.setItem(s => {
                            s.code = newCode ? newCode.replace(/[^a-zA-Z0-9]/g, "").toUpperCase() : "";
                          });
                        }}
                        inputProps={{ type: "text" }}
                        value={a.item.code}
                      />
                    )
                  }
                : undefined,
              getValue(item) {
                return item.code;
              },
              sortItems(items, dir) {
                return _.orderBy(items, a => a.code, dir);
              }
            },
            {
              label: translate.common.UseType,
              getValue(item) {
                return {
                  [OrgCouponUseType.registration]: translate.common.Registration,
                  [OrgCouponUseType.invoices]: translate.common.Invoices,
                  [OrgCouponUseType.tryouts]: translate.common.Tryouts,
                  [OrgCouponUseType.all]: translate.common.All
                }[item.useType];
              },
              sortItems(items, dir) {
                return _.orderBy(items, a => a.useType, dir);
              },
              editable: hasOrgManageFinancesPermission
                ? {
                    onComplete: newItem =>
                      getBifrost()
                        .orgCoupon__client__updateOrgCoupon.fetchClient({ orgCoupon: newItem, locale: getCurrentLocale() })
                        .then(resp => {
                          if (resp.data.type !== "success") {
                            openErrorToast(
                              [translate({ defaultMessage: "Unable to save changes to coupon type." }), resp.data.message].join(
                                " "
                              )
                            );
                          }
                        }),
                    onError: e => {
                      openErrorToast(translate({ defaultMessage: "Unable to save changes to coupon type." }));
                      console.error(e);
                    },
                    render: a => (
                      <PrettyCoolSelectInput
                        placeholder={translate.common.SelectDotDotDot}
                        value={a.item.useType ?? ""}
                        isRequired
                        options={[
                          {
                            label: translate.common.Registration,
                            value: OrgCouponUseType.registration
                          },
                          {
                            label: translate.common.Tryouts,
                            value: OrgCouponUseType.tryouts
                          },
                          {
                            label: translate.common.All,
                            value: OrgCouponUseType.all
                          }
                        ]}
                        onChange={newVal => {
                          a.setItem({ useType: newVal as OrgCouponUseType });
                        }}
                      />
                    )
                  }
                : undefined
            },
            {
              label: translate.common.DiscountType,
              getValue(item) {
                return item.type === OrgCouponType.amountOff ? translate.common.Amount : translate.common.Percent;
              },
              sortItems(items, dir) {
                return _.orderBy(items, a => a.type, dir);
              }
            },
            {
              label: translate.common.Discount,
              getValue(item) {
                return item.type === OrgCouponType.amountOff
                  ? formatMoneyCentsToDollarCentPrettyString(item.amountCents).replace(/\.00$/, "")
                  : (item.percent * 100).toFixed(2) + "%";
              },
              sortItems: (items, dir) => {
                return _.orderBy(items, a => (a.type === OrgCouponType.amountOff ? a.amountCents : a.percent), dir);
              },
              editable: hasOrgManageFinancesPermission
                ? {
                    onComplete: newItem =>
                      getBifrost()
                        .orgCoupon__client__updateOrgCoupon.fetchClient({ orgCoupon: newItem, locale: getCurrentLocale() })
                        .then(resp => {
                          if (resp.data.type !== "success") {
                            openErrorToast(
                              [translate({ defaultMessage: "Unable to save changes to coupon amount." }), resp.data.message].join(
                                " "
                              )
                            );
                          }
                        }),
                    onError: e => {
                      openErrorToast(translate({ defaultMessage: "Unable to save changes to coupon amount." }));
                      console.error(e);
                    },
                    getInitialTempStateOnEditStart: currItem =>
                      currItem.type === OrgCouponType.amountOff
                        ? (currItem.amountCents / 100).toFixed(2)
                        : currItem.percent * 100,
                    render: a => (
                      <PrettyCoolTextInput
                        isMoney={a.item.type === OrgCouponType.amountOff}
                        isPercent={a.item.type === OrgCouponType.percentOff}
                        onChange={newAmtRaw => {
                          const newAmt = newAmtRaw?.match(/\d+\.?\d?\d?/)?.[0];
                          if (!newAmt) {
                            a.setTempState("");
                            a.setItem(s => {
                              if ("amountCents" in s) {
                                s.amountCents = 0;
                              }
                              if ("percent" in s) {
                                s.percent = 0;
                              }
                            });
                          } else {
                            if (a.item.type === OrgCouponType.amountOff && parseFloat(newAmt) < 0) {
                              return;
                            }

                            if (
                              a.item.type === OrgCouponType.percentOff &&
                              (parseFloat(newAmt) < 0 || parseFloat(newAmt) > 100)
                            ) {
                              return;
                            }

                            a.setTempState(newAmt);
                            a.setItem(s => {
                              if ("amountCents" in s) {
                                s.amountCents = Math.floor(parseFloat(newAmt) * 100);
                              }
                              if ("percent" in s) {
                                s.percent = parseFloat(newAmt) / 100;
                              }
                            });
                          }
                        }}
                        inputProps={{ type: "number" }}
                        value={a.tempState}
                      />
                    )
                  }
                : undefined
            },
            {
              label: translate({ defaultMessage: "Uses Remaining" }),
              getValue(item) {
                return item.useLimit ? item.useLimit - (item.numberTimesUsed || 0) : "∞";
              },
              sortItems(items, dir) {
                return _.orderBy(
                  items,
                  a => {
                    return a.useLimit;
                  },
                  dir
                );
              }
            },
            {
              label: translate.common.Expiration,
              getValue(item) {
                return item.expirationDateMS ? moment(item.expirationDateMS).format("MM/DD/YYYY") : "";
              },
              sortItems(items, dir) {
                return _.orderBy(
                  items,
                  a => {
                    return dir === "asc" ? a.expirationDateMS ?? Number.MAX_VALUE : a.expirationDateMS ?? Number.MIN_VALUE;
                  },
                  dir
                );
              },
              editable: hasOrgManageFinancesPermission
                ? {
                    onComplete: newItem =>
                      getBifrost()
                        .orgCoupon__client__updateOrgCoupon.fetchClient({ orgCoupon: newItem, locale: getCurrentLocale() })
                        .then(resp => {
                          if (resp.data.type !== "success") {
                            openErrorToast(
                              [
                                translate({ defaultMessage: "Unable to save changes to coupon expiration" }),
                                resp.data.message
                              ].join(" ")
                            );
                          }
                        }),
                    onError: e => {
                      openErrorToast(translate({ defaultMessage: "Unable to save changes to coupon expiration" }));
                      console.error(e);
                    },
                    render: a => (
                      <PrettyCoolDateInput
                        isClearable
                        value={a.item.expirationDateMS ? new Date(a.item.expirationDateMS) : undefined}
                        onChange={newVal => {
                          if (newVal) {
                            a.setItem({ expirationDateMS: moment(newVal).valueOf() });
                          } else {
                            a.setItem({ expirationDateMS: undefined });
                          }
                        }}
                      />
                    )
                  }
                : undefined
            },
            {
              label: translate({ defaultMessage: "Assigned Players" }),
              getValue(item) {
                const prettyPlayerBundles = item.playerBundleIds
                  ? playerBundles?.filter(pl => !!item.playerBundleIds?.[pl.playerBundle.id])
                  : undefined;

                return (
                  <div>
                    {prettyPlayerBundles && prettyPlayerBundles.length > 4 ? (
                      <Tag
                        text={translate(
                          {
                            defaultMessage: "{numPlayers} {numPlayers, plural, one {player} other {players}}"
                          },
                          { numPlayers: prettyPlayerBundles.length }
                        )}
                      />
                    ) : (
                      prettyPlayerBundles
                        ?.map(ppb => {
                          return `${ppb.derived.accountInfo.firstName} ${ppb.derived.accountInfo.lastName}`;
                        })
                        .map((a, i) => <Tag text={a} key={i} />)
                    )}
                  </div>
                );
              }
            }
          ]}
          getItemKey={item => {
            return item.id;
          }}
          filters={[
            {
              filterComponent: (
                <CoolTextInput
                  value={searchInput}
                  onChange={newVal => {
                    setSearchInput(newVal ?? "");
                  }}
                  showClearButton
                  inputProps={{
                    placeholder: translate({
                      defaultMessage: "Search by Code",
                      description: "Telling the user to search a list by the code"
                    })
                  }}
                />
              ),
              onFilter: items => {
                return searchInput
                  ? items.filter(item =>
                      item.code.toLowerCase().replaceAll(" ", "").includes(searchInput.toLowerCase().replaceAll(" ", ""))
                    )
                  : items;
              }
            },
            {
              filterComponent: (
                <CoolSelectInput
                  options={[
                    {
                      label: translate.common.Registration,
                      value: OrgCouponUseType.registration
                    },
                    {
                      label: translate.common.Tryouts,
                      value: OrgCouponUseType.tryouts
                    },
                    {
                      label: translate.common.All,
                      value: OrgCouponUseType.all
                    }
                  ]}
                  value={selectedOrgCouponUseType ?? ""}
                  allowClear
                  placeholder={translate.common.UseType}
                  onChange={newVal => {
                    setSelectedOrgCouponUseType(newVal === "" ? undefined : (newVal as OrgCouponUseType));
                  }}
                />
              ),
              onFilter: items => {
                return selectedOrgCouponUseType ? items.filter(item => item.useType === selectedOrgCouponUseType) : items;
              }
            },
            {
              filterComponent: (
                <CoolSelectInput
                  options={[
                    {
                      label: translate.common.Amount,
                      value: OrgCouponType.amountOff
                    },
                    {
                      label: translate.common.Percent,
                      value: OrgCouponType.percentOff
                    }
                  ]}
                  value={selectedOrgCouponType ?? ""}
                  placeholder={translate.common.DiscountType}
                  allowClear
                  onChange={newVal => {
                    setSelectedOrgCouponType(newVal === "" ? undefined : (newVal as OrgCouponType));
                  }}
                />
              ),
              onFilter: items => {
                return selectedOrgCouponType ? items.filter(item => item.type === selectedOrgCouponType) : items;
              }
            },
            {
              filterComponent: (
                <CoolCheckboxInput
                  label={translate({
                    defaultMessage: "Unexpired Only",
                    description: "Option to only show active coupons"
                  })}
                  labelType="inside"
                  value={showActiveOnly}
                  onChange={newVal => {
                    setShowActiveOnly(newVal);
                  }}
                />
              ),
              onFilter(items) {
                if (showActiveOnly) {
                  return items.filter(item => !isOrgCouponExpired(item) && !isOrgCouponOutOfUses(item));
                }
                return items;
              }
            },
            {
              filterComponent: (
                <div
                  onClick={() => {
                    setSelectedOrgCouponType(undefined);
                    setSelectedOrgCouponUseType(undefined);
                    setSearchInput("");
                    setShowActiveOnly(true);
                  }}
                  className="flex flex-row items-center cursor-pointer pt-2"
                >
                  <div className="h-5 w-5 mr-2">
                    <ArrowPathIcon className="text-blue-500" />
                  </div>
                  <div className="text-blue-500">{translate.common.ResetFilters}</div>
                </div>
              ),
              onFilter(items) {
                return items;
              }
            }
          ]}
        />
      </div>
    </View>
  );
}
