import { useTheme, useMediaQuery } from "@material-ui/core";
import {
  NONE,
  COLORS,
  formatMoneyCentsToDollarCentPrettyString,
  ObjectKeys,
  compute,
  calculateFeesForPayment,
  ensureColorHasHashtag,
  calculateDiscountFromOrgCoupon,
  roundNumberToTwoDecimalPlaces,
  canUseCouponInfo,
  linkify
} from "@ollie-sports/core";
import { translate, dateFormatters, getCurrentLocale } from "@ollie-sports/i18n";
import {
  Org,
  OrgInvoice__Manual,
  OrgInvoice__Registration,
  PlayerBundle,
  OrgSettings,
  OrgInvoiceTypes,
  OrgPaymentPlanType,
  OrgPaymentPlan,
  OrgCoupon,
  PaymentPlanSinglePaymentInfo,
  OrgPaymentInvoiceCredit,
  OrgCouponId,
  OrgPaymentPlanId,
  OrgSeason,
  OrgRegistrationPackage,
  OrgCouponType,
  OrgCouponUseType,
  PaymentMethodType
} from "@ollie-sports/models";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { View, TouchableOpacity } from "react-native-web";
import CoolerTable from "../../components/CoolerTable";
import { CoolSelectInput } from "../../components/Inputs/CoolSelectInput";
import { ShadowView } from "../../components/ShadowView";
import { StyledText } from "../../components/StyledText";
import { SectionHeader } from "../openOrgEvents/OpenOrgEventRegistrationPage";
import { getBifrost } from "../../services/bifrost.service";
import { CoolTextInputWithLabel } from "../../components/Inputs/CoolTextInput";
import { openErrorToast, openSuccessToast } from "../../utils/openErrorToast";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { PrettyCheckbox, PrettyCoolDateInput, PrettyCoolSelectInput, PrettyCoolTextInputWithLabel } from "../../components/Form";
import { Expando } from "../../components/Expando";
import getFullScreenModal from "../../components/modals/getFullscreenModal";
import { getCurrentUserAccountId } from "../../hooks/commonDataUtils";
import { StyledButton } from "../../components/StyledButton";
import clsx from "clsx";
import { useGlobalState } from "../../services/global-state.service";
import LoginView from "../auth/LoginView";
import { PaymentMethodForm } from "../payment/PaymentMethodForm";

const ENTER_CARD_DETAILS = "ADD_NEW_CARD";

export function OrgInvoicePay(
  p: (
    | { type: "manual"; orgInvoice: OrgInvoice__Manual }
    | { type: "registration"; orgSeason: OrgSeason; orgInvoice: Omit<OrgInvoice__Registration, "id" | "invoiceGroupId"> }
  ) & {
    org: Org;
    playerBundle: PlayerBundle;
    orgSettings: OrgSettings;
    orgPaymentInvoiceCredits: OrgPaymentInvoiceCredit[];
    error?: { msg: string; onClear: () => void };
    onComplete: (data: {
      todayPaymentDetails: {
        baseAmountDueCents: number;
        lateFeeAmountDueCents?: number;
        otherFeesAmountDueCents: number;
        appliedOrgCouponDetails?: {
          orgCouponId: OrgCouponId;
          discountAmountCents: number;
        };
      };
      orgPaymentPlanDetails?: {
        orgPaymentPlanId: OrgPaymentPlanId;
        futurePaymentsToSchedule: PaymentPlanSinglePaymentInfo[];
      };
      nonDefaultPaymentMethodIdToUse?: string;
    }) => Promise<void>;
    visibilityOptions?: {
      isVisible: boolean;
      setIsVisible: (isVisible: boolean) => void;
    };
    isEverythingElseValid: boolean;
    isPreview?: boolean;
    previewOverrideOrgCoupon?: OrgCoupon;
    previewOverridePaymentMethodType?: PaymentMethodType;
    previewOverrideOrgPaymentPlan?: OrgPaymentPlan;
    previewOverridePaymentDateMS?: number;
    previewOverrideRegistrationPackage?: Pick<
      OrgRegistrationPackage,
      | "referenceImageUrl"
      | "referenceImageTitle"
      | "supportedPaymentPlanIds"
      | "details"
      | "hideLineItemsAtRegistration"
      | "lineItems"
    >;
  }
) {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const isLoggedIn = useGlobalState().auth.isLoggedIn;
  const [selectedPaymentPlanId, setSelectedPaymentPlanId] = useState<string>(p.previewOverrideOrgPaymentPlan?.id ?? NONE);
  const { data: orgPaymentPlansFetched } = getBifrost().orgPaymentPlan__server__getOrgPaymentPlansForOrgNoAuth.useServer({
    orgId: p.org.id
  });
  const [addPaymentMethodErrorMsg, setAddPaymentMethodErrorMsg] = useState("");
  const orgPaymentPlans = _.compact([...(orgPaymentPlansFetched ?? []), p.previewOverrideOrgPaymentPlan]);
  const selectedOrgPaymentPlan =
    p.previewOverrideOrgPaymentPlan ??
    (selectedPaymentPlanId ? orgPaymentPlans?.find(opp => opp.id === selectedPaymentPlanId) : undefined);
  const [selectedNumberOfPayments, setSelectedNumberOfPayments] = useState<number | undefined>(
    p.previewOverrideOrgPaymentPlan && p.previewOverrideOrgPaymentPlan.type === OrgPaymentPlanType.monthly
      ? p.previewOverrideOrgPaymentPlan.numberPeriods
      : undefined
  );
  const orgRegistrationPackageId =
    p.orgInvoice.type === OrgInvoiceTypes.registration
      ? (p.orgInvoice as Omit<OrgInvoice__Registration, "id" | "invoiceGroupId">).orgRegistrationPackageId
      : undefined;
  const { data: orgRegistrationPackageFetched } =
    getBifrost().orgRegistrationPackage__client__getOrgRegistrationPackage.useClient(
      { id: orgRegistrationPackageId ?? "" },
      { enabled: !!orgRegistrationPackageId }
    );
  const orgRegistrationPackage = p.previewOverrideRegistrationPackage ?? orgRegistrationPackageFetched;
  const [selectedCoupon, setSelectedCoupon] = useState<OrgCoupon | undefined>(p.previewOverrideOrgCoupon);
  const [isProcessing, setIsProcessing] = useState(false);
  const [extraAmountUserWantsToPayCentsShadow, setExtraAmountUserWantsToPayCentsShadow] = useState("");
  const [extraAmountUserWantsToPayCents, setExtraAmountUserWantsToPayCents] = useState(0);

  const [hasAgreedToPayScheduledCharges, setHasAgreedToPayScheduledCharges] = useState(false);
  const { data: userPaymentMethods, forceRefresh: refreshUserPaymentMethods } =
    getBifrost().accountSecret__server__getPaymentMethods.useServer(
      { selfAccountId: isLoggedIn ? getCurrentUserAccountId() : "" },
      { enabled: isLoggedIn }
    );

  const allowedPaymentPlanIdss = p.previewOverrideOrgPaymentPlan
    ? [p.previewOverrideOrgPaymentPlan.id]
    : ObjectKeys(
        p.orgInvoice.type === OrgInvoiceTypes.manual
          ? (p.orgInvoice as Omit<OrgInvoice__Manual, "id">).allowedPaymentPlanIds ?? {}
          : orgRegistrationPackage
          ? orgRegistrationPackage.supportedPaymentPlanIds ?? {}
          : {}
      );

  const creditAmountCents = _.sum(p.orgPaymentInvoiceCredits.map(c => c.amountCents));

  const discountAmountCents = selectedCoupon
    ? roundNumberToTwoDecimalPlaces(
        calculateDiscountFromOrgCoupon({ orgCoupon: selectedCoupon, originalAmountCents: p.orgInvoice.amountDueCents })
      )
    : 0;
  const invoiceAmountAfterDiscount = roundNumberToTwoDecimalPlaces(p.orgInvoice.amountDueCents - discountAmountCents);
  const invoiceAmountAfterCreditsApplied = roundNumberToTwoDecimalPlaces(
    Math.max(invoiceAmountAfterDiscount - creditAmountCents, 0)
  );
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState("");
  const selectedPaymentMethod = userPaymentMethods?.find(pm => pm.id === selectedPaymentMethodId);
  const selectedPaymentMethodToUseWithPreviewOverride = p.previewOverridePaymentMethodType ?? selectedPaymentMethod?.type;

  const allOrgPaymentDetails =
    p.type === "manual"
      ? compute.orgPaymentPlan.getAllPaymentDetailsForOrgPaymentPlan({
          invoiceAmountCents: invoiceAmountAfterCreditsApplied,
          invoiceDueDateMS: p.orgInvoice.dueDateMS,
          orgPaymentPlan: selectedOrgPaymentPlan,
          numPaymentsOverrideForFlexibleDateOrgPaymentPlan: selectedNumberOfPayments,
          feeDetails:
            selectedPaymentMethodToUseWithPreviewOverride === PaymentMethodType.bank
              ? p.orgSettings.customFeeDetails?.bank
              : p.orgSettings.customFeeDetails?.card,
          type: "manual",
          previewOverridePaymentDateMS: p.previewOverridePaymentDateMS,
          extraAmountUserWantsToPayCents,
          paymentMethodType: selectedPaymentMethodToUseWithPreviewOverride
        })
      : compute.orgPaymentPlan.getAllPaymentDetailsForOrgPaymentPlan({
          invoiceAmountCents: invoiceAmountAfterCreditsApplied,
          invoiceDueDateMS: p.orgInvoice.dueDateMS,
          orgPaymentPlan: selectedOrgPaymentPlan,
          numPaymentsOverrideForFlexibleDateOrgPaymentPlan: selectedNumberOfPayments,
          feeDetails:
            selectedPaymentMethodToUseWithPreviewOverride === PaymentMethodType.bank
              ? p.orgSettings.customFeeDetails?.bank
              : p.orgSettings.customFeeDetails?.card,
          type: "registration",
          orgSeason: p.orgSeason,
          previewOverridePaymentDateMS: p.previewOverridePaymentDateMS,
          extraAmountUserWantsToPayCents,
          paymentMethodType: selectedPaymentMethodToUseWithPreviewOverride
        });

  const allOrgPayments = allOrgPaymentDetails.paymentInfo;
  const checkoutDate = p.previewOverridePaymentDateMS ?? Date.now();

  const paymentsDueToday = allOrgPayments.filter(details => {
    return isPaymentDueToday(checkoutDate, details.originalPaymentDateMS);
  });

  const futurePaymentsToSchedule = allOrgPayments.filter(
    op => !paymentsDueToday.find(a => a.originalPaymentDateMS === op.originalPaymentDateMS)
  );

  const isLate = p.orgInvoice.dueDateMS < checkoutDate;

  const downPaymentAmountCents = roundNumberToTwoDecimalPlaces(allOrgPaymentDetails.downPaymentAmountCents);
  const paymentPlanSetupFeeAmountCents = roundNumberToTwoDecimalPlaces(allOrgPaymentDetails.paymentPlanSetupFeeAmountCents);
  const sumOfInstallmentsDueToday = roundNumberToTwoDecimalPlaces(_.sum(paymentsDueToday.map(details => details.amountCents)));

  const maximumExtraAmountAllowed = invoiceAmountAfterCreditsApplied - downPaymentAmountCents;

  const subtotalBeforeLateFeeAmountCents = roundNumberToTwoDecimalPlaces(
    downPaymentAmountCents + sumOfInstallmentsDueToday + paymentPlanSetupFeeAmountCents + extraAmountUserWantsToPayCents
  );

  const lateFeeAmountCents = isLate ? p.orgInvoice.lateFeeCentsToBeIssuedIfLate : 0;
  const subTotal = roundNumberToTwoDecimalPlaces(subtotalBeforeLateFeeAmountCents + lateFeeAmountCents);

  const otherFeesAmountCents = calculateFeesForPayment({
    chargeAmountCents: subTotal,
    feeDetails:
      selectedPaymentMethodToUseWithPreviewOverride === PaymentMethodType.bank
        ? p.orgSettings.customFeeDetails?.bank
        : p.orgSettings.customFeeDetails?.card,
    paymentMethodType: selectedPaymentMethodToUseWithPreviewOverride ?? PaymentMethodType.card
  });
  const totalAmountDue = roundNumberToTwoDecimalPlaces(subTotal + otherFeesAmountCents);

  const [paymentDetails, setPaymentDetails] = useState<PaymentPlanSinglePaymentInfo[]>(allOrgPayments);

  const allOrgPaymentsHash = allOrgPayments.map(fp => fp.amountCents + fp.originalPaymentDateMS).join("");

  const accountId = isLoggedIn ? getCurrentUserAccountId() : undefined;
  const doesAccountManagePlayerBundle = accountId ? !!p.playerBundle.managingAccounts?.[accountId] : false;

  useEffect(() => {
    if (userPaymentMethods && userPaymentMethods.length) {
      setSelectedPaymentMethodId(a => {
        if (a) {
          return a;
        }

        if (userPaymentMethods) {
          const defaultUserPaymentMethod = userPaymentMethods.find(pm => pm.isDefault);
          if (defaultUserPaymentMethod && !!p.orgSettings.customFeeDetails?.[defaultUserPaymentMethod.type]) {
            return defaultUserPaymentMethod.id;
          } else {
            return "";
          }
        } else {
          return ENTER_CARD_DETAILS;
        }
      });
    }
  }, [!!userPaymentMethods]);

  const orgPrimaryColor = p.orgSettings.primaryColor ? ensureColorHasHashtag(p.orgSettings.primaryColor) : COLORS.blue;

  useEffect(() => {
    setPaymentDetails(allOrgPayments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOrgPaymentsHash]);

  const isExpanded = !p.visibilityOptions || p.visibilityOptions.isVisible;

  const finalCentsDueToday = subtotalBeforeLateFeeAmountCents + lateFeeAmountCents + otherFeesAmountCents;
  const hasValidPaymentMethod = selectedPaymentMethodId && selectedPaymentMethodId !== ENTER_CARD_DETAILS;
  const hasAgreedToPayScheduledChargesOrIsPayingInFull =
    hasAgreedToPayScheduledCharges || !selectedOrgPaymentPlan || !invoiceAmountAfterCreditsApplied;
  const isButtonDisabled =
    isProcessing ||
    !p.isEverythingElseValid ||
    !!(invoiceAmountAfterCreditsApplied && !hasValidPaymentMethod) ||
    !hasAgreedToPayScheduledChargesOrIsPayingInFull;

  const paymentPlanOptionss = [
    {
      label: translate.common.PayInFull,
      value: NONE
    },
    ..._.compact(
      allowedPaymentPlanIdss.map(paymentPlanId => {
        const orgPaymentPlan = orgPaymentPlans?.find(opp => opp.id === paymentPlanId);
        if (orgPaymentPlan) {
          return {
            label: orgPaymentPlan.name,
            value: paymentPlanId,
            disabled: !doesAccountManagePlayerBundle && !p.isPreview
          } as any;
        }
        return null;
      })
    )
  ];

  const activePaymentPlanOptions = paymentPlanOptionss.filter(a => !a.disabled);

  return (
    <div>
      <ShadowView
        style={{
          marginTop: p.isPreview ? 0 : 30,
          borderRadius: 20,
          backgroundColor: COLORS.white,
          width: mobileDevice || p.isPreview ? "100%" : 650
        }}
      >
        <SectionHeader
          isMobileDevice={mobileDevice}
          title={translate({ defaultMessage: "Payment" })}
          org={p.org}
          orgSettings={p.orgSettings}
          status="incomplete"
          isSectionExpanded={isExpanded}
          onPress={
            p.visibilityOptions
              ? () => {
                  p.visibilityOptions?.setIsVisible(!p.visibilityOptions.isVisible);
                }
              : undefined
          }
        />
        <Expando durationMS={500} isExpanded={isExpanded} contentClassName="overflow-hidden">
          <div className="flex flex-col" style={{ padding: mobileDevice ? 15 : 30, flex: 1, overflow: "hidden" }}>
            <StyledText style={{ fontWeight: "bold" }}>
              {`${p.playerBundle.virtualAthleteAccount.firstName} ${p.playerBundle.virtualAthleteAccount.lastName}`}
            </StyledText>
            <StyledText style={{ marginTop: 8 }}>
              {`${translate.common.Amount}: ${formatMoneyCentsToDollarCentPrettyString(p.orgInvoice.amountDueCents).replace(
                /\.00$/,
                ""
              )}`}
            </StyledText>
            {p.orgInvoice.lateFeeCentsToBeIssuedIfLate && isLate ? (
              <StyledText style={{ marginTop: 8 }}>
                {`${translate({ defaultMessage: "Late Fee Due" })}: ${formatMoneyCentsToDollarCentPrettyString(
                  p.orgInvoice.lateFeeCentsToBeIssuedIfLate
                ).replace(/\.00$/, "")}`}
              </StyledText>
            ) : null}
            <StyledText style={{ marginTop: 8 }}>
              {`${translate.common.DueDate}: ${dateFormatters.mm_dd_yyyy(
                moment(p.orgInvoice.dueDateMS).toDate(),
                getCurrentLocale()
              )}`}
            </StyledText>
            <StyledText style={{ marginTop: 8 }}>{`${translate.common.Memo}: ${p.orgInvoice.memo}`}</StyledText>
            {orgRegistrationPackage?.referenceImageUrl ? (
              <div
                className="flex flex-col"
                style={{ borderTopWidth: 2, marginTop: 16, borderTopColor: COLORS.grey_07, paddingTop: 16 }}
              >
                {orgRegistrationPackage.referenceImageTitle ? (
                  <StyledText style={{ fontWeight: "bold", marginBottom: 8 }}>
                    {orgRegistrationPackage.referenceImageTitle}
                  </StyledText>
                ) : null}
                <TouchableOpacity
                  onPress={() => {
                    getFullScreenModal({
                      title: orgRegistrationPackage?.referenceImageTitle ?? translate({ defaultMessage: "Reference Image" }),
                      children: <img src={orgRegistrationPackage.referenceImageUrl} className="w-auto h-auto" alt="" />
                    });
                  }}
                >
                  <img
                    src={orgRegistrationPackage.referenceImageUrl}
                    className="w-full object-scale-down"
                    style={{ maxHeight: "400px" }}
                    alt=""
                  />
                </TouchableOpacity>
              </div>
            ) : null}
            {orgRegistrationPackage?.lineItems && !orgRegistrationPackage.hideLineItemsAtRegistration ? (
              <LineItemsSection lineItems={orgRegistrationPackage.lineItems} />
            ) : null}

            {orgRegistrationPackage?.details ? (
              <div className="mt-4">
                <StyledText style={{ fontWeight: "bold" }}>{translate.common.Details}</StyledText>
                <div className="mt-4" dangerouslySetInnerHTML={{ __html: orgRegistrationPackage.details }} />
              </div>
            ) : null}

            {p.orgInvoice.thisInvoicePaidInFullDateMS ? null : !isLoggedIn ? (
              <div className="mt-8">
                <div className="pb-2">{translate({ defaultMessage: "Please login to continue" })}</div>
                <div
                  style={{
                    borderRadius: 4,
                    border: "1px solid lightgrey",
                    paddingBottom: 12
                  }}
                >
                  <LoginView className="scale-90" />
                </div>
              </div>
            ) : (
              <div className="flex flex-col">
                <CouponSection
                  orgInvoice={p.orgInvoice}
                  playerBundle={p.playerBundle}
                  selectedCoupon={selectedCoupon}
                  onSelectCoupon={newCoupon => {
                    setSelectedCoupon(newCoupon);
                  }}
                  onDeselectCoupon={() => {
                    setSelectedCoupon(undefined);
                  }}
                  disabled={!!p.previewOverrideOrgCoupon}
                  credits={p.orgPaymentInvoiceCredits}
                />
                {p.isPreview || activePaymentPlanOptions.length > 1 ? (
                  <div className="flex flex-col" style={{ marginTop: 8, paddingTop: 8 }}>
                    <CoolSelectInput
                      label={translate({ defaultMessage: "Payment Plan" })}
                      placeholder={translate({ defaultMessage: "Select payment plan..." })}
                      onChange={newVal => {
                        try {
                          setSelectedPaymentPlanId(newVal);

                          if (newVal !== selectedPaymentPlanId) {
                            const newSelectedPaymentPlan = orgPaymentPlans?.find(opp => opp.id === newVal);
                            if (newSelectedPaymentPlan && newSelectedPaymentPlan.type === OrgPaymentPlanType.monthly) {
                              setSelectedNumberOfPayments(newSelectedPaymentPlan.numberPeriods);
                            }
                          }
                        } catch (e) {}
                      }}
                      value={selectedPaymentPlanId}
                      options={paymentPlanOptionss}
                    />
                    {selectedOrgPaymentPlan?.type === OrgPaymentPlanType.monthly ? (
                      <CoolSelectInput
                        containerStyle={{ marginTop: 16 }}
                        onChange={newVal => {
                          if (!newVal) {
                            setSelectedNumberOfPayments(undefined);
                          } else {
                            try {
                              const numVal = parseInt(newVal);
                              if (numVal) {
                                setSelectedNumberOfPayments(numVal);
                              }
                            } catch (e) {}
                          }
                        }}
                        options={Array.from({ length: selectedOrgPaymentPlan.numberPeriods }, (_, i) => i + 1).map(num => {
                          return {
                            label: `${num}`,
                            value: `${num}`
                          };
                        })}
                        value={selectedNumberOfPayments ? `${selectedNumberOfPayments}` : ""}
                        label={translate({ defaultMessage: "# of Months in Payment Plan" })}
                      />
                    ) : null}
                    {selectedOrgPaymentPlan ? (
                      <PrettyCoolTextInputWithLabel
                        subtitle={`${translate({ defaultMessage: "Max Amount" })}: ${formatMoneyCentsToDollarCentPrettyString(
                          maximumExtraAmountAllowed
                        )}`}
                        style={{ marginTop: 16 }}
                        onChange={newVal => {
                          const newAmt = newVal?.match(/\d+\.?\d?\d?/)?.[0];
                          if (!newAmt) {
                            setExtraAmountUserWantsToPayCentsShadow("");
                            setExtraAmountUserWantsToPayCents(0);
                            return;
                          }
                          if (parseFloat(newAmt || "0") < 0 || parseFloat(newAmt || "0") * 100 > maximumExtraAmountAllowed) {
                            return;
                          }
                          setExtraAmountUserWantsToPayCentsShadow(newAmt || "");
                          setExtraAmountUserWantsToPayCents(newAmt ? Number(newAmt) * 100 : 0);
                        }}
                        value={extraAmountUserWantsToPayCentsShadow}
                        label={translate({ defaultMessage: "Do you want to pay extra today to lower future payments?" })}
                      />
                    ) : null}
                  </div>
                ) : null}

                {!p.isPreview ? (
                  <View>
                    <PrettyCoolSelectInput
                      onChange={async val => {
                        setSelectedPaymentMethodId(val);
                      }}
                      blurShouldTriggerValidation={false}
                      validate={val => {
                        if (invoiceAmountAfterCreditsApplied > 0) {
                          if (!val || val === ENTER_CARD_DETAILS) {
                            return translate({ defaultMessage: "Must add a payment method" });
                          }
                        }
                        return "";
                      }}
                      containerStyle={{ marginTop: 16 }}
                      value={selectedPaymentMethodId}
                      options={[
                        ...(userPaymentMethods || []).map(a => {
                          const disabled = !p.orgSettings.customFeeDetails?.[a.type];
                          return {
                            value: a.id,
                            disabled,
                            label:
                              a.type === "card"
                                ? `${translate.common.CreditDebitCardShort} - ${a.cardNumberLast4} (${a.expMM}/${a.expYYYY.slice(
                                    -2
                                  )})${
                                    disabled
                                      ? ` - ${translate({ defaultMessage: "Card payments not enabled for this club" })}`
                                      : ""
                                  }`
                                : `${translate.common.BankAccount} - ${a.accountNumberLast4}${
                                    disabled
                                      ? ` - ${translate({ defaultMessage: "Bank payments not enabled for this club" })}`
                                      : ""
                                  }`
                          };
                        }),
                        {
                          value: ENTER_CARD_DETAILS,
                          disabled: false,
                          label: translate({ defaultMessage: "Add New Payment Method" })
                        }
                      ]}
                      placeholder={translate({ defaultMessage: "Select payment method..." })}
                      label={translate({ defaultMessage: "Payment Method" })}
                    />

                    {selectedPaymentMethodId === ENTER_CARD_DETAILS ? (
                      <PaymentMethodForm
                        style={{ marginTop: 16 }}
                        accountId={accountId ?? ""}
                        errorMsg={addPaymentMethodErrorMsg}
                        hasOtherPaymentMethods={!!userPaymentMethods?.length}
                        onSubmitPaymentMethod={async paymentMethod => {
                          try {
                            const { data: result } = await getBifrost().accountSecret__server__addPaymentMethod.fetchServer({
                              paymentMethod,
                              selfAccountId: accountId ?? "",
                              locale: getCurrentLocale()
                            });
                            if (result.status === "error") {
                              setAddPaymentMethodErrorMsg(result.prettyErrorMsg);
                              return false;
                            }
                            await refreshUserPaymentMethods();
                            if (!!p.orgSettings.customFeeDetails?.[paymentMethod.type]) {
                              setSelectedPaymentMethodId(result.paymentMethodId);
                            }
                            openSuccessToast();
                          } catch (e) {
                            return false;
                            // Payment TODO
                          }
                          return true;
                        }}
                      />
                    ) : null}
                  </View>
                ) : null}

                {activePaymentPlanOptions.length === 1 &&
                allowedPaymentPlanIdss.length &&
                !doesAccountManagePlayerBundle &&
                !p.isPreview ? (
                  <div className="flex px-1 text-xs mt-8 text-gray-500 items-center">
                    {translate({
                      defaultMessage: "Note: Your Ollie account is not linked to this player, so you must pay in full."
                    })}
                  </div>
                ) : null}

                <PaymentSummarySection
                  hasAgreedToPayScheduledCharges={hasAgreedToPayScheduledCharges}
                  toggleHasAgreedToPayScheduledCharges={() => {
                    setHasAgreedToPayScheduledCharges(!hasAgreedToPayScheduledCharges);
                  }}
                  dueDateMS={p.orgInvoice.dueDateMS}
                  baseAmountDueToday={sumOfInstallmentsDueToday}
                  feesAmountCents={otherFeesAmountCents}
                  lateFeeAmountCents={lateFeeAmountCents}
                  subTotalAmountCents={subTotal}
                  totalAmountCents={totalAmountDue}
                  downPaymentAmountCents={downPaymentAmountCents}
                  paymentPlanSetupFeeAmountCents={paymentPlanSetupFeeAmountCents}
                  paymentPlanPayments={paymentDetails}
                  selectedOrgPaymentPlan={selectedOrgPaymentPlan}
                  previewOverridePaymentDateMS={p.previewOverridePaymentDateMS}
                  onChangePaymentDetails={
                    selectedOrgPaymentPlan?.allowUsersToOverrideChargeDateUpToLateFeeDeadline
                      ? newPaymentDetails => {
                          setPaymentDetails(newPaymentDetails);
                        }
                      : undefined
                  }
                  extraAmountUserWantsToPayCents={extraAmountUserWantsToPayCents}
                />
                {!p.isPreview ? (
                  <StyledButton
                    style={{
                      backgroundColor: orgPrimaryColor,
                      color: COLORS.white,
                      marginTop: 30,
                      width: "100%"
                    }}
                    disabled={isButtonDisabled}
                    onClick={async () => {
                      setIsProcessing(true);
                      try {
                        p.error?.onClear();
                        await p.onComplete({
                          todayPaymentDetails: {
                            baseAmountDueCents: subtotalBeforeLateFeeAmountCents,
                            lateFeeAmountDueCents: lateFeeAmountCents,
                            otherFeesAmountDueCents: otherFeesAmountCents,
                            appliedOrgCouponDetails: selectedCoupon
                              ? {
                                  orgCouponId: selectedCoupon.id,
                                  discountAmountCents
                                }
                              : undefined
                          },
                          orgPaymentPlanDetails: selectedOrgPaymentPlan
                            ? {
                                orgPaymentPlanId: selectedOrgPaymentPlan.id,
                                futurePaymentsToSchedule
                              }
                            : undefined,
                          nonDefaultPaymentMethodIdToUse: selectedPaymentMethodId
                        });
                      } catch (e) {
                        openErrorToast(
                          translate({ defaultMessage: "Something went wrong!!!!!! NEED TO FIX THIS ERROR TO BE USEFUL" })
                        );
                      }
                      setIsProcessing(false);
                    }}
                    text={
                      finalCentsDueToday
                        ? translate(
                            { defaultMessage: "Finalize & Pay {amount}" },
                            {
                              amount: formatMoneyCentsToDollarCentPrettyString(finalCentsDueToday).replace(/\.00$/, "")
                            }
                          )
                        : translate({ defaultMessage: "Submit" })
                    }
                  />
                ) : null}
                {p.error ? <div className="text-red-600 mt-4">{p.error.msg}</div> : null}
              </div>
            )}
          </div>
        </Expando>
      </ShadowView>
      <div
        className="mt-4"
        dangerouslySetInnerHTML={{
          __html: linkify(translate({ defaultMessage: "Having trouble? Contact us at support@olliesports.com" }))
        }}
      />
    </div>
  );
}

function CouponSection(p: {
  orgInvoice: Omit<OrgInvoice__Manual | OrgInvoice__Registration, "id" | "invoiceGroupId">;
  playerBundle: PlayerBundle;
  selectedCoupon?: OrgCoupon;
  onSelectCoupon: (coupon: OrgCoupon) => void;
  onDeselectCoupon: () => void;
  credits: OrgPaymentInvoiceCredit[];
  disabled?: boolean;
}) {
  const { data: availableOrgCoupons } = getBifrost().orgCoupon__server__getAvailableOrgCouponsForPlayerBundleIdNoAuth.useServer({
    playerBundleId: p.playerBundle.id
  });
  const [couponInput, setCouponInput] = useState("");
  const discountAmountCents = p.selectedCoupon
    ? calculateDiscountFromOrgCoupon({ orgCoupon: p.selectedCoupon, originalAmountCents: p.orgInvoice.amountDueCents })
    : 0;
  const invoiceAmountAfterDiscount = p.orgInvoice.amountDueCents - discountAmountCents - _.sum(p.credits.map(c => c.amountCents));

  const availableOrgCouponsWithCorrectUseType = availableOrgCoupons?.filter(oc => {
    if (!canUseCouponInfo({ locale: getCurrentLocale(), orgCoupon: oc, playerBundleId: p.playerBundle.id }).canUse) {
      return false;
    }
    if (oc.useType === OrgCouponUseType.all) {
      return true;
    } else if (oc.useType === OrgCouponUseType.registration && p.orgInvoice.type === OrgInvoiceTypes.registration) {
      return true;
    } else if (oc.useType === OrgCouponUseType.invoices && p.orgInvoice.type === OrgInvoiceTypes.manual) {
      return true;
    }
    return false;
  });

  useEffect(() => {
    if (availableOrgCouponsWithCorrectUseType?.length && availableOrgCouponsWithCorrectUseType.length === 1) {
      p.onSelectCoupon(availableOrgCouponsWithCorrectUseType[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableOrgCouponsWithCorrectUseType?.length]);

  return (
    <div className="flex flex-col">
      <Divider className="mt-4" />
      {availableOrgCouponsWithCorrectUseType?.length ? (
        <div className="flex flex-col">
          <CoolSelectInput
            containerStyle={{ marginTop: 16, flex: 1 }}
            label={translate({ defaultMessage: "Assigned Coupons" })}
            onChange={newVal => {
              if (!newVal) {
                p.onDeselectCoupon();
              }

              const newCoupon = availableOrgCouponsWithCorrectUseType.find(oc => oc.id === newVal);
              if (newCoupon) {
                p.onSelectCoupon(newCoupon);
              }
            }}
            allowClear
            options={availableOrgCouponsWithCorrectUseType.map(orgCoupon => {
              return {
                label: orgCoupon.code,
                value: orgCoupon.id
              };
            })}
            placeholder={translate.common.SelectDotDotDot}
            value={
              p.selectedCoupon && availableOrgCouponsWithCorrectUseType.find(oc => oc.id === p.selectedCoupon?.id)
                ? p.selectedCoupon.id
                : ""
            }
          />
        </div>
      ) : null}
      <div className="flex flex-col" style={{ flexDirection: "row", flex: 1, alignItems: "flex-end" }}>
        <CoolTextInputWithLabel
          style={{ marginTop: 16, flex: 1 }}
          label={
            availableOrgCoupons?.length
              ? translate({ defaultMessage: "Coupon Code Manually" })
              : translate({ defaultMessage: "Coupon Code" })
          }
          inputProps={{ placeholder: translate({ defaultMessage: "Enter Coupon Code..." }), disabled: p.disabled }}
          onChange={newVal => {
            setCouponInput(newVal ? newVal.trim().toUpperCase() : "");
          }}
          value={couponInput}
        />
        <TouchableOpacity
          style={{ marginLeft: 16, marginBottom: 12 }}
          disabled={!couponInput}
          onPress={async () => {
            const { data: orgCoupon } = await getBifrost().orgCoupon__server__getOrgCouponNoAuth.fetchServer({
              code: couponInput,
              orgId: p.orgInvoice.orgId,
              useType:
                p.orgInvoice.type === OrgInvoiceTypes.registration ? OrgCouponUseType.registration : OrgCouponUseType.invoices
            });
            if (orgCoupon) {
              const couponInfo = canUseCouponInfo({
                orgCoupon,
                playerBundleId: p.playerBundle.id,
                locale: getCurrentLocale()
              });
              if (!couponInfo.canUse) {
                openErrorToast(couponInfo.prettyMessage);
              } else {
                p.onSelectCoupon(orgCoupon);
                setCouponInput("");
                openSuccessToast(translate({ defaultMessage: "Applied {code}" }, { code: couponInput }));
              }
            } else {
              openErrorToast(
                translate({ defaultMessage: "Could not find a coupon with the code {code}" }, { code: couponInput })
              );
            }
          }}
        >
          <StyledText style={{ color: COLORS.blue }}>{translate.common.Apply}</StyledText>
        </TouchableOpacity>
      </div>
      {p.selectedCoupon || p.credits.length ? (
        <div className="flex flex-col" style={{ marginTop: 16 }}>
          {p.selectedCoupon ? (
            <View style={{ flexDirection: "row", marginBottom: 16 }}>
              <div
                className={clsx(["w-4 h-4 mr-2 items-center", !!p.disabled && "pointer-events-none opacity-70"])}
                onClick={p.onDeselectCoupon}
              >
                <XCircleIcon color={COLORS.red} />
              </div>
              <StyledText style={{ color: COLORS.red }}>{p.selectedCoupon.code}</StyledText>
            </View>
          ) : null}
          <PaymentDueTodayRow
            label={translate({ defaultMessage: "Invoice Amount" })}
            amountCentsMS={p.orgInvoice.amountDueCents}
          />
          {p.selectedCoupon ? (
            <PaymentDueTodayRow
              rowType="discount"
              label={translate({ defaultMessage: "Coupon Discount" })}
              amountCentsMS={discountAmountCents}
            />
          ) : null}
          {p.credits.map(credit => {
            return (
              <PaymentDueTodayRow
                rowType="discount"
                label={`${translate.common.Credit} (${credit.note})`}
                amountCentsMS={credit.amountCents}
              />
            );
          })}
          <PaymentDueTodayRow
            label={translate({ defaultMessage: "Remaining Invoice Amount" })}
            amountCentsMS={invoiceAmountAfterDiscount}
            rowType="total"
          />
        </div>
      ) : null}
    </div>
  );
}

function PaymentSummarySection(p: {
  dueDateMS: number;
  downPaymentAmountCents?: number;
  paymentPlanSetupFeeAmountCents?: number;
  baseAmountDueToday: number;
  selectedOrgPaymentPlan?: OrgPaymentPlan;
  selectedPaymentMethod?: { id: string; last4: string };
  lateFeeAmountCents: number;
  subTotalAmountCents: number;
  feesAmountCents: number;
  totalAmountCents: number;
  hasAgreedToPayScheduledCharges: boolean;
  toggleHasAgreedToPayScheduledCharges: () => void;
  paymentPlanPayments: PaymentPlanSinglePaymentInfo[];
  onChangePaymentDetails?: (newPaymentDetails: PaymentPlanSinglePaymentInfo[]) => void;
  previewOverridePaymentDateMS?: number;
  extraAmountUserWantsToPayCents: number;
}) {
  const isLate = p.dueDateMS < (p.previewOverridePaymentDateMS ?? Date.now());

  return (
    <div className="flex flex-col" style={{ paddingTop: 16 }}>
      <Divider className="my-4 pb-2" />
      <div className="flex flex-col">
        <StyledText style={{ fontWeight: "bold", marginBottom: 16 }}>
          {translate({ defaultMessage: "Today's Payment" })}
        </StyledText>
        {p.downPaymentAmountCents ? (
          <PaymentDueTodayRow label={translate.common.DownPayment} amountCentsMS={p.downPaymentAmountCents} />
        ) : null}
        {p.paymentPlanSetupFeeAmountCents ? (
          <PaymentDueTodayRow
            label={translate({
              defaultMessage: "Payment Plan Setup Fee"
            })}
            amountCentsMS={p.paymentPlanSetupFeeAmountCents}
          />
        ) : null}
        <PaymentDueTodayRow
          label={
            p.selectedOrgPaymentPlan
              ? translate({ defaultMessage: "Payment Plan Installment(s) Due Today" })
              : translate.common.Amount
          }
          amountCentsMS={p.baseAmountDueToday}
        />
        {p.extraAmountUserWantsToPayCents ? (
          <PaymentDueTodayRow
            label={translate({ defaultMessage: "Extra Amount" })}
            amountCentsMS={p.extraAmountUserWantsToPayCents}
          />
        ) : null}
        {isLate ? <PaymentDueTodayRow label={translate.common.LateFee} amountCentsMS={p.lateFeeAmountCents} /> : null}
        <PaymentDueTodayRow rowType="subtotal" label={translate.common.Subtotal} amountCentsMS={p.subTotalAmountCents} />
        <PaymentDueTodayRow label={translate({ defaultMessage: "Processing Fees" })} amountCentsMS={p.feesAmountCents} />
        <PaymentDueTodayRow rowType="total" label={translate.common.Total} amountCentsMS={p.totalAmountCents} />
      </div>
      {p.paymentPlanPayments.length && p.selectedOrgPaymentPlan ? (
        <div className="flex flex-col">
          <div
            className="flex flex-col"
            style={{ borderTopWidth: 2, marginTop: 16, borderTopColor: COLORS.grey_07, paddingTop: 16 }}
          >
            <StyledText style={{ fontWeight: "bold", marginTop: 16, marginBottom: 12 }}>
              {translate({ defaultMessage: "Payment Plan Details" })} - {p.selectedOrgPaymentPlan.name}
            </StyledText>
            {p.selectedOrgPaymentPlan.installmentFeeAmountCents ? (
              <StyledText style={{ marginBottom: 12, fontSize: 14, color: COLORS.grey_66 }}>
                {translate(
                  {
                    defaultMessage: "This payment plan has a {amount} installment fee."
                  },
                  { amount: formatMoneyCentsToDollarCentPrettyString(p.selectedOrgPaymentPlan.installmentFeeAmountCents) }
                )}
              </StyledText>
            ) : null}
            {p.selectedOrgPaymentPlan.allowUsersToOverrideChargeDateUpToLateFeeDeadline ? (
              <StyledText style={{ marginBottom: 12, fontSize: 14, color: COLORS.grey_66 }}>
                {translate({
                  defaultMessage: "You may tap dates below to customize payments dates."
                })}
              </StyledText>
            ) : null}

            <PaymentPlanTable
              previewOverridePaymentDateMS={p.previewOverridePaymentDateMS}
              paymentDetails={p.paymentPlanPayments}
              onChangePaymentDetails={
                p.onChangePaymentDetails
                  ? newPaymentDetails => {
                      p.onChangePaymentDetails?.(newPaymentDetails);
                    }
                  : undefined
              }
              selectedOrgPaymentPlan={p.selectedOrgPaymentPlan}
            />
          </div>
        </div>
      ) : null}
      {p.paymentPlanPayments.length && p.selectedOrgPaymentPlan && !p.previewOverridePaymentDateMS ? (
        <div className="mt-4 italic text-gray-800 pr-2">
          <Divider className="mb-4" />
          <PrettyCheckbox
            checked={p.hasAgreedToPayScheduledCharges}
            onChange={() => {
              p.toggleHasAgreedToPayScheduledCharges();
            }}
            isRequired
            label={translate(
              {
                defaultMessage:
                  'By checking this box, I agree to the payment plan "{paymentPlanName}" and commit to timely payments as outlined above. I understand that failure to adhere to this plan may result in additional fees or penalties.'
              },
              { paymentPlanName: p.selectedOrgPaymentPlan.name }
            )}
          />
        </div>
      ) : null}
    </div>
  );
}

function PaymentPlanTable(p: {
  paymentDetails: PaymentPlanSinglePaymentInfo[];
  onChangePaymentDetails?: (newPaymentDetails: PaymentPlanSinglePaymentInfo[]) => void;
  selectedOrgPaymentPlan?: OrgPaymentPlan;
  previewOverridePaymentDateMS?: number;
}) {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const checkoutDate = p.previewOverridePaymentDateMS ?? Date.now();
  return (
    <div className="flex flex-col">
      <CoolerTable
        columnDefs={[
          {
            label: translate.common.Date,
            getValue(item) {
              if (isPaymentDueToday(checkoutDate, item.originalPaymentDateMS)) {
                return `${
                  mobileDevice
                    ? dateFormatters.m_d_yy(moment(item.originalPaymentDateMS).toDate(), getCurrentLocale())
                    : dateFormatters.mm_dd_yyyy(moment(item.originalPaymentDateMS).toDate(), getCurrentLocale())
                } (${translate({ defaultMessage: "Paid Today" })})`;
              }
              return p.onChangePaymentDetails ? (
                <PrettyCoolDateInput
                  onChange={newVal => {
                    const newPaymentDetails = p.paymentDetails.reduce((acc, val) => {
                      if (item.originalPaymentDateMS === val.originalPaymentDateMS) {
                        acc.push({ ...val, manualDateMS: moment(newVal).startOf("day").valueOf() });
                      } else {
                        acc.push(val);
                      }
                      return acc;
                    }, [] as PaymentPlanSinglePaymentInfo[]);
                    p.onChangePaymentDetails?.(newPaymentDetails);
                  }}
                  value={moment(item.manualDateMS ?? item.originalPaymentDateMS)
                    .startOf("day")
                    .toDate()}
                  minDate={moment().add(1, "day").startOf("day").toDate()}
                  maxDate={moment(item.dueDateMS).startOf("day").toDate()}
                />
              ) : mobileDevice ? (
                dateFormatters.m_d_yy(moment(item.originalPaymentDateMS).toDate(), getCurrentLocale())
              ) : (
                dateFormatters.mm_dd_yyyy(moment(item.originalPaymentDateMS).toDate(), getCurrentLocale())
              );
            }
          },
          {
            label: translate.common.Amount,
            getValue(item) {
              return formatMoneyCentsToDollarCentPrettyString(
                isPaymentDueToday(checkoutDate, item.originalPaymentDateMS) ? 0 : item.amountCents
              );
            },
            getValueForTotalRow(items) {
              return formatMoneyCentsToDollarCentPrettyString(
                _.sum(items.map(item => (isPaymentDueToday(checkoutDate, item.originalPaymentDateMS) ? 0 : item.amountCents)))
              );
            }
          },
          {
            label: translate({ defaultMessage: "Estimated Fees" }),
            getValue(item) {
              return formatMoneyCentsToDollarCentPrettyString(
                isPaymentDueToday(checkoutDate, item.originalPaymentDateMS) ? 0 : item.feeAmountCents
              );
            },
            getValueForTotalRow(items) {
              return formatMoneyCentsToDollarCentPrettyString(
                _.sum(items.map(item => (isPaymentDueToday(checkoutDate, item.originalPaymentDateMS) ? 0 : item.feeAmountCents)))
              );
            }
          },
          {
            label: translate.common.Total,
            getValue(item) {
              return formatMoneyCentsToDollarCentPrettyString(
                isPaymentDueToday(checkoutDate, item.originalPaymentDateMS) ? 0 : item.amountCents + item.feeAmountCents
              );
            },
            getCellCustomClassName(item) {
              return "font-bold";
            },
            getValueForTotalRow(items) {
              return formatMoneyCentsToDollarCentPrettyString(
                _.sum(
                  items.map(item =>
                    isPaymentDueToday(checkoutDate, item.originalPaymentDateMS) ? 0 : item.amountCents + item.feeAmountCents
                  )
                )
              );
            }
          }
        ]}
        getRowCustomClassName={item => {
          const checkoutDate = p.previewOverridePaymentDateMS ?? Date.now();

          if (isPaymentDueToday(checkoutDate, item.originalPaymentDateMS)) {
            return "bg-green-50";
          }
          return "";
        }}
        getItemKey={item => `${item.originalPaymentDateMS} ${Math.random()}`} // This is a hack while I test and the payment dates are all the same...
        items={p.paymentDetails}
      />

      {p.selectedOrgPaymentPlan?.lateFeeAmountCents ? (
        <StyledText style={{ marginTop: 4, fontSize: 14, color: COLORS.grey_66 }}>
          {translate(
            {
              defaultMessage:
                "Note: Late payments will be subject to a {lateFeeAmount} late fee. A payment is considered late after {numDays} days."
            },
            {
              numDays: p.selectedOrgPaymentPlan.numberOfDaysUntilLate,
              lateFeeAmount: formatMoneyCentsToDollarCentPrettyString(p.selectedOrgPaymentPlan.lateFeeAmountCents)
            }
          )}
        </StyledText>
      ) : null}
    </div>
  );
}

function PaymentDueTodayRow(p: { label: string; amountCentsMS: number; rowType?: "subtotal" | "total" | "discount" }) {
  const isTotalOrSubtotal = p.rowType === "subtotal" || p.rowType === "total";
  return (
    <div
      className="flex flex-col"
      style={{
        flexDirection: "row",
        flex: 1,
        marginBottom: !isTotalOrSubtotal ? 4 : 0,
        paddingTop: isTotalOrSubtotal ? 4 : 0,
        paddingBottom: isTotalOrSubtotal ? 4 : 0,
        borderTopWidth: isTotalOrSubtotal ? 1 : 0
      }}
    >
      <StyledText style={{ flex: 1, fontWeight: p.rowType === "total" ? 800 : p.rowType === "subtotal" ? 500 : undefined }}>
        {p.label}
      </StyledText>
      <StyledText
        style={{
          fontWeight: p.rowType === "total" ? 800 : p.rowType === "subtotal" ? 500 : undefined,
          color: p.rowType === "discount" ? COLORS.green : undefined,
          marginLeft: 16
        }}
      >
        {`${p.rowType === "discount" ? "-" : ""}${formatMoneyCentsToDollarCentPrettyString(p.amountCentsMS)}`}
      </StyledText>
    </div>
  );
}

function Divider(p: { className?: string }) {
  return (
    <div
      className={p.className}
      style={{
        borderTopWidth: 2,
        borderTopColor: COLORS.grey_07
      }}
    />
  );
}

function isPaymentDueToday(checkoutDateMS: number, scheduledDateMS: number) {
  return (
    scheduledDateMS < checkoutDateMS ||
    (moment(scheduledDateMS).isSame(moment(checkoutDateMS), "date") &&
      moment(scheduledDateMS).isSame(moment(checkoutDateMS), "month") &&
      moment(scheduledDateMS).isSame(moment(checkoutDateMS), "year"))
  );
}

function LineItemsSection(p: { lineItems: OrgRegistrationPackage["lineItems"] }) {
  if (!p.lineItems) {
    return null;
  }

  const sortedLineItems = _(
    Object.keys(p.lineItems).map(itemKey => {
      const item = p.lineItems?.[itemKey];
      if (!item) {
        return null;
      }
      return { label: itemKey, ...item };
    })
  )
    .compact()
    .orderBy(a => a.sortOrder, "asc")
    .value();

  if (!sortedLineItems.length) {
    return null;
  }

  return (
    <div className="mt-4">
      <div className="mb-4">
        <StyledText style={{ fontWeight: "bold" }}>{translate({ defaultMessage: "Cost Breakdown" })}</StyledText>
      </div>
      {sortedLineItems.map(item => {
        return <PaymentDueTodayRow key={item.label} amountCentsMS={item.amountCents} label={item.label} />;
      })}
      <PaymentDueTodayRow
        amountCentsMS={_.sum(sortedLineItems.map(a => a.amountCents))}
        label={translate.common.Total}
        rowType="total"
      />
    </div>
  );
}
