import { ReactNode } from "react";
import { View } from "react-native-web";
import { StyledText } from "../../../components/StyledText";

export function TableSectionWrapper(p: { children: ReactNode; title: string; style?: any; rightButton?: JSX.Element }) {
  return (
    <View style={[{ marginTop: 30 }, p.style]}>
      <View style={{ flexDirection: "row" }}>
        <StyledText style={{ fontSize: 24, fontWeight: "bold", marginBottom: 8, flex: 1 }}>{p.title}</StyledText>
        {p.rightButton}
      </View>
      {p.children}
    </View>
  );
}
