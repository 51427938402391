import { Org, SerializedFile } from "@ollie-sports/models";
import sevenZipBin from "7zip-bin";
import execa from "execa";
import _ from "lodash";
import { promises as fs } from "fs";
import { ObjectKeys } from "../../utils";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";

export async function org__server__newClubMerchantForm(raw: {
  selfAccountId: string;
  orgId: string;
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  phone: string;
  taxID: string;
  address: string;
  city: string;
  stateProvince: string;
  postal: string;
  stateOfRegistration: string;
  bankAccountNumber: string;
  bankRoutingNumber: string;
  recentBankStatements: SerializedFile[] | null;
  highVolumeBankStatements: SerializedFile[] | null;
  voidedCheckImage: SerializedFile[] | null;
  peakProcessingMonth: string;
  percentFeesPaidUpfront: string;
  averageRegistrationPackageSize: string;
  mostExpensiveRegistrationPackageSize: string;
  numberOfPlayersInClub: string;
  numberOfMonthsInMostPopularPaymentPlan: string;
  recentProcessingStatements: SerializedFile[] | null;
  highVolumeProcessingStatements: SerializedFile[] | null;
  annualVolume: string;
  emailAddress: string;
  ownerPhone: string;
  ownerAddress: string;
  ownerCity: string;
  ownerState: string;
  ownerZip: string;
  socialSecurityNum: string;
  driversLicenseNum: string;
  driversLicenseState: string;
  photoOfDriversLicense: SerializedFile[] | null;
  dateOfBirth: string;
}): Promise<void> {
  // SERVER_ONLY_TOGGLE
  const { orgId, selfAccountId, ...p } = raw;

  const { serverConfig, appOllieFirestoreV2: h, appFirebaseAdminApp, injectedServerLibraries } = getServerHelpers();
  const [org, orgSecret] = await Promise.all([h.Org.getDoc(orgId), h.OrgSecret.getDoc(orgId)]);

  if (!org?.accounts[selfAccountId]?.exists) {
    throw new Error("User does not have access to submit merchant form");
  }

  const uploadID = `info-${Math.random().toString().slice(2)}`;
  const tmpDir = `/tmp/${uploadID}`;
  const bundleFilename = `bundle-${uploadID}.7z`;
  const bundlePath = `/tmp/${bundleFilename}`;

  await fs.mkdir(tmpDir);

  try {
    await fs.writeFile(
      tmpDir + "/info.txt",
      Buffer.from(
        ObjectKeys(p)
          .filter(a => typeof p[a] === "string")
          .map(key => {
            return [upperCase(key), p[key]].join("\n");
          })
          .join("\n\n"),
        "utf-8"
      )
    );

    await Promise.all(
      ObjectKeys(p)
        .filter(key => p[key] instanceof Array)
        .map(async key => {
          const files = p[key] as SerializedFile[];
          for (let i = 0; i < files.length; i++) {
            const file = files[i]!;
            const num = files.length > 1 ? i + 1 : "";
            const filename = [key, num, file.name].filter(a => a).join("-");
            await fs.writeFile(tmpDir + "/" + filename, Buffer.from(file.base64Content, "base64"));
          }
        })
    );

    await fs.chmod(sevenZipBin.path7za, 0o755);

    await execa.command(
      `${sevenZipBin.path7za} a -t7z -p${serverConfig.merchantOnboardingSecurityKey} -mhe=on -mx=9 ${bundlePath} ${tmpDir}`
    );

    const gcpFile = appFirebaseAdminApp.storage().bucket(`${serverConfig.projectId}-merchant-onboarding`).file(bundleFilename);

    await gcpFile.save(await fs.readFile(bundlePath));

    const downloadUrl = await gcpFile.getSignedUrl({ action: "read", expires: "03-09-2500" }).then(a => a[0]);

    await h.OrgSecret.set({
      id: orgId,
      doc: {
        ...(orgSecret || {}),
        id: orgId,
        merchantOnboardingInfo: {
          sevenZipBundleUrl: downloadUrl,
          uploadedAtMS: Date.now(),
          uploaderAccountId: selfAccountId
        }
      }
    });

    await injectedServerLibraries.sendGrid.send({
      from: {
        email: "noreply@olliesports.com",
        name: "Ollie Merchant Onboarding"
      },
      to: [{ email: "jcook@discountpayments.com" }],
      cc: [{ email: "connor@olliesports.com" }],
      subject: `New Merchant Submission (${org.name})`,
      html: `
        <!DOCTYPE html>
        <html>
        <head>
        <meta charset="utf-8">
        <title>New Merchant Submission</title>
        <meta name="description" content="Event Export">
        <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
        </head>
        <body>
        <p>${org.name} has submitted their merchant processing information. You may download all the information in a password protected 7zip bundle at the below link. Be sure to use software that can access password protected 7zip files after downloading.</p>
        <p style="margin-top:10px"><a target="_blank" title="${downloadUrl}" download="${bundleFilename}" href="${downloadUrl}">Download Link</a></p>
        </body>
        </html>
        `
    });
  } finally {
    await fs.rmdir(tmpDir).catch(() => {});
    await fs.unlink(bundlePath).catch(() => {});
  }

  // SERVER_ONLY_TOGGLE
}

org__server__newClubMerchantForm.auth = async (req: any) => {
  await validateTokenAndEnsureSelfAccountIdMatches(req);
};

function upperCase(str: string) {
  return _.snakeCase(str)
    .split("_")
    .map(a => a.toUpperCase())
    .join(" ");
}
