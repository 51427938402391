import { Typography, Box, Button, SvgIcon } from "@material-ui/core";
import { View } from "react-native-web";
import {
  Org,
  OrgPaymentInvoiceWithOrgInvoiceAndParentOrgInvoiceWhereApplicable,
  OrgInvoiceTypes,
  PlayerBundleId,
  OrgPaymentType
} from "@ollie-sports/models";
import { CenteredLoader } from "../../components/CenteredLoader";
import { dateFormatters, getCurrentLocale, translate } from "@ollie-sports/i18n";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { GlobeAltIcon, LinkIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import { useOrg } from "../../hooks/useOrg";
import {
  COLORS,
  PRETTY__ORG_PAYMENT_TYPE,
  formatMoneyCentsToDollarCentPrettyString,
  getOverallOrgInvoiceAmountDetails
} from "@ollie-sports/core";
import { getBifrost } from "../../services/bifrost.service";
import moment from "moment";
import { CoolTextInput } from "../../components/Inputs/CoolTextInput";
import { useState } from "react";
import { AsyncFancyTable } from "../../components/AsyncFancyTable";
import { getCurrentUserAccountId } from "../../hooks/commonDataUtils";
import { CoolSelectInput } from "../../components/Inputs/CoolSelectInput";
import { CoolDateInput } from "../../components/Inputs/CoolDateInput";
import { FileText, ArrowRight } from "react-feather";
import { possiblyConvertNMIResponseCodeToTranslatedPrettyString } from "@ollie-sports/core/dist/utils/payment-helpers";

export default function OrgPayments() {
  const params: any = useParams();
  const orgId = params.orgId;

  const { org, isLoading } = useOrg({ orgId });

  return (
    <Box px={3} py={2} display="flex" style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        {isLoading ? (
          <CenteredLoader />
        ) : org ? (
          <OrgPaymentsInner org={org} />
        ) : (
          <Typography>{translate({ defaultMessage: "Failed to load payments" })}</Typography>
        )}
      </View>
    </Box>
  );
}

function OrgPaymentsInner(p: { org: Org }) {
  const [refreshCounter, setRefreshCounter] = useState(0);
  const history = useHistory();
  return (
    <div style={{ flex: 1 }}>
      <div className="flex">
        <h1 className="flex-1 text-2xl sm:text-4xl mt-4">{translate.common.Payments}</h1>
      </div>
      <div className="pb-8">
        <AsyncFancyTable
          getRowKey={item => item.orgPayment.id + item.orgInvoice.id}
          pagination={{ initialPageSize: 50, pageSizes: [50, 100, 150] }}
          dataCachingKey={"payments-" + p.org.id}
          noItemsMessage={translate({ defaultMessage: "No payments created yet..." })}
          noFilteredItemsMessage={translate({ defaultMessage: "No payments matching selected filters..." })}
          fetchItems={info =>
            getBifrost()
              .orgInvoice__server__getOrgPaymentsForOrg.fetchServer({
                orgId: p.org.id,
                searchTerm: info.filters?.search,
                selfAccountId: getCurrentUserAccountId(),
                offset: info.pagination.numItemsPerPage * (info.pagination.page - 1),
                sort: info.sort,
                limit: info.pagination.numItemsPerPage,
                type: info.filters?.type ? (info.filters.type as OrgPaymentType) : undefined,
                startDate: info.filters?.startDate,
                endDate: info.filters?.endDate,
                category: info.filters?.category as "registration" | "invoice" | undefined
              })
              .then(a => {
                return {
                  itemsToBeRendered: a.data.data,
                  totalNumberOfItemsMatchingCriteria: a.data.count
                };
              })
          }
          getRowOptions={(item: OrgPaymentInvoiceWithOrgInvoiceAndParentOrgInvoiceWhereApplicable) => {
            return {};
          }}
          extraDeps={[refreshCounter, p.org.id]}
          columns={{
            name: {
              label: translate.common.Player,
              getCell(item) {
                return <PlayerNameCell playerBundleId={item.orgPayment.playerBundleId} />;
              },
              sortable: true
            },
            type: {
              label: translate.common.Type,
              getCell(item) {
                return PRETTY__ORG_PAYMENT_TYPE(getCurrentLocale())[item.orgPayment.type];
              },
              sortable: true
            },
            category: {
              label: translate({ defaultMessage: "Category" }),
              getCell(item) {
                return item.orgInvoice.type === OrgInvoiceTypes.registration ||
                  item.orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment
                  ? translate.common.Registration
                  : translate.common.Invoice;
              },
              sortable: true
            },
            amount: {
              label: translate.common.Amount,
              getCell(item) {
                return formatMoneyCentsToDollarCentPrettyString(
                  item.orgPayment.amountCents +
                    (item.orgPayment.lateFeeAmountCents ?? 0) +
                    (item.orgPayment.processingFeeAmountCents ?? 0)
                );
              },
              sortable: true
            },
            date: {
              label: translate.common.Date,
              getCell(item: OrgPaymentInvoiceWithOrgInvoiceAndParentOrgInvoiceWhereApplicable) {
                return dateFormatters.mm_dd_yyyy(moment(item.orgPayment.createdAtMS).toDate(), getCurrentLocale());
              },
              sortable: true
            },
            memo: {
              label: translate.common.Memo,
              getCell(item: OrgPaymentInvoiceWithOrgInvoiceAndParentOrgInvoiceWhereApplicable) {
                return `${item.orgInvoice.memo}${item.parentOrgInvoice ? ` (${item.parentOrgInvoice.memo})` : ""}`;
              }
            },
            failureCode: {
              label: translate({ defaultMessage: "Failure Reason" }),
              getCell(item: OrgPaymentInvoiceWithOrgInvoiceAndParentOrgInvoiceWhereApplicable) {
                if (item.orgPayment.type === OrgPaymentType.invoiceFailedPayment && item.orgPayment.nmiPaymentResponseInfo) {
                  return possiblyConvertNMIResponseCodeToTranslatedPrettyString({
                    responseCode: item.orgPayment.nmiPaymentResponseInfo.responseCode,
                    locale: getCurrentLocale()
                  });
                }
                return "";
              }
            }
          }}
          buttons={[
            // Payment TODO Add receipt URL?
            {
              label: item => {
                return (
                  <div style={{ color: COLORS.blue, marginRight: 5, marginTop: 2 }}>
                    {translate({ defaultMessage: "View Invoice" })}
                  </div>
                );
              },
              icon: item => {
                return <ArrowRight color={COLORS.blue_66} />;
              },
              onClick: async item => {
                history.push({
                  pathname: `/app/org/${item.orgInvoice.orgId}/invoices/${item.parentOrgInvoice?.id ?? item.orgInvoice.id}`
                });
              }
            }
          ]}
          renderFiltersWrapper={filters => <div className="my-4 flex flex-row flex-wrap">{filters}</div>}
          renderFilters={{
            search: a => (
              <CoolTextInput
                style={{ width: 500 }}
                value={a.value}
                placeholder={translate({ defaultMessage: "Search by player name" })}
                onChange={newVal => {
                  a.setValueDebounced(newVal || "", 500, { leading: false, trailing: true });
                }}
              />
            ),
            type: a => (
              <CoolSelectInput
                value={a.value}
                placeholder={translate.common.Type}
                onChange={newVal => {
                  a.setValue(newVal || "");
                }}
                containerStyle={{ marginLeft: 8 }}
                allowClear
                options={[
                  { value: OrgPaymentType.invoiceDefault, label: translate.common.Payment },
                  { value: OrgPaymentType.invoiceCredit, label: translate.common.Credit },
                  { value: OrgPaymentType.invoiceFailedPayment, label: translate.common.Failed }
                ]}
              />
            ),
            category: a => (
              <CoolSelectInput
                value={a.value}
                placeholder={translate({ defaultMessage: "Category" })}
                onChange={newVal => {
                  a.setValue(newVal || "");
                }}
                containerStyle={{ marginLeft: 8 }}
                allowClear
                options={[
                  { value: "invoice", label: translate.common.Invoice },
                  { value: "registration", label: translate.common.Registration }
                ]}
              />
            ),
            startDate: a => (
              <CoolDateInput
                className="ml-1"
                value={a.value ? moment(a.value).toDate() : undefined}
                placeholderText={translate.common.StartDate}
                isClearable
                onChange={newVal => {
                  a.setValue(moment(newVal).startOf("day").valueOf());
                }}
              />
            ),
            endDate: a => (
              <CoolDateInput
                className="ml-1.5"
                value={a.value ? moment(a.value).toDate() : undefined}
                placeholderText={translate.common.EndDate}
                isClearable
                onChange={newVal => {
                  a.setValue(moment(newVal).endOf("day").valueOf());
                }}
              />
            )
          }}
          initialFilterValues={{
            search: "",
            type: "",
            category: "",
            startDate: undefined as number | undefined,
            endDate: undefined as number | undefined
          }}
        />
      </div>
    </div>
  );
}

function PlayerNameCell(p: { playerBundleId: PlayerBundleId }) {
  const { data: playerBundle } = getBifrost().playerBundle__server__getPlayerBundle.useServer({
    id: p.playerBundleId
  });
  return (
    <div>
      {playerBundle ? `${playerBundle.virtualAthleteAccount.firstName} ${playerBundle.virtualAthleteAccount.lastName}` : ""}
    </div>
  );
}
