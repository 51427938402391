import { Typography, Box, useMediaQuery, useTheme, Button, makeStyles } from "@material-ui/core";
import { View } from "react-native-web";
import { dateFormatters, getCurrentLocale, translate } from "@ollie-sports/i18n";
import { useLocation, useParams } from "react-router-dom";
import _ from "lodash";
import { getAuthToken, getBifrost } from "../../services/bifrost.service";
import { CenteredLoader } from "../../components/CenteredLoader";
import { OrgInvoicePay } from "./OrgInvoicePay";
import { ShadowView } from "../../components/ShadowView";
import { StyledText } from "../../components/StyledText";
import LoginView from "src/views/auth/LoginView";
import {
  Org,
  OrgInvoice__Registration,
  OrgRegistrationAnswer,
  OrgRegistrationAnswerCheckbox,
  OrgRegistrationAnswerFreeResponse,
  OrgRegistrationAnswerLegalDoc,
  OrgRegistrationAnswerRadio,
  OrgRegistrationPackage,
  OrgRegistrationQuestion,
  OrgRegistrationQuestionGrouping,
  OrgRegistrationQuestionType,
  OrgRegistrationQuestion__AdditionalStep,
  OrgSeason,
  OrgSettings,
  PlayerBundle,
  PrettyPlayerBundle
} from "@ollie-sports/models";
import { OrgUnathenticatedPageHeader } from "../../components/OrgUnauthenticatedPageHeader";
import { SectionHeader } from "../openOrgEvents/OpenOrgEventRegistrationPage";
import { COLORS, compute, ensureColorHasHashtag, htmlify } from "@ollie-sports/core";
import {
  OrgRegistrationQuestionFreeResponse,
  OrgRegistrationQuestionExplainer,
  OrgRegistrationQuestionRadio,
  OrgRegistrationQuestionCheckbox,
  OrgRegistrationQuestionLegalDoc
} from "../../components/OrgRegistrationQuestions";
import { Form, FormRef } from "../../components/Form";
import { useEffect, useRef, useState } from "react";
import { Expando } from "../../components/Expando";
import { openErrorToast } from "../../utils/openErrorToast";
import { useOrgSeasons } from "../../hooks/useOrgSeasons";
import { getCurrentUserAccountId } from "../../hooks/commonDataUtils";
import { getFbApp } from "../../services/fb.service";
import { useGlobalState } from "../../services/global-state.service";
import { usePersistentState } from "../../utils/usePersistentState";
import { StyledButton } from "../../components/StyledButton";
import { TextButton } from "../../components/TextButton";
import { LogOut } from "react-feather";
import { logout } from "../../services/auth.service";
import CoolerTable from "../../components/CoolerTable";
import { escapeHtmlAndWrapLinks } from "../../utils/parseText";
import moment from "moment";
import clsx from "clsx";
import { CheckIcon } from "@heroicons/react/24/outline";

const useStyles = makeStyles({
  eventDetails: {
    "& a": {
      color: COLORS.blue
    }
  }
});

export default function OrgInvoiceRegistration() {
  const params: any = useParams();
  const orgId = params.orgId;
  const orgSeasonId = params.orgSeasonId;
  const playerBundleId = params.playerBundleId;
  const queryParams = new URLSearchParams(useLocation().search);
  const serverJwtToken = queryParams.get("serverJwtToken");

  const [errorLoggingIn, setErrorLoggingIn] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(!!serverJwtToken);

  const { data: playerBundle } = getBifrost().playerBundle__server__getPrettyPlayerBundle.useClient({ id: playerBundleId });

  useEffect(() => {
    (async () => {
      if (serverJwtToken) {
        try {
          setIsLoggingIn(true);
          await getFbApp().auth().signInWithCustomToken(serverJwtToken);
          setErrorLoggingIn(false);
        } catch (e) {
          setErrorLoggingIn(true);
        } finally {
          document.dispatchEvent(new CustomEvent("notify-ollie-app-of-ready-if-inside-iframe"));
          setIsLoggingIn(false);
        }
      }
    })();
  }, [serverJwtToken]);

  const {
    data: orgInvoiceInfoData,
    isLoading: isInfoLoading,
    forceRefresh
  } = getBifrost().orgInvoice__server__getInfoForPayingRegistrationInvoiceNoAuth.useServer(
    {
      orgId,
      orgSeasonId,
      playerBundleId
    },
    { notifyOnMetaDataChanges: true }
  );

  const { data: org, isLoading: isOrgLoading } = getBifrost().org__server__getOrgNoAuth.useServer(
    {
      orgId
    },
    { notifyOnMetaDataChanges: true }
  );

  const { data: orgSettings, isLoading: isOrgSettingsLoading } = getBifrost().orgSettings__server__getOrgSettingsNoAuth.useServer(
    {
      orgId
    },
    { notifyOnMetaDataChanges: true }
  );

  if (errorLoggingIn) {
    return (
      <div className="flex m-4">
        {translate({ defaultMessage: "Unable to load the page. Please try again or contact support@olliesports.com" })}
      </div>
    );
  }

  const hasRegistrationAndHasNoOutstandingAdditionalSteps =
    orgInvoiceInfoData?.orgRegistration && orgInvoiceInfoData.orgRegistration.completedAllAdditionalStepsAtMS > 0;
  const hasRegistrationButHasOutstandingAdditionalSteps =
    orgInvoiceInfoData?.orgRegistration && orgInvoiceInfoData.orgRegistration.completedAllAdditionalStepsAtMS === 0;

  return (
    <Box display="flex" style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        {isOrgLoading || isOrgSettingsLoading || isInfoLoading || isLoggingIn ? (
          <CenteredLoader />
        ) : hasRegistrationAndHasNoOutstandingAdditionalSteps && org && orgSettings ? (
          <OrgUnathenticatedPageHeader org={org} orgSettings={orgSettings}>
            <ShadowView style={{ marginTop: 30, padding: 30, borderRadius: 20 }}>
              <StyledText>
                {translate(
                  { defaultMessage: "You're all set! {playerName} is succesfully registered to play for {orgName}." },
                  {
                    orgName: org.name,
                    playerName: playerBundle?.derived.accountInfo.firstName || translate({ defaultMessage: "Your player" })
                  }
                )}
              </StyledText>
            </ShadowView>
          </OrgUnathenticatedPageHeader>
        ) : hasRegistrationButHasOutstandingAdditionalSteps &&
          org &&
          orgSettings &&
          orgInvoiceInfoData.prettyPlayerBundle.playerBundle ? (
          <RegistrationAdditionalSteps
            org={org}
            orgSettings={orgSettings}
            playerBundle={orgInvoiceInfoData.prettyPlayerBundle}
            orgRegistrationAnswers={orgInvoiceInfoData.orgRegistrationAnswers}
            orgRegistrationQuestionsInfo={orgInvoiceInfoData.orgRegistrationQuestionsInfo}
          />
        ) : org &&
          orgInvoiceInfoData?.orgInvoice &&
          orgInvoiceInfoData?.orgRegistrationPackage &&
          orgInvoiceInfoData.prettyPlayerBundle.playerBundle &&
          orgInvoiceInfoData?.orgSeason &&
          orgSettings ? (
          <RegistrationInner
            org={org}
            orgSeason={orgInvoiceInfoData.orgSeason}
            orgSettings={orgSettings}
            onComplete={async () => {
              document.dispatchEvent(new CustomEvent("notify-ollie-app-of-registration-success"));
              await forceRefresh();
            }}
            orgInvoice={orgInvoiceInfoData.orgInvoice}
            prettyPlayerBundle={orgInvoiceInfoData.prettyPlayerBundle}
            orgRegistrationQuestionsInfo={orgInvoiceInfoData.orgRegistrationQuestionsInfo}
            orgRegistrationPackage={orgInvoiceInfoData.orgRegistrationPackage}
            isWebView={!!serverJwtToken}
          />
        ) : (
          <RegistrationNotFound org={org ?? undefined} orgSettings={orgSettings ?? undefined} />
        )}
      </View>
    </Box>
  );
}

function RegistrationAdditionalSteps(p: {
  org: Org;
  orgSettings: OrgSettings;
  playerBundle: PrettyPlayerBundle;
  orgRegistrationQuestionsInfo?: {
    orgRegistrationQuestionGroupings: OrgRegistrationQuestionGrouping[];
    orgRegistrationQuestions: OrgRegistrationQuestion[];
  };
  orgRegistrationAnswers?: OrgRegistrationAnswer[];
}) {
  const additionalStepQuestionsWithAnswerWhenExists =
    p.orgRegistrationQuestionsInfo?.orgRegistrationQuestions
      .filter(q => q.type === OrgRegistrationQuestionType.additionalStep)
      .map(q => {
        const answer = p.orgRegistrationAnswers?.find(a => a.questionId === q.id);
        return {
          question: q as OrgRegistrationQuestion__AdditionalStep,
          answer
        };
      }) ?? [];

  return (
    <OrgUnathenticatedPageHeader org={p.org} orgSettings={p.orgSettings}>
      <ShadowView style={{ marginTop: 30, padding: 30, borderRadius: 20, maxWidth: 500 }}>
        <StyledText>
          {translate(
            {
              defaultMessage:
                "Thanks for your payment! To finish registering {playerName} for {orgName}, please complete the following step(s) and then ask a team admin or coach to mark them as completed."
            },
            {
              orgName: p.org.name,
              playerName: p.playerBundle.derived.accountInfo.firstName || translate({ defaultMessage: "Your player" })
            }
          )}
        </StyledText>
        <div className="mt-4">
          {_.orderBy(additionalStepQuestionsWithAnswerWhenExists, a => a.question.shortTitle, "asc").map(item => {
            let isComplete = !!(item.answer && item.answer.type === OrgRegistrationQuestionType.additionalStep);
            return (
              <div className="mt-4">
                <div className="flex flex-row items-center">
                  <div
                    className={clsx(
                      `w-4 h-4 p-0.5 bg-white  border rounded-full mr-2 border-black`,
                      isComplete && `border-[${COLORS.green}] bg-[${COLORS.green}]`
                    )}
                  >
                    <CheckIcon color={COLORS.white} />
                  </div>
                  <div className="font-bold">{item.question.shortTitle}</div>
                </div>
                <div dangerouslySetInnerHTML={{ __html: htmlify(item.question.descriptionMD) }} className="mt-2" />
              </div>
            );
          })}
        </div>
      </ShadowView>
    </OrgUnathenticatedPageHeader>
  );
}
function RegistrationInner(p: {
  org: Org;
  orgSettings: OrgSettings;
  orgInvoice: Omit<OrgInvoice__Registration, "id" | "invoiceGroupId">;
  prettyPlayerBundle: PrettyPlayerBundle;
  orgSeason: OrgSeason;
  onComplete: () => Promise<void>;
  orgRegistrationPackage: OrgRegistrationPackage;
  orgRegistrationQuestionsInfo?: {
    orgRegistrationQuestionGroupings: OrgRegistrationQuestionGrouping[];
    orgRegistrationQuestions: OrgRegistrationQuestion[];
  };
  isWebView: boolean;
}) {
  const classes = useStyles({ color: COLORS.blue });
  const isLoggedIn = useGlobalState().auth.isLoggedIn;
  const [answers, setAnswers] = usePersistentState<Record<string, Omit<OrgRegistrationAnswer, "id">>>({
    initialValue: {},
    key: p.prettyPlayerBundle.playerBundle.id + p.org.id + "-org-invoice-registration"
  });
  const [isDetailsSectionVisible, setIsDetailsSectionVisible] = useState(true);
  const [isPaySectionVisible, setIsPaySectionVisible] = useState(false);
  const [isQuestionsSectionVisible, setIsQuestionsSectionVisible] = useState(false);
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [errorMsg, setErrorMsg] = useState("");

  const accountId = isLoggedIn ? getCurrentUserAccountId() : undefined;

  const doesAccountManagePlayerBundle = accountId ? !!p.prettyPlayerBundle.playerBundle.managingAccounts?.[accountId] : false;

  const hasOutstandingAdditionalSteps = !!p.orgRegistrationQuestionsInfo?.orgRegistrationQuestions.filter(
    orq => orq.type === OrgRegistrationQuestionType.additionalStep
  ).length;

  return (
    <OrgUnathenticatedPageHeader org={p.org} orgSettings={p.orgSettings}>
      <Form
        children={isEverythingValid => {
          return (
            <View style={{ maxWidth: 500, flex: 1 }}>
              <ShadowView
                style={{
                  marginTop: 30,
                  borderRadius: 20,
                  backgroundColor: COLORS.white,
                  minWidth: mobileDevice ? 200 : 650
                }}
              >
                <SectionHeader
                  isMobileDevice={mobileDevice}
                  title={translate({ defaultMessage: "Overview" })}
                  org={p.org}
                  orgSettings={p.orgSettings}
                  status={"na"}
                  isSectionExpanded={isDetailsSectionVisible}
                  onPress={() => {
                    setIsDetailsSectionVisible(!isDetailsSectionVisible);
                  }}
                />
                <Expando durationMS={500} isExpanded={isDetailsSectionVisible}>
                  <View style={{ padding: 30 }}>
                    <StyledText>{`${translate.common.Player}: ${p.prettyPlayerBundle.derived.accountInfo.firstName} ${p.prettyPlayerBundle.derived.accountInfo.lastName}`}</StyledText>
                    <StyledText style={{ marginTop: 8 }}>{`${translate.common.Season}: ${p.orgSeason.name}`}</StyledText>
                    <StyledText
                      style={{ marginTop: 8 }}
                    >{`${translate.common.RegistrationPackage}: ${p.orgRegistrationPackage.name}`}</StyledText>
                    {p.orgRegistrationPackage.details ? (
                      <div
                        dangerouslySetInnerHTML={{ __html: htmlify(p.orgRegistrationPackage.details) }}
                        className="mt-4 flex flex-col"
                      />
                    ) : null}
                    {!isLoggedIn ? (
                      <div className="mt-8">
                        <div className="pb-2">{translate({ defaultMessage: "Please login to continue" })}</div>
                        <div
                          style={{
                            borderRadius: 4,
                            border: "1px solid lightgrey",
                            paddingBottom: 12
                          }}
                        >
                          <LoginView className="scale-90" />
                        </div>
                      </div>
                    ) : null}
                    {isLoggedIn && !doesAccountManagePlayerBundle ? (
                      <div style={{ marginTop: 30 }}>
                        {translate({
                          defaultMessage:
                            "You do not have permission to register this player. If you believe you are seeing this in error, you may be logged into the wrong account."
                        })}
                        <TextButton
                          text={translate({ defaultMessage: "Log Out" })}
                          onPress={async () => {
                            await logout();
                          }}
                          iconElm={<LogOut width={16} height={16} className="text-blue-400" />}
                          textClassName="text-blue-400"
                          className="mt-4"
                        />
                      </div>
                    ) : null}
                    <StyledButton
                      text={translate.common.Next}
                      style={{
                        width: "100%",
                        backgroundColor: p.orgSettings.primaryColor
                          ? ensureColorHasHashtag(p.orgSettings.primaryColor)
                          : COLORS.blue,
                        color: COLORS.white,
                        marginTop: 30
                      }}
                      disabled={!isLoggedIn || !doesAccountManagePlayerBundle}
                      onClick={async () => {
                        if (!isLoggedIn) {
                          return;
                        }

                        setIsDetailsSectionVisible(false);
                        if (
                          p.orgRegistrationQuestionsInfo?.orgRegistrationQuestionGroupings.length ||
                          p.orgRegistrationQuestionsInfo?.orgRegistrationQuestions.length
                        ) {
                          setIsQuestionsSectionVisible(true);
                        } else {
                          setIsPaySectionVisible(true);
                        }
                      }}
                    />
                  </View>
                </Expando>
              </ShadowView>
              {isLoggedIn && doesAccountManagePlayerBundle ? (
                <div>
                  {p.orgRegistrationQuestionsInfo?.orgRegistrationQuestionGroupings.length ||
                  p.orgRegistrationQuestionsInfo?.orgRegistrationQuestions.length ? (
                    <RegistrationQuestionSection
                      orgRegistrationQuestionsInfo={p.orgRegistrationQuestionsInfo}
                      answers={answers}
                      onUpdateAnswers={setAnswers}
                      orgSettings={p.orgSettings}
                      org={p.org}
                      isVisible={isQuestionsSectionVisible}
                      setIsVisible={isVisible => {
                        setIsQuestionsSectionVisible(isVisible);
                      }}
                      orgRegistrationPackage={p.orgRegistrationPackage}
                      onNextButtonClick={() => {
                        setIsQuestionsSectionVisible(false);
                        setIsPaySectionVisible(true);
                      }}
                      isWebView={p.isWebView}
                    />
                  ) : null}
                  <OrgInvoicePay
                    type="registration"
                    orgSeason={p.orgSeason}
                    org={p.org}
                    orgSettings={p.orgSettings}
                    orgInvoice={p.orgInvoice}
                    error={{
                      msg: errorMsg,
                      onClear: () => {
                        setErrorMsg("");
                      }
                    }}
                    playerBundle={p.prettyPlayerBundle.playerBundle}
                    onComplete={async data => {
                      try {
                        const { data: result } =
                          await getBifrost().orgInvoice__server__payOrgInvoiceAndPotentiallyGeneratePaymentPlanInstallmentInvoices.fetchServer(
                            {
                              orgInvoice: p.orgInvoice,
                              orgRegistrationAnswers: Object.values(answers),
                              selfAccountId: getCurrentUserAccountId(),
                              locale: getCurrentLocale(),
                              hasOutstandingAdditionalSteps,
                              ...data
                            }
                          );
                        if (result.type === "error") {
                          setErrorMsg(
                            result.prettyErrorReason ??
                              translate({
                                defaultMessage:
                                  "There was a problem processing your registration. Please try again or contact support@olliesports.com"
                              })
                          );
                          openErrorToast(
                            translate({ defaultMessage: "Error! Please view the error message below for details." })
                          );
                          return;
                        }
                        if (result.type === "failed") {
                          setErrorMsg(
                            result.prettyFailureReason ??
                              translate({
                                defaultMessage:
                                  "There was a problem processing your registration. Please try again or contact support@olliesports.com"
                              })
                          );
                          openErrorToast(
                            translate({ defaultMessage: "Error! Please view the error message below for details." })
                          );
                          return;
                        }
                        await p.onComplete();
                        setAnswers({});
                      } catch (e) {
                        openErrorToast(
                          translate({
                            defaultMessage:
                              "There was a problem processing your registration. Please try again or contact support@olliesports.com"
                          })
                        );
                      }
                    }}
                    orgPaymentInvoiceCredits={[]}
                    visibilityOptions={{
                      isVisible: isPaySectionVisible,
                      setIsVisible: isVisible => {
                        setIsPaySectionVisible(isVisible);
                      }
                    }}
                    isEverythingElseValid={isEverythingValid}
                  />
                </div>
              ) : null}
            </View>
          );
        }}
      />
    </OrgUnathenticatedPageHeader>
  );
}

function RegistrationQuestionSection(p: {
  orgRegistrationPackage: OrgRegistrationPackage;
  orgRegistrationQuestionsInfo: {
    orgRegistrationQuestionGroupings: OrgRegistrationQuestionGrouping[];
    orgRegistrationQuestions: OrgRegistrationQuestion[];
  };
  org: Org;
  orgSettings: OrgSettings;
  answers: Record<string, Omit<OrgRegistrationAnswer, "id">>;
  onUpdateAnswers: (newAnswers: Record<string, Omit<OrgRegistrationAnswer, "id">>) => void;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  onNextButtonClick: () => void;
  isWebView: boolean;
}) {
  const theme = useTheme();
  const formRef = useRef<FormRef>(null);
  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const sortedQuestionIds = _.uniq(
    compute.orgRegistrationQuestion.getOrgQuestionIdsSortedForOrgRegistrationPackage({
      orgRegistrationPackage: p.orgRegistrationPackage,
      orgRegistrationQuestionGroupings: p.orgRegistrationQuestionsInfo.orgRegistrationQuestionGroupings,
      orgRegistrationQuestions: p.orgRegistrationQuestionsInfo.orgRegistrationQuestions
    })
  );

  const sortedQuestions = _.compact(
    sortedQuestionIds.map(questionId => {
      return p.orgRegistrationQuestionsInfo.orgRegistrationQuestions.find(q => q.id === questionId);
    })
  ) as OrgRegistrationQuestion[];

  const sortedQuestionsExcludingAdditionalSteps = sortedQuestions.filter(
    q => q.type !== OrgRegistrationQuestionType.additionalStep
  );

  if (!sortedQuestionsExcludingAdditionalSteps.length) {
    return null;
  }

  return (
    <Form
      ref={formRef}
      children={isValid => {
        return (
          <ShadowView
            style={[
              {
                marginTop: 30,
                borderRadius: 20,
                backgroundColor: COLORS.white,
                minWidth: mobileDevice ? 200 : 650
              }
            ]}
          >
            <SectionHeader
              isMobileDevice={mobileDevice}
              title={translate({ defaultMessage: "Questionnaire" })}
              org={p.org}
              orgSettings={p.orgSettings}
              status={isValid ? "complete" : "incomplete"}
              isSectionExpanded={p.isVisible}
              onPress={() => {
                p.setIsVisible(!p.isVisible);
              }}
            />
            <Expando durationMS={500} isExpanded={p.isVisible}>
              <View style={{ padding: 30 }}>
                {sortedQuestionsExcludingAdditionalSteps.map((question, index) => {
                  switch (question.type) {
                    case OrgRegistrationQuestionType.explainer:
                      return (
                        <OrgRegistrationQuestionExplainer key={question.id} hideTopBorder={index === 0} question={question} />
                      );
                    case OrgRegistrationQuestionType.freeResponse:
                      const freeResponseAnswer = p.answers[question.id];
                      let freeResponseValue = "";
                      if (freeResponseAnswer && freeResponseAnswer.type === OrgRegistrationQuestionType.freeResponse) {
                        freeResponseValue = (freeResponseAnswer as OrgRegistrationAnswerFreeResponse).response;
                      }
                      return (
                        <OrgRegistrationQuestionFreeResponse
                          key={question.id}
                          hideTopBorder={index === 0}
                          question={question}
                          value={freeResponseValue}
                          onChangeValue={newVal => {
                            if (!newVal) {
                              const newAnswers = { ...p.answers };
                              delete newAnswers[question.id];
                              p.onUpdateAnswers(newAnswers);
                            } else {
                              const newAnswers = { ...p.answers };
                              const thisNewAnswer: Omit<OrgRegistrationAnswerFreeResponse, "id"> = {
                                type: OrgRegistrationQuestionType.freeResponse,
                                orgId: question.orgId,
                                questionId: question.id,
                                questionSnapshot: question,
                                response: newVal
                              };
                              newAnswers[question.id] = thisNewAnswer;
                              p.onUpdateAnswers(newAnswers);
                            }
                          }}
                        />
                      );
                    case OrgRegistrationQuestionType.radio:
                      const radioAnswer = p.answers[question.id];
                      let radioValue = "";
                      if (radioAnswer && radioAnswer.type === OrgRegistrationQuestionType.radio) {
                        radioValue = (radioAnswer as OrgRegistrationAnswerRadio).selection;
                      }
                      return (
                        <OrgRegistrationQuestionRadio
                          key={question.id}
                          hideTopBorder={index === 0}
                          value={radioValue}
                          question={question}
                          onChangeValue={newVal => {
                            if (!newVal) {
                              const newAnswers = { ...p.answers };
                              delete newAnswers[question.id];
                              p.onUpdateAnswers(newAnswers);
                            } else {
                              const newAnswers = { ...p.answers };
                              const thisNewAnswer: Omit<OrgRegistrationAnswerRadio, "id"> = {
                                type: OrgRegistrationQuestionType.radio,
                                orgId: question.orgId,
                                questionId: question.id,
                                questionSnapshot: question,
                                selection: newVal
                              };
                              newAnswers[question.id] = thisNewAnswer;
                              p.onUpdateAnswers(newAnswers);
                            }
                          }}
                        />
                      );
                    case OrgRegistrationQuestionType.checkbox:
                      const checkboxAnswer = p.answers[question.id];
                      let checkboxValue: string[] = [];
                      if (checkboxAnswer && checkboxAnswer.type === OrgRegistrationQuestionType.checkbox) {
                        checkboxValue = (checkboxAnswer as OrgRegistrationAnswerCheckbox).selections;
                      }
                      return (
                        <OrgRegistrationQuestionCheckbox
                          key={question.id}
                          hideTopBorder={index === 0}
                          question={question}
                          onChangeValue={newVal => {
                            if (!newVal.length) {
                              const newAnswers = { ...p.answers };
                              delete newAnswers[question.id];
                              p.onUpdateAnswers(newAnswers);
                            } else {
                              const newAnswers = { ...p.answers };
                              const thisNewAnswer: Omit<OrgRegistrationAnswerCheckbox, "id"> = {
                                type: OrgRegistrationQuestionType.checkbox,
                                orgId: question.orgId,
                                questionId: question.id,
                                questionSnapshot: question,
                                selections: newVal
                              };
                              newAnswers[question.id] = thisNewAnswer;
                              p.onUpdateAnswers(newAnswers);
                            }
                          }}
                          value={checkboxValue}
                        />
                      );
                    case OrgRegistrationQuestionType.legalDoc:
                      const legalDocAnswer = p.answers[question.id];
                      let isSelected = false;
                      if (legalDocAnswer && legalDocAnswer.type === OrgRegistrationQuestionType.legalDoc) {
                        isSelected = !!(legalDocAnswer as OrgRegistrationAnswerLegalDoc).agreedToAtMS;
                      }
                      return (
                        <OrgRegistrationQuestionLegalDoc
                          key={question.id}
                          question={question}
                          value={isSelected}
                          onChangeValue={newVal => {
                            if (!newVal) {
                              const newAnswers = { ...p.answers };
                              delete newAnswers[question.id];
                              p.onUpdateAnswers(newAnswers);
                            } else {
                              const newAnswers = { ...p.answers };
                              const thisNewAnswer: Omit<OrgRegistrationAnswerLegalDoc, "id"> = {
                                type: OrgRegistrationQuestionType.legalDoc,
                                orgId: question.orgId,
                                questionId: question.id,
                                questionSnapshot: question,
                                agreedToAtMS: Date.now()
                              };
                              newAnswers[question.id] = thisNewAnswer;
                              p.onUpdateAnswers(newAnswers);
                            }
                          }}
                        />
                      );
                  }
                })}
                <View style={{ marginTop: 30 }}>
                  <StyledButton
                    style={{
                      width: mobileDevice ? "100%" : undefined,
                      backgroundColor: p.orgSettings.primaryColor
                        ? ensureColorHasHashtag(p.orgSettings.primaryColor)
                        : COLORS.blue,
                      color: COLORS.white
                    }}
                    onClick={async () => {
                      if (!formRef.current?.validate()) {
                        openErrorToast(translate({ defaultMessage: "Please complete all required fields" }));
                        return;
                      }

                      p.setIsVisible(false);
                      p.onNextButtonClick();
                    }}
                    text={translate.common.Next}
                  />
                </View>
              </View>
            </Expando>
          </ShadowView>
        );
      }}
    />
  );
}

function RegistrationNotFound(p: { org?: Org; orgSettings?: OrgSettings }) {
  const inner = (
    <ShadowView style={{ marginTop: 30, padding: 30, borderRadius: 20 }}>
      <StyledText>
        {translate({
          defaultMessage: "Could not load the registration. You may have the wrong link. Please contact your club admin for help."
        })}
      </StyledText>
    </ShadowView>
  );
  return p.org && p.orgSettings ? (
    <OrgUnathenticatedPageHeader org={p.org} orgSettings={p.orgSettings}>
      {inner}
    </OrgUnathenticatedPageHeader>
  ) : (
    inner
  );
}
