import { Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import {
  Org,
  OrgInvoice,
  OrgInvoiceTypes,
  OrgInvoice__Manual,
  OrgPaymentPlanType,
  PlayerBundleId,
  Team,
  TeamId
} from "@ollie-sports/models";
import { translate } from "@ollie-sports/i18n";
import { getBifrost } from "../../services/bifrost.service";
import { COLORS, ObjectKeys, api } from "@ollie-sports/core";
import { useEffect, useState } from "react";
import { useImmutableState } from "../../utils/useImmutableState";
import { Form, PrettyCoolDateInput, PrettyCoolMultiSelectWithLabel, PrettyCoolTextInputWithLabel } from "../../components/Form";
import moment from "moment";
import AsyncSelect from "react-select/async";
import { getCurrentUserAccountId } from "../../hooks/commonDataUtils";
import { usePromise } from "../../utils/hooks/usePromise";
import { openModal } from "../../components/modals/imperativeModal";
import { dequal } from "dequal";
import getFullScreenModal, { FullScreenModal } from "../../components/modals/getFullscreenModal";
import { useOrgPaymentPlans } from "../../hooks/useOrgPaymentPlans";
import { openErrorToast, openSuccessToast } from "../../utils/openErrorToast";
import { useOrgSettings } from "../../hooks/useOrgSettings";
import { Expando } from "../../components/Expando";
import { View } from "react-native-web";
import { TextButton } from "../../components/TextButton";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/outline";
import { OrgInvoicePayPreviewInner } from "./OrgInvoicePayPreview";
import { mainModule } from "process";
import { StyledText } from "../../components/StyledText";
import { useOrgTeams } from "../../hooks/useOrgTeams";
import { CoolMultiSelectInput, CoolMultiSelectWithLabel } from "../../components/Inputs/CoolMultiSelectInput";
import _, { first } from "lodash";
import { PressableAsterisksText, customizeAsterisks } from "../../components/PressableAsterisksText";

type Props = {
  type: "create" | "edit";
  org: Org;
  initialOrgInvoice?: Partial<OrgInvoice__Manual>;
};

export function openOrgInvoiceAddEditModal(p: Props) {
  return new Promise<boolean>(res => {
    const modal = openModal({
      body: (
        <OrgInvoiceAddEditModal
          {...p}
          onRequestDismiss={didChange => {
            modal.close();
            res(didChange);
          }}
        />
      )
    });
  });
}

function OrgInvoiceAddEditModal(p: Props & { onRequestDismiss: (didChange: boolean) => void }) {
  const initialOrgInvoice = p.initialOrgInvoice ?? {
    orgId: p.org.id
  };
  const [orgInvoice, setOrgInvoice] = useImmutableState<Partial<OrgInvoice__Manual>>(initialOrgInvoice);
  const [amountShadow, setAmountShadow] = useState(orgInvoice.amountDueCents ? String(orgInvoice.amountDueCents / 100) : "");
  const [lateFeeAmountShadow, setLateFeeAmountShadow] = useState(
    orgInvoice.lateFeeCentsToBeIssuedIfLate ? String(orgInvoice.lateFeeCentsToBeIssuedIfLate / 100) : ""
  );
  const { orgSettings } = useOrgSettings({
    orgId: p.org.id
  });

  const previewInvoice: OrgInvoice__Manual = {
    id: "preview",
    invoiceGroupId: "preview",
    createdAtMS: Date.now(),
    playerBundleId: "test-player-bundle-id",
    derivedTotalAmountPaidCentsBeforeAllFees: 0,
    derivedTotalAmountPaidCentsIncludingChildrenInvoices: 0,
    memo: orgInvoice.memo ?? "",
    amountDueCents: orgInvoice.amountDueCents ?? 0,
    derivedTotalAmountDueCentsIncludingChildrenInvoices: orgInvoice.amountDueCents ?? 0,
    dueDateMS: orgInvoice.dueDateMS ?? 0,
    lateFeeCentsToBeIssuedIfLate: orgInvoice.lateFeeCentsToBeIssuedIfLate ?? 0,
    orgId: p.org.id,
    type: OrgInvoiceTypes.manual,
    allowedPaymentPlanIds: orgInvoice.allowedPaymentPlanIds,
    assignedByAccountId: orgInvoice.assignedByAccountId ?? getCurrentUserAccountId(),
    thisInvoicePaidInFullDateMS: 0
  };

  const isPreviewInvoiceValid = !!previewInvoice.amountDueCents && !!previewInvoice.memo && !!previewInvoice.dueDateMS;

  const { data: initialPlayerBundle } = usePromise(
    () =>
      orgInvoice.playerBundleId
        ? getBifrost().playerBundle__server__getPlayerBundle.fetchServer({ id: orgInvoice.playerBundleId })
        : Promise.resolve(null),
    []
  );
  const [isPreviewExpanded, setIsPreviewExpanded] = useState(false);

  const [selectedPlayerBundleIds, setSelectedPlayerBundleIds] = useState<PlayerBundleId[]>([]);
  const [selectedPlayerBundleData, setSelectedPlayerBundleData] = useState<{ playerBundleId: PlayerBundleId; display: string }[]>(
    []
  );
  const [selectedTeamIds, setSelectedTeamIds] = useState<TeamId[]>([]);
  const teams = useOrgTeams({ orgId: p.org.id }) ?? [];

  const { data: playerBundleInfoFromTeam } = getBifrost().playerBundle__server__getPlayerBundleIdsAndNameForTeamIds.useServer(
    {
      orgId: p.org.id,
      selfAccountId: getCurrentUserAccountId(),
      teamIds: selectedTeamIds
    },
    { enabled: !!selectedTeamIds.length }
  );

  function getSelectedPlayerBundleDisplay(p: { playerName: string; guardianNames: string[]; teamNames: string[] }) {
    const guardianNames = p.guardianNames.length ? ` (${p.guardianNames.join(", ")})` : "";
    return `${p.playerName}${guardianNames} (${p.teamNames.join(", ")})`;
  }

  const allSelectedPlayerBundleData: { playerBundleId: PlayerBundleId; display: string }[] = _.uniqBy(
    [
      ...selectedPlayerBundleData,
      ...(playerBundleInfoFromTeam?.map(a => {
        const playerTeams = _.compact(
          a.teamIds.map(teamId => {
            return teams.find(t => t.id === teamId);
          })
        );
        const display = getSelectedPlayerBundleDisplay({
          guardianNames: a.guardianNames,
          playerName: a.playerName,
          teamNames: playerTeams.map(t => t.name)
        });
        return {
          playerBundleId: a.playerBundleId,
          display
        };
      }) ?? [])
    ],
    a => a.playerBundleId
  );

  const playerBundleIdsToInvoice = allSelectedPlayerBundleData.map(a => a.playerBundleId);

  useEffect(() => {
    if (initialPlayerBundle?.data) {
      setSelectedPlayerBundleIds([initialPlayerBundle.data.id]);
    }
  }, [initialPlayerBundle]);

  const orgPaymentPlans =
    useOrgPaymentPlans({
      orgId: p.org.id
    }) ?? [];

  const [errorMsg, setErrorMsg] = useState("");

  const paymentPlanOptions = orgPaymentPlans
    .filter(opp => opp.type === OrgPaymentPlanType.monthly)
    .map(paymentPlan => {
      return {
        label: paymentPlan.name,
        value: paymentPlan.id
      };
    });

  return (
    <Form
      children={isFormValid => {
        return (
          <FullScreenModal
            title={
              p.type === "create"
                ? translate({ defaultMessage: "Create Invoice" })
                : translate({ defaultMessage: "Edit Invoice" })
            }
            bottomButton={{
              title: p.type === "edit" ? translate.common.Save : translate({ defaultMessage: "Create & Notify" }),
              enabled: isFormValid && (p.type === "edit" || playerBundleIdsToInvoice.length > 0),
              onPress: async () => {
                try {
                  if (p.type === "create") {
                    await getBifrost().orgInvoice__server__createManualOrgInvoices.fetchServer({
                      invoicesBase: {
                        amountDueCents: orgInvoice.amountDueCents ?? 0,
                        derivedTotalAmountDueCentsIncludingChildrenInvoices: orgInvoice.amountDueCents ?? 0,
                        derivedTotalAmountPaidCentsIncludingChildrenInvoices: 0,
                        derivedTotalAmountPaidCentsBeforeAllFees: 0,
                        dueDateMS: orgInvoice.dueDateMS ?? 0,
                        lateFeeCentsToBeIssuedIfLate: orgInvoice.lateFeeCentsToBeIssuedIfLate ?? 0,
                        memo: orgInvoice.memo ?? "",
                        orgId: p.org.id,
                        type: OrgInvoiceTypes.manual,
                        assignedByAccountId: getCurrentUserAccountId(),
                        allowedPaymentPlanIds:
                          orgInvoice.type === OrgInvoiceTypes.manual
                            ? (orgInvoice as Partial<OrgInvoice__Manual>).allowedPaymentPlanIds
                            : undefined,
                        thisInvoicePaidInFullDateMS: 0
                      },
                      playerBundleIds: playerBundleIdsToInvoice,
                      selfAccountId: getCurrentUserAccountId()
                    });
                  } else {
                    const newOrgInvoice: Partial<OrgInvoice> = { ...orgInvoice };
                    if (newOrgInvoice.type === OrgInvoiceTypes.manual && p.initialOrgInvoice?.type === OrgInvoiceTypes.manual) {
                      const orgPaymentPlanIdsToDelete = ObjectKeys(
                        (p.initialOrgInvoice as Partial<OrgInvoice__Manual>).allowedPaymentPlanIds ?? {}
                      ).filter(
                        paymentPlanId =>
                          newOrgInvoice.type === OrgInvoiceTypes.manual &&
                          !(newOrgInvoice as Partial<OrgInvoice__Manual>).allowedPaymentPlanIds?.[paymentPlanId]
                      );
                      orgPaymentPlanIdsToDelete.forEach(paymentPlanId => {
                        if (newOrgInvoice.type === OrgInvoiceTypes.manual) {
                          (newOrgInvoice as Partial<OrgInvoice__Manual>).allowedPaymentPlanIds = {
                            ...((newOrgInvoice as Partial<OrgInvoice__Manual>).allowedPaymentPlanIds ?? {}),
                            [paymentPlanId]: api.common__magicDeleteValue()
                          };
                        }
                      });
                    }
                    await getBifrost().orgInvoice__client__updateManualOrgInvoice.fetchClient({
                      updatedInvoice: { ...newOrgInvoice } as OrgInvoice__Manual
                    });
                  }
                  openSuccessToast(
                    p.type === "create"
                      ? translate(
                          {
                            defaultMessage: "Created {num, plural, one {invoice} other {invoices}}"
                          },
                          { num: selectedPlayerBundleIds.length }
                        )
                      : translate({
                          defaultMessage: "Updated invoice"
                        })
                  );
                  p.onRequestDismiss(true);
                } catch (e) {
                  console.log(e);
                  openErrorToast(
                    translate(
                      {
                        defaultMessage:
                          "There was a problem creating the {num, plural, one {invoice} other {invoices}}. Please try again or contact support@olliesports.com"
                      },
                      {
                        num: selectedPlayerBundleIds.length
                      }
                    )
                  );
                }
              }
            }}
            onRequestDismiss={() => {
              if (
                !dequal(orgInvoice, initialOrgInvoice) &&
                !window.confirm(translate({ defaultMessage: "You have unsaved changes. Are you sure you wish to leave?" }))
              ) {
                return;
              }

              p.onRequestDismiss(false);
            }}
          >
            <div>
              <PrettyCoolTextInputWithLabel
                style={{ marginTop: 16 }}
                label={translate.common.Amount}
                isRequired
                isMoney={true}
                onChange={newVal => {
                  const newAmt = newVal?.match(/\d+\.?\d?\d?/)?.[0];
                  if (!newVal) {
                    setAmountShadow("");
                    setOrgInvoice({ amountDueCents: undefined });
                    return;
                  }

                  if (parseInt(newAmt || "0") < 0) {
                    return;
                  }
                  setAmountShadow(newAmt || "");
                  setOrgInvoice({ amountDueCents: newAmt ? Number(newAmt) * 100 : 0 });
                }}
                inputProps={{ type: "number" }}
                value={amountShadow}
              />
              <div className="flex gap-2 mt-4">
                <div className="flex-1 flex-col">
                  <PrettyCoolDateInput
                    value={orgInvoice.dueDateMS ? moment(orgInvoice.dueDateMS).toDate() : undefined}
                    onChange={newVal => {
                      if (newVal) {
                        const dueDate = moment(newVal);
                        dueDate.endOf("day");
                        dueDate.subtract(1, "second");

                        setOrgInvoice({ dueDateMS: dueDate.valueOf() });
                      } else {
                        setOrgInvoice({ dueDateMS: undefined });
                      }
                    }}
                    isRequired
                    validate={val => {
                      if (!val) {
                        return translate.common.IsRequired;
                      }
                      if (val && val < moment().startOf("day").toDate() && p.type === "create") {
                        return translate({ defaultMessage: "Due date must be in the future" });
                      }
                      return "";
                    }}
                    infoTooltip={translate({
                      defaultMessage:
                        "The date when the user will need to pay in full or make the first payment on a payment plan if allowed below."
                    })}
                    className="flex-1"
                    label={translate({ defaultMessage: "First Payment Due" })}
                  />
                </div>
                <div className="flex-1 flex-col">
                  <PrettyCoolTextInputWithLabel
                    label={`${translate.common.LateFee} (${translate.common.Optional})`}
                    isMoney
                    style={{ flex: 1 }}
                    onChange={newVal => {
                      if (!newVal) {
                        setLateFeeAmountShadow("");
                        setOrgInvoice({ lateFeeCentsToBeIssuedIfLate: undefined });
                        return;
                      }
                      const newAmt = newVal?.match(/\d+\.?\d?\d?/)?.[0];
                      setLateFeeAmountShadow(newAmt || "");
                      setOrgInvoice({ lateFeeCentsToBeIssuedIfLate: newAmt ? Number(newAmt) * 100 : 0 });
                    }}
                    inputProps={{ type: "number" }}
                    value={lateFeeAmountShadow}
                  />
                </div>
              </div>
              <div className="flex px-1 text-xs text-gray-500 items-center mt-1">
                {translate({
                  defaultMessage:
                    "Players and their guardians will receive 3 reminders the week that the invoice is due, 3 reminders the week after, and then weekly reminders if they still have not registered."
                })}
              </div>
              <PrettyCoolTextInputWithLabel
                isRequired
                style={{ marginTop: 16 }}
                label={translate.common.Memo}
                value={orgInvoice.memo}
                onChange={newVal => {
                  setOrgInvoice({ memo: newVal ?? "" });
                }}
              />
              {orgInvoice.type === OrgInvoiceTypes.manual ? (
                <div className="mt-4">
                  <PrettyCoolMultiSelectWithLabel
                    label={`${translate({ defaultMessage: "Allowed Payment Plans" })} (${translate.common.Optional})`}
                    infoTooltip={translate({
                      defaultMessage:
                        "Note: Fixed date payment plans are not allowed for manual invoices. Monthly payment plans only."
                    })}
                    onChange={newVal => {
                      setOrgInvoice({
                        allowedPaymentPlanIds: newVal.length
                          ? newVal.reduce((acc, val) => {
                              acc[val.value] = true;
                              return acc;
                            }, {} as Record<string, true>)
                          : undefined
                      });
                    }}
                    options={paymentPlanOptions}
                    value={paymentPlanOptions.filter(
                      option => !!(orgInvoice as Partial<OrgInvoice__Manual>).allowedPaymentPlanIds?.[option.value]
                    )}
                  />
                </div>
              ) : null}
              {p.type === "create" ? (
                <div>
                  <div className="mt-4 mb-1">
                    <label className="text-sm font-extrabold">{translate.common.Teams}</label>
                    <div>
                      <label className="text-xs text-gray-500">
                        {translate({
                          defaultMessage:
                            "Select the team(s) that you want to invoice. All players on selected teams will receive an invoice."
                        })}
                      </label>
                    </div>
                  </div>
                  <CoolMultiSelectInput
                    onChange={newVal => {
                      setSelectedTeamIds(newVal.map(a => a.value));
                    }}
                    options={teams.map(t => {
                      return {
                        label: t.name,
                        value: t.id
                      };
                    })}
                    value={_.compact(
                      selectedTeamIds.map(teamId => {
                        const team = teams.find(t => t.id === teamId);
                        if (team) {
                          return {
                            label: team.name,
                            value: team.id
                          };
                        }
                        return null;
                      })
                    )}
                  />
                </div>
              ) : null}
              {p.type === "create" ? (
                <div>
                  <div className="mt-4 mb-1">
                    <label className="text-sm font-extrabold">{translate.common.Players}</label>
                    <div>
                      <label className="text-xs text-gray-500">
                        {translate({
                          defaultMessage: "Select the player(s) that you want to invoice."
                        })}
                      </label>
                    </div>
                  </div>
                  <AsyncSelect
                    isMulti={true}
                    cacheOptions
                    isClearable
                    styles={{
                      dropdownIndicator: a => {
                        return {
                          ...a,
                          cursor: "pointer"
                        };
                      },

                      menu: curr => ({
                        ...curr,
                        position: "relative"
                      }),
                      menuPortal: a => ({ ...a, zIndex: 60 }),
                      clearIndicator: a => {
                        return {
                          ...a,
                          cursor: "pointer"
                        };
                      }
                    }}
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    defaultValue={
                      initialPlayerBundle?.data
                        ? {
                            label: `${initialPlayerBundle.data.virtualAthleteAccount.firstName} ${initialPlayerBundle.data?.virtualAthleteAccount.lastName}`,
                            value: initialPlayerBundle.data.id
                          }
                        : undefined
                    }
                    onChange={v => {
                      setSelectedPlayerBundleIds(v.map(a => a.value));
                      setSelectedPlayerBundleData(
                        v.map(a => {
                          return {
                            playerBundleId: a.value,
                            display: a.label
                          };
                        })
                      );
                    }}
                    loadingMessage={() => ""}
                    loadOptions={currValue => {
                      return getBifrost()
                        .playerBundle__server__searchInOrg.fetchServer({
                          orgId: p.org.id,
                          searchText: currValue,
                          selfAccountId: getCurrentUserAccountId()
                        })
                        .then(a =>
                          a.data.map(b => {
                            const playerName = `${b.playerBundle.virtualAthleteAccount.firstName.trim()} ${b.playerBundle.virtualAthleteAccount.lastName.trim()}`;
                            return {
                              value: b.playerBundle.id,
                              label: getSelectedPlayerBundleDisplay({
                                playerName,
                                guardianNames: b.guardianNames,
                                teamNames: b.teamNames
                              })
                            };
                          })
                        );
                    }}
                    noOptionsMessage={() => ""}
                    placeholder={translate({ defaultMessage: "Type to search players..." })}
                    defaultOptions={[{ value: "", label: " " }]}
                  />
                </div>
              ) : null}
              {allSelectedPlayerBundleData.length ? (
                <PressableAsterisksText
                  className="text-xs text-gray-500"
                  onAsteriskPress={() => {
                    getFullScreenModal({
                      title: translate.common.Players,
                      children: (
                        <div>
                          {_.orderBy(allSelectedPlayerBundleData, a => a.display, "asc").map(a => {
                            const firstParenthesesIndex = a.display.indexOf("(");
                            let displayToUse = a.display;
                            if (firstParenthesesIndex) {
                              displayToUse = `*${
                                a.display.slice(0, firstParenthesesIndex) + "*" + a.display.slice(firstParenthesesIndex)
                              }`;
                            }
                            return (
                              <div key={a.playerBundleId} className="my-2 text-lg flex flex-row">
                                <div className="mr-2">{"•"}</div>
                                <div>
                                  {customizeAsterisks(displayToUse, a => {
                                    return <div className="font-bold inline-block mr-1">{a}</div>;
                                  })}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )
                    });
                  }}
                  asterisksClassName="underline text-blue-400 cursor-context-menu"
                >
                  {translate(
                    {
                      defaultMessage: "*{num, plural, one {1 player} other {{num} players}}* will receive this invoice."
                    },
                    { num: allSelectedPlayerBundleData.length }
                  )}
                </PressableAsterisksText>
              ) : null}
              {orgSettings ? (
                <View style={{ marginVertical: 16 }}>
                  <TextButton
                    iconElm={
                      isPreviewExpanded ? (
                        <ArrowUpIcon width={16} height={16} className="text-green-400" />
                      ) : (
                        <ArrowDownIcon width={16} height={16} className="text-green-400" />
                      )
                    }
                    text={isPreviewExpanded ? translate.common.HidePreview : translate.common.ViewPreview}
                    onPress={() => {
                      setIsPreviewExpanded(!isPreviewExpanded);
                    }}
                    disabled={!isPreviewInvoiceValid}
                    textClassName="text-green-400"
                  />
                  <Expando durationMS={500} isExpanded={isPreviewExpanded}>
                    <View style={{ paddingHorizontal: 16 }}>
                      {isPreviewInvoiceValid ? (
                        <OrgInvoicePayPreviewInner
                          type={OrgInvoiceTypes.manual}
                          org={p.org}
                          orgSettings={orgSettings}
                          testManualOrgInvoice={previewInvoice}
                        />
                      ) : (
                        <StyledText style={{ marginTop: 16 }}>
                          {translate({
                            defaultMessage:
                              "Please make sure that all of the required fields are filled out in order to view the preview."
                          })}
                        </StyledText>
                      )}
                    </View>
                  </Expando>
                </View>
              ) : null}
              {errorMsg ? <Typography style={{ color: COLORS.red, marginTop: 30 }}>{errorMsg}</Typography> : null}
            </div>
          </FullScreenModal>
        );
      }}
    />
  );
}
