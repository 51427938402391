import { Typography } from "@material-ui/core";
import _ from "lodash";
import {
  OrgInvoiceChild,
  OrgInvoiceParent,
  OrgPayment,
  OrgPaymentInvoiceCredit,
  OrgPaymentInvoiceCreditApplicationMethod,
  OrgPaymentType,
  PaymentMethodType
} from "@ollie-sports/models";
import { translate } from "@ollie-sports/i18n";
import { getBifrost } from "../../services/bifrost.service";
import {
  COLORS,
  filterOrgPaymentInvoices,
  formatMoneyCentsToDollarCentPrettyString,
  getIndividualOrgInvoiceAmountDetails
} from "@ollie-sports/core";
import { useState } from "react";
import { Form, PrettyCoolSelectInput, PrettyCoolTextInputWithLabel } from "../../components/Form";
import { openModal } from "../../components/modals/imperativeModal";
import { FullScreenModal } from "../../components/modals/getFullscreenModal";
import { StyledText } from "../../components/StyledText";
import { View } from "react-native-web";
import { getCurrentUserAccountId } from "../../hooks/commonDataUtils";
import { openErrorToast } from "../../utils/openErrorToast";

type PropsForParentInvoiceCredit = {
  orgInvoiceParent: OrgInvoiceParent;
  maxAmountCents: number;
  scheduledChildrenOrgInvoices: OrgInvoiceChild[];
  orgPayments: OrgPayment[];
  onComplete: () => Promise<void>;
};

export function openOrgPaymentInvoiceCreditAddModal(p: PropsForParentInvoiceCredit) {
  return new Promise<string | undefined>(res => {
    const modal = openModal({
      body: (
        <OrgPaymentInvoiceCreditAddModal
          {...p}
          onRequestDismiss={() => {
            modal.close();
          }}
        />
      )
    });
  });
}

function OrgPaymentInvoiceCreditAddModal(p: PropsForParentInvoiceCredit & { onRequestDismiss: () => void }) {
  const [isLoading, setIsLoading] = useState(false);
  const [amountCents, setAmountCents] = useState<number | undefined>();
  const [amountShadow, setAmountShadow] = useState("");
  const [note, setNote] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [selectedApplicationMethod, setSelectedApplicationMethod] = useState<
    OrgPaymentInvoiceCreditApplicationMethod | undefined
  >();

  return (
    <Form
      children={isFormValid => {
        return (
          <FullScreenModal
            title={translate({ defaultMessage: "Add Credit" })}
            bottomButton={{
              title: translate.common.Apply,
              enabled: isFormValid,
              onPress: async () => {
                setIsLoading(true);
                try {
                  if (!amountCents || !note) {
                    throw new Error("");
                  }
                  const baseCredit: Omit<
                    OrgPaymentInvoiceCredit,
                    "id" | "createdAtMS" | "groupingId" | "amountCents" | "invoiceId" | "invoiceGroupId"
                  > = {
                    type: OrgPaymentType.invoiceCredit,
                    appliedByAccountId: getCurrentUserAccountId(),
                    note,
                    orgId: p.orgInvoiceParent.orgId,
                    status: "succeeded",
                    playerBundleId: p.orgInvoiceParent.playerBundleId
                  };
                  const allCreditsToAdd: Omit<OrgPaymentInvoiceCredit, "id" | "createdAtMS" | "groupingId">[] = [];
                  if (p.scheduledChildrenOrgInvoices.length === 0) {
                    // This means we just have the parent so apply the credit to the parent
                    if (
                      p.orgInvoiceParent.thisInvoicePaidInFullDateMS ||
                      amountCents >
                        p.orgInvoiceParent.amountDueCents - p.orgInvoiceParent.derivedTotalAmountPaidCentsBeforeAllFees
                    ) {
                      // This shouldn't happen but we need to protect against it
                      throw new Error("");
                    }
                    allCreditsToAdd.push({
                      ...baseCredit,
                      amountCents: amountCents,
                      invoiceGroupId: p.orgInvoiceParent.id,
                      invoiceId: p.orgInvoiceParent.id
                    });
                  } else if (p.scheduledChildrenOrgInvoices.length === 1) {
                    // This means we have just 1 child and the parent must be paid
                    // already, so apply all the credit to the one child
                    if (
                      amountCents >
                      p.scheduledChildrenOrgInvoices[0].amountDueCents -
                        p.scheduledChildrenOrgInvoices[0].derivedTotalAmountPaidCentsBeforeAllFees
                    ) {
                      // This shouldn't happen but we need to protect against it
                      throw new Error("");
                    }
                    allCreditsToAdd.push({
                      ...baseCredit,
                      amountCents: amountCents ?? 0,
                      invoiceGroupId: p.scheduledChildrenOrgInvoices[0].invoiceGroupId,
                      invoiceId: p.scheduledChildrenOrgInvoices[0].id
                    });
                  } else {
                    // Apply the credit to children according to the application method
                    if (!selectedApplicationMethod) {
                      // This shouldn't happen but we need to protect against it
                      throw new Error("");
                    }
                    switch (selectedApplicationMethod) {
                      case OrgPaymentInvoiceCreditApplicationMethod.equal:
                        const totalRemainingAmount = _.sum(
                          p.scheduledChildrenOrgInvoices.map(
                            oi =>
                              getIndividualOrgInvoiceAmountDetails({
                                orgInvoice: oi,
                                orgPayments: filterOrgPaymentInvoices(p.orgPayments).filter(op => op.invoiceId === oi.id),
                                paymentMethodType: PaymentMethodType.card // Doesn't matter
                              }).remainingAmount
                          )
                        );
                        if (totalRemainingAmount === 0) {
                          // Shouldn't happen, just guarding agains dividing by 0
                          return;
                        }
                        const creditPercentInRelationToTotalRemainingAmount = amountCents / totalRemainingAmount;
                        _.orderBy(p.scheduledChildrenOrgInvoices, a => a.autoChargeDateMS, "asc").forEach(oi => {
                          allCreditsToAdd.push({
                            ...baseCredit,
                            amountCents: Math.floor(
                              getIndividualOrgInvoiceAmountDetails({
                                orgInvoice: oi,
                                orgPayments: filterOrgPaymentInvoices(p.orgPayments).filter(op => op.invoiceId === oi.id),
                                paymentMethodType: PaymentMethodType.card // Doesn't matter
                              }).remainingAmount * creditPercentInRelationToTotalRemainingAmount
                            ),
                            invoiceGroupId: oi.invoiceGroupId,
                            invoiceId: oi.id
                          });
                        });
                        const totalAmountApplied = _.sum(allCreditsToAdd.map(c => c.amountCents));
                        let centsRemainingToApply = amountCents - totalAmountApplied;
                        let currentIndex = 0;
                        while (centsRemainingToApply > 0) {
                          allCreditsToAdd[currentIndex].amountCents = allCreditsToAdd[currentIndex].amountCents + 1;
                          centsRemainingToApply = centsRemainingToApply - 1;
                          currentIndex = currentIndex === allCreditsToAdd.length - 1 ? 0 : currentIndex + 1;
                        }

                        break;
                      case OrgPaymentInvoiceCreditApplicationMethod.beginning:
                        let amountCentsToApplyRemaining = amountCents;
                        _.orderBy(p.scheduledChildrenOrgInvoices, a => a.dueDateMS, "asc").forEach(oi => {
                          if (amountCentsToApplyRemaining) {
                            const orgInvoiceDetails = getIndividualOrgInvoiceAmountDetails({
                              orgInvoice: oi,
                              orgPayments: filterOrgPaymentInvoices(p.orgPayments).filter(op => op.invoiceId === oi.id),
                              paymentMethodType: PaymentMethodType.card // Doesn't matter
                            });
                            if (orgInvoiceDetails.remainingAmount) {
                              const amountCentsToApplyToThisCredit = Math.min(
                                orgInvoiceDetails.remainingAmount,
                                amountCentsToApplyRemaining
                              );
                              amountCentsToApplyRemaining = amountCentsToApplyRemaining - amountCentsToApplyToThisCredit;
                              allCreditsToAdd.push({
                                ...baseCredit,
                                amountCents: amountCentsToApplyToThisCredit,
                                invoiceGroupId: oi.invoiceGroupId,
                                invoiceId: oi.id
                              });
                            }
                          }
                        });
                        break;
                      case OrgPaymentInvoiceCreditApplicationMethod.end:
                        let amountCentsToApplyRemaining2 = amountCents;
                        _.orderBy(p.scheduledChildrenOrgInvoices, a => a.dueDateMS, "desc").forEach(oi => {
                          if (amountCentsToApplyRemaining2) {
                            const orgInvoiceDetails = getIndividualOrgInvoiceAmountDetails({
                              orgInvoice: oi,
                              orgPayments: filterOrgPaymentInvoices(p.orgPayments).filter(op => op.invoiceId === oi.id),
                              paymentMethodType: PaymentMethodType.card // Doesn't matter
                            });
                            if (orgInvoiceDetails.remainingAmount) {
                              const amountCentsToApplyToThisCredit = Math.min(
                                orgInvoiceDetails.remainingAmount,
                                amountCentsToApplyRemaining2
                              );
                              amountCentsToApplyRemaining2 = amountCentsToApplyRemaining2 - amountCentsToApplyToThisCredit;
                              allCreditsToAdd.push({
                                ...baseCredit,
                                amountCents: amountCentsToApplyToThisCredit,
                                invoiceGroupId: oi.invoiceGroupId,
                                invoiceId: oi.id
                              });
                            }
                          }
                        });
                        break;
                    }
                  }
                  await getBifrost().orgPayment__client__addOrgPaymentInvoiceCredits.fetchClient({
                    orgPaymentInvoiceCredits: allCreditsToAdd
                  });
                  await p.onComplete();
                  p.onRequestDismiss();
                } catch (e) {
                  openErrorToast(
                    translate({
                      defaultMessage:
                        "There was a problem applying the credit. Please try again or contact support@olliesports.com"
                    })
                  );
                }
                setIsLoading(false);
              }
            }}
            onRequestDismiss={() => {
              if (
                (amountCents || note) &&
                !window.confirm(translate({ defaultMessage: "You have unsaved changes. Are you sure you wish to leave?" }))
              ) {
                return;
              }

              p.onRequestDismiss();
            }}
          >
            <div>
              <View>
                <StyledText>
                  {translate({
                    defaultMessage: "You can apply credit up to the remaining amount on the invoice."
                  })}
                </StyledText>
                <View style={{ flexDirection: "row", marginTop: 16 }}>
                  <StyledText>{`${translate({
                    defaultMessage: "Remaining Amount"
                  })}:`}</StyledText>
                  <StyledText style={{ fontWeight: "bold" }}>{`${formatMoneyCentsToDollarCentPrettyString(
                    p.maxAmountCents
                  )}`}</StyledText>
                </View>
              </View>
              <PrettyCoolTextInputWithLabel
                label={translate({ defaultMessage: "Amount" })}
                isRequired
                isMoney
                onChange={newAmtRaw => {
                  const newAmt = newAmtRaw?.match(/\d+\.?\d?\d?/)?.[0];
                  setAmountShadow(newAmt || "");
                  setAmountCents(newAmt ? Number(newAmt) * 100 : undefined);
                }}
                validate={val => {
                  if (!val) {
                    return translate.common.IsRequired;
                  }
                  const valNumber = Number(val) * 100;
                  if (valNumber > p.maxAmountCents) {
                    return translate(
                      { defaultMessage: "The Amount cannot exceed {amount}." },
                      { amount: formatMoneyCentsToDollarCentPrettyString(p.maxAmountCents) }
                    );
                  }
                  return "";
                }}
                inputProps={{ type: "number", min: 0 }}
                value={amountShadow}
                style={{ marginTop: 16 }}
              />
              <PrettyCoolTextInputWithLabel
                style={{ marginTop: 16 }}
                label={translate.common.Note}
                value={note}
                isRequired
                onChange={newVal => {
                  setNote(newVal ?? "");
                }}
              />
              {p.scheduledChildrenOrgInvoices.length > 1 ? (
                <PrettyCoolSelectInput
                  containerStyle={{ marginTop: 16 }}
                  label={translate({ defaultMessage: "How do you want to apply the credit?" })}
                  onChange={newVal => {
                    if (newVal) {
                      setSelectedApplicationMethod(newVal as OrgPaymentInvoiceCreditApplicationMethod);
                    } else {
                      setSelectedApplicationMethod(undefined);
                    }
                  }}
                  value={selectedApplicationMethod}
                  options={Object.keys(OrgPaymentInvoiceCreditApplicationMethod).map(method => {
                    return {
                      value: method,
                      label:
                        method === OrgPaymentInvoiceCreditApplicationMethod.beginning
                          ? translate({ defaultMessage: "Beginning: Apply to earliest payments first" })
                          : method === OrgPaymentInvoiceCreditApplicationMethod.end
                          ? translate({ defaultMessage: "End: Apply to last payments first" })
                          : translate({ defaultMessage: "Even: Divide evenly among all remaining payments" })
                    };
                  })}
                  isRequired
                />
              ) : null}

              {errorMsg ? <Typography style={{ color: COLORS.red, marginTop: 30 }}>{errorMsg}</Typography> : null}
            </div>
          </FullScreenModal>
        );
      }}
    />
  );
}

type PropsForScheduledPaymentInvoiceCredit = {
  orgInvoice: OrgInvoiceChild;
  orgInvoiceParent: OrgInvoiceParent;
  maxAmountCents: number;
  onComplete: () => Promise<void>;
};

export function openOrgPaymentInvoiceCreditAddToScheduledPaymentModal(p: PropsForScheduledPaymentInvoiceCredit) {
  return new Promise<string | undefined>(res => {
    const modal = openModal({
      body: (
        <OrgPaymentInvoiceCreditAddToScheduledPaymentModal
          {...p}
          onRequestDismiss={() => {
            modal.close();
          }}
        />
      )
    });
  });
}

function OrgPaymentInvoiceCreditAddToScheduledPaymentModal(
  p: PropsForScheduledPaymentInvoiceCredit & { onRequestDismiss: () => void }
) {
  const [isLoading, setIsLoading] = useState(false);
  const [amountCents, setAmountCents] = useState<number | undefined>();
  const [amountShadow, setAmountShadow] = useState("");
  const [note, setNote] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  return (
    <Form
      children={isFormValid => {
        return (
          <FullScreenModal
            title={translate({ defaultMessage: "Add Credit" })}
            bottomButton={{
              title: translate.common.Apply,
              enabled: isFormValid,
              onPress: async () => {
                setIsLoading(true);
                try {
                  if (!amountCents || !note) {
                    throw new Error("");
                  }
                  const credit: Omit<OrgPaymentInvoiceCredit, "id" | "createdAtMS" | "groupingId"> = {
                    type: OrgPaymentType.invoiceCredit,
                    appliedByAccountId: getCurrentUserAccountId(),
                    note,
                    orgId: p.orgInvoice.orgId,
                    status: "succeeded",
                    playerBundleId: p.orgInvoice.playerBundleId,
                    invoiceId: p.orgInvoice.id,
                    amountCents,
                    invoiceGroupId: p.orgInvoiceParent.id
                  };
                  await getBifrost().orgPayment__client__addOrgPaymentInvoiceCredits.fetchClient({
                    orgPaymentInvoiceCredits: [credit]
                  });
                  await p.onComplete();
                  p.onRequestDismiss();
                } catch (e) {
                  openErrorToast(
                    translate({
                      defaultMessage:
                        "There was a problem applying the credit. Please try again or contact support@olliesports.com"
                    })
                  );
                }
                setIsLoading(false);
              }
            }}
            onRequestDismiss={() => {
              if (
                (amountCents || note) &&
                !window.confirm(translate({ defaultMessage: "You have unsaved changes. Are you sure you wish to leave?" }))
              ) {
                return;
              }

              p.onRequestDismiss();
            }}
          >
            <div>
              <View>
                <StyledText>
                  {translate({
                    defaultMessage: "You can apply credit up to the remaining amount on the scheduled payment."
                  })}
                </StyledText>
                <View style={{ flexDirection: "row", marginTop: 16 }}>
                  <StyledText>{`${translate({
                    defaultMessage: "Remaining Amount"
                  })}:`}</StyledText>
                  <StyledText style={{ fontWeight: "bold" }}>{`${formatMoneyCentsToDollarCentPrettyString(
                    p.maxAmountCents
                  )}`}</StyledText>
                </View>
              </View>
              <PrettyCoolTextInputWithLabel
                label={translate({ defaultMessage: "Amount" })}
                isRequired
                isMoney
                onChange={newAmtRaw => {
                  const newAmt = newAmtRaw?.match(/\d+\.?\d?\d?/)?.[0];
                  setAmountShadow(newAmt || "");
                  setAmountCents(newAmt ? Number(newAmt) * 100 : undefined);
                }}
                validate={val => {
                  if (!val) {
                    return translate.common.IsRequired;
                  }
                  const valNumber = Number(val) * 100;
                  if (valNumber > p.maxAmountCents) {
                    return translate(
                      { defaultMessage: "The Amount cannot exceed {amount}." },
                      { amount: formatMoneyCentsToDollarCentPrettyString(p.maxAmountCents) }
                    );
                  }
                  return "";
                }}
                inputProps={{ type: "number", min: 0 }}
                value={amountShadow}
                style={{ marginTop: 16 }}
              />
              <PrettyCoolTextInputWithLabel
                style={{ marginTop: 16 }}
                label={translate.common.Note}
                value={note}
                isRequired
                onChange={newVal => {
                  setNote(newVal ?? "");
                }}
              />

              {errorMsg ? <Typography style={{ color: COLORS.red, marginTop: 30 }}>{errorMsg}</Typography> : null}
            </div>
          </FullScreenModal>
        );
      }}
    />
  );
}
